import avatar from "./images/avatar1.png";
import React, { useState, useEffect } from "react";
import axios from 'axios';
import $ from "jquery";
import { useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from "../../common/common";
import Loader from "react-js-loader";
import '../../common/components/css/style.css';
import avatar1 from "./images/avatar1.png";
import "../../index.css";
import Snackbar from '@mui/material/Snackbar';

export default function Staffattendance() {

    const navigate = useNavigate();
    const [programsData, setProgramsData] = useState({});
    const [programsLoading, setProgramsLoading] = useState(false);
    const fetchProgramsData = async () => {
        setProgramsLoading(false);
        try {
            const postData = {
                school_id: localStorage.getItem('schoolId')
                // school_id:24
            }
            const { data } = await axios.post(baseUrl + "/api/get_programs", postData);
            setProgramsData(data.data);
            setProgramsLoading(true);
        } catch (error) {
            console.log(error)
        }
    }

    const [foodData, setFoodData] = useState({});
    const [dataHeader, setDataHeader] = useState([]);
    const [foodHeaderdata, setFoodHeaderData] = useState([]);
    const [foodLoading, setFoodLoading] = useState(false);

    const [filterValue, setFilterValue] = useState('');
    const handleInputChange = async (event) => {
      setFilterValue(event.target.value);
      try {
        axios({
            method: "post",
            url: baseUrl + "/api/staff_attendance_list_admin",
            data: {
                school_id: localStorage.getItem('schoolId'),
                search:event.target.value.trim(),
                // programs_id: localStorage.getItem('programId'),
                // list_status: 1
            },
        }).then((response) => {
            console.log(response);
            if (response['data']['status'] === true) {
                setFoodData(response.data);
                setDataHeader(response.data.dateresult);
                setFoodHeaderData(response.data.data);
                setFoodLoading(true);
            }
        });
    } catch (error) {
        console.log(error);
    }
    };
    const fetchAttendanceData = () => {
        setFoodLoading(false);
        try {
            axios({
                method: "post",
                url: baseUrl + "/api/staff_attendance_list_admin",
                data: {
                    school_id: localStorage.getItem('schoolId'),
                    search: ""
                    // programs_id: localStorage.getItem('programId'),
                    // list_status: 1
                },
            }).then((response) => {
                console.log(response);
                if (response['data']['status'] === true) {
                    setFoodData(response.data);
                    setDataHeader(response.data.dateresult);
                    setFoodHeaderData(response.data.data);
                    setFoodLoading(true);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    const [selectedStaff, setselectedStaff] = useState([]);

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allStudentIds = foodHeaderdata.map(student => student.id);
            setselectedStaff(allStudentIds);
        } else {
            setselectedStaff([]);
        }
    };

    const handleSelectStudent = (studentId) => {
        setselectedStaff(prevSelected =>
            prevSelected.includes(studentId)
                ? prevSelected.filter(id => id !== studentId)
                : [...prevSelected, studentId]
        );
    };

    const [error, setError] = useState(null);
    const addAttendance = (attendanceStatus) => {
        const today = new Date();
        if (selectedStaff.length === 0) {
            setError("Please Select Student")
            setOpen(true);
        } else {
            const postData = {
                school_id: localStorage.getItem('schoolId'),
                attendance_date: today.toISOString().split('T')[0],
                programs_id: localStorage.getItem('programId'),
                staff_ids: selectedStaff,
                attendance_status: attendanceStatus,
                status: 1,
                created_by: localStorage.getItem('schoolId'),
                updated_by: localStorage.getItem('schoolId')
            }
            console.log(postData)
            axios({
                method: "post",
                url: baseUrl + "/api/staff_attendance_add_edit",
                data: postData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    setselectedStaff([])
                    // window.location.reload();
                    fetchAttendanceData();
                }
            });
        }

    }

    const [open, setOpen] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        fetchProgramsData();
        fetchAttendanceData();
        // $(document).ready(function() {
        //     let $checkAll = $('#check-all');
        //     let $otherCheckboxes = $('input[type=checkbox]:not(#check-all)');        
        //     $checkAll.on('change', onCheckAllChange);
        //     $otherCheckboxes.on('change', onOtherCheckboxChange);        
        //     function onCheckAllChange() {
        //         $otherCheckboxes.prop('checked', $checkAll.prop('checked'));
        //     }        
        //     function onOtherCheckboxChange() {
        //         let allChecked = $otherCheckboxes.toArray().every(function(input) {
        //             return $(input).prop('checked');
        //         });
        //         $checkAll.prop('checked', allChecked);
        //     }
        // });
    }, []);

    return (
        <section>
            <div className="outer-container">
                <div id="left-panel" className="sp-left-panel">
                    <div className="sp-top-band">
                        <a className="sp-logo">
                            Scool Panda<sup>Beta</sup>
                        </a>
                        <div className="sp-search-top flexc">
                            <i className="icon-Search" />
                            <input
                                id="search"
                                className="sp-search-btn"
                                placeholder="Quick search..."
                            />
                        </div>
                    </div>
                    <div className="sp-middle-band">
                        <div className="sp-nav y-auto">
                            <div className="">
                                <h4 className="sp-title">Quick Actions</h4>
                                <ul>
                                    <li onClick={() => { navigate('/dashboard'); }}>
                                        <span>
                                            <i className="icon-Dashboard-Outline" />
                                            Dashboard
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/activity'); }}>
                                        <span>
                                            <i className="icon-Activity-Outline" />
                                            Activity
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/calendar'); }}>
                                        <span>
                                            <i className="icon-Timetable-Outline" />
                                            Calendar
                                        </span>
                                    </li>
                                    <li className="selected">
                                        <span>
                                            <i className="icon-Staff-Outline" />
                                            Staff
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i className="icon-Message-Outline" />
                                            Messages
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i className="icon-Fees-Outline" />
                                            Fees Management
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i className="icon-Enquiries-Outline" />
                                            Enquiries
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i className="icon-Invites-Outline" />
                                            Invites
                                        </span>
                                    </li>
                                </ul>
                                <h4 className="sp-title flexc justifySp">
                                    Programs{" "}
                                    <span id="add-programs" className="sp-plus">
                                        +
                                    </span>
                                </h4>
                                <ul>
                                    {programsLoading && programsData && programsData.length > 0 ?
                                        programsData.map((data) => {
                                            return (
                                                <li onClick={() => { navigate('/student_list'); }}>
                                                    <span>
                                                        <i className="icon-Program-Outline-1" />
                                                        {data.program_name}
                                                    </span>
                                                </li>
                                            );
                                        }) :
                                        <Loader type="spinner-cub" bgColor="#4D6EE3" size={30} />
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="sp-bottom sp-nav">
                            <ul>
                                <li>
                                    <span>
                                        <i className="icon-Import-Outline" />
                                        Notifications
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <i className="icon-Invites-Outline" />
                                        Upgrade Pro
                                    </span>
                                </li>
                                <li onClick={() => navigate("/signout")}>
                                    <span>
                                        <i className="icon-Invites-Outline" />
                                        Log Out
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="sp-right-panel">
                    <div className="sp-top-bar flexc justifySp">
                        <div className="ft-wt600 flexc">
                            <span className="selected-icon">
                                <i className="icon-Program-Outline-1" />
                            </span>
                            {localStorage.getItem('programName')}
                            <i className="icon-More-Icon" />
                        </div>
                        <div className="flexc">
                            <button className="sp-view-btn ft-wt500 flexc">
                                <i className="icon-Import-Outline" />
                                Import
                            </button>
                            <button className="createN ft-wt500 mr-lf10">+ New Student</button>
                        </div>
                    </div>
                    <div className="sp-header">
                        <nav className="flexc">
                            {/* <div class="sp-list-view flexc hidden"><i class="icon-Attendance-Outline"></i>Student List</div> */}
                            <div className="sp-list-view flexc" onClick={() => { navigate('/staff_list'); }}>
                                <i className="icon-Attendance-Outline" />
                                Staff List
                            </div>
                            <div className="sp-list-view flexc selected">
                                <i className="icon-Attendance-Outline" />
                                Attendance
                            </div>
                            <div className="sp-list-view flexc">
                                <i className="icon-Calendar-Outline" />
                                Timetable
                            </div>
                        </nav>
                        <div className="sp-menu-bar flexc justifySp">
                            <div className="sp-menu icon_div_space">
                                <Snackbar
                                    open={open}
                                    onClose={handleClose}
                                    autoHideDuration={3000}
                                    message={error}
                                />
                                <div className="flexc">
                                    <button className="sp_mbtn flexc" onClick={() => { addAttendance(1); }}>
                                        <span className="flexc ft-13">
                                            <span className="sp-lpdr  icon-Tick color_cha_whi" />
                                        </span>
                                        <span>Present</span>
                                    </button>
                                    <button className="sp_mbtn flexc excla_div" onClick={() => { addAttendance(2); }}>
                                        <span className="notapp-ele color_cha_whi   " />
                                        <span>Absent</span>
                                    </button>
                                    <button className="sp_mbtn flexc" onClick={() => { addAttendance(5); }}>
                                        <span className="cross-ele color_cha_whi leave_ic" />
                                        <span>Leave</span>
                                    </button>
                                    <div className="btn-search-bar flexc">
                                        <i className="icon-Search" />
                                        <input
                                            type="text"
                                            placeholder="Search Staff"
                                            value={filterValue}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {foodLoading && foodData ?
                            <div className="std-attence">
                                <div className="attence-top overxy-auto">
                                    <div className="students-add-list">
                                        <ul className="sp-head sp_sticky">
                                            <li className="table_input_sty">
                                                <input
                                                    type="checkbox"
                                                    id="check-all"
                                                    onChange={handleSelectAll}
                                                    checked={selectedStaff.length === foodHeaderdata.length}
                                                />
                                                {foodData.staff}
                                            </li>
                                            {dataHeader && dataHeader.length > 0 ?
                                                dataHeader.map((data, index) => (
                                                    <li key={index}>{data.date_data}</li>
                                                )) : <div></div>}
                                        </ul>
                                        {foodHeaderdata.map((student, studentIndex) => (
                                            <React.Fragment key={studentIndex}>
                                                <ul>
                                                    <li className="table_input_sty">
                                                        <input
                                                            type="checkbox"
                                                            className="sub_select"
                                                            onChange={() => handleSelectStudent(student.id)}
                                                            checked={selectedStaff.includes(student.id)}
                                                        />
                                                        <span className="ft-500 flexc">
                                                            <img src={student.profile_image || avatar1} width={40} alt="Avatar" />
                                                            <span className="mr-lf10 ft-14 truncate">{student.name}</span>
                                                        </span>
                                                    </li>
                                                    {student.attendance.map((attendanceData, attendanceIndex) => (
                                                        <React.Fragment key={attendanceIndex}>
                                                            <li>
                                                                {attendanceData.attendance_status === 1 ? (
                                                                    <span className="flexc ft-13">
                                                                        <span className="sp-lpdr icon-Tick " />
                                                                    </span>
                                                                ) : attendanceData.attendance_status === 2 ? (
                                                                    <span className="notapp-ele" />
                                                                ) : attendanceData.attendance_status > 2 ? (
                                                                    <span className="cross-ele" />
                                                                ) : null}
                                                            </li>
                                                        </React.Fragment>
                                                    ))}
                                                </ul>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </div> : foodHeaderdata.length === 0 ? <div className="sp-title flex-3">Data Not Found</div> : <Loader type="spinner-cub" bgColor="#4D6EE3" color="#4D6EE3" title="Loading..." size={50} />}
                    </div>
                </div>
                {/* my code */}
            </div>

        </section>
    );
}
