

import React, { useState, useMemo, useEffect, useRef } from "react";
import { Link, Navigate, Outlet, useNavigate } from "react-router-dom";
import "../signin/css/style.css";
import panda from "./images/panda.png";
// import {Alert} from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import spriteImage from './images/sprite-sp.svg';
import $ from "jquery";
import axios from 'axios';
import { baseUrl } from "../../common/common";
import Loader from "react-js-loader";
import validator from "validator";

export default function Signin() {

  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [error, setError] = useState(null);
  const passwordInputRef = useRef(null);

  const handleKeyDown = (event, targetRef) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submit
      targetRef.current && targetRef.current.focus();
    }
  };
  const login = () => {
    if (userName === "" || !validator.isEmail(userName)) {
      setError("Please Enter Valid Email")
    } else if (userPassword === "") {
      setError("Please Enter Valid Password")
    } else {
      setError(null);
      axios({
        method: "post",
        url: baseUrl + "/api/schoollogin",
        data: {
          username: userName,
          password: userPassword
        },
        headers: {
          "Content-Type": "multipart/form-data"
        },
      }).then((response) => {
        console.log(response);
        if (response.data.status === true) {
          localStorage.setItem('schoolId', response.data.data[0].id);
          // setTimeout(() => {
          //   navigate('/student_list');
          // }, 2500);
          navigate('/student_list');
          console.log(response);
        } else {
          setError(response.data.message);
        }
      });
    }

  }

  return (
    <section>
      <div className="whole-container sign_page">
        {/* Login Welcome back */}
        <div className="flex-prop form-wrap">
          <div className="form-container">
            <div className="mrg-cen text-align-c main_panda">
              <img src={panda} style={{ width: "80px", height: "100" }} width={80} height={100} alt="Scool Panda" />
              <h1>
                Welcome back!
                <span className="dis-block">
                  to transforming Learning Communication
                </span>
              </h1>
            </div>
            <div className="sign-form mt-40 add_style_input input_forsign">
              {/* <form method="post" onsubmit="event.preventDefault();"> */}
              {/* {error &&            
                    <Alert variant="danger">  
                        <div className="">{error}</div>
                    </Alert>
                  } */}
              <div className="forms-fields-text">

                <label htmlFor="name">Email Address</label>
                <div className="icon_input">
                  <input type="email"
                    value={userName}
                    onChange={ev => setUserName(ev.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, passwordInputRef)}
                    placeholder="johndoe@workemail.com"
                    style={error ? { border: '1px solid red' } : {}} />
                  <span className="sp-icon-mail" />
                </div>
                {error && <div className="error">{error}</div>}
              </div>
              <div className="forms-fields-text">
                <label htmlFor="name">Password</label>
                <div className="icon_input">
                  <input type="password"
                    ref={passwordInputRef}
                    value={userPassword}
                    onChange={ev => setUserPassword(ev.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && login()}
                    placeholder="8+ Character required"
                    style={error ? { border: '1px solid red' } : {}} />
                  <span className="sp-icon-pw" />
                </div>
                {error && <div className="error">{error}</div>}
              </div>
              <div className="flex-prop forms-field-btn differ_input">
                <div className="flex-prop toggleswitch">
                  <input type="checkbox" defaultValue="" id="toggle-s" />
                  <label
                    className="inline-flex items-center cursor-pointer"
                    htmlFor="toggle-s"
                  ></label>
                  <span className=" text-sm">Keep me login</span>
                </div>
                <div className="text-fwd">
                  <Link to="/forgot_password">Forgot password?</Link>
                </div>
              </div>
              <button onClick={login} className="verify-btn" id="sp-login">
                Login
              </button>
              <div className="form-bottom mt-40">
                <p>Or login using your Email</p>
                <div className="form-otherlogins">
                  <span className="sp-gmail-acc">Gmail</span>{" "}
                  <span className="sp-microsoft-acc">Microsoft</span>
                </div>
              </div>
              <div className="form-bottom mt-40 span_acount">
                <p>Don't have an account?<span > <Link to="/signup">Sign Up</Link> </span> </p>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
        {/* Login Welcome back */}
      </div>
    </section>
  );
}

