import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import avatar1 from "./images/avatar5.png";
import avatar5 from "./images/avatar5.png";
import slider from "./images/slider-image.png";
import profile from "./images/profile-photo.png";
import '../../common/components/js/common.js';
import { baseUrl } from "../../common/common";
import Loader from "react-js-loader";
import "../../common/components/css/style.css";
import Snackbar from '@mui/material/Snackbar';
import { Dropdown } from 'flowbite-react';
import "../../index.css";
import { HiCheck } from "react-icons/hi";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import imgDoc from "./images/doc.png";
import imgSend from "./images/send.png";

export default function Activity() {

    const navigate = useNavigate();

    const [activityData, setActivityData] = useState([]);
    const [activitysLoading, setActivityLoading] = useState(false);
    const fetchActivity = () => {
        //setActivityLoading(false);
        try {
            axios({
                method: "post",
                url: baseUrl + "/api/get_post",
                data: {
                    school_id: localStorage.getItem('schoolId'),
                    admin: 1,
                    limit_value: 25,
                    limit_offset: 0,
                    user_id: localStorage.getItem('schoolId')
                },
            }).then((response) => {
                console.log(response);
                if (response['data']['status'] === true) {
                    setActivityData(response.data.data);
                    setActivityLoading(true);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    const [programsData, setProgramsData] = useState({});
    const [programsLoading, setProgramsLoading] = useState(false);

    const fetchProgramsData = async () => {
        setProgramsLoading(false);
        try {
            const postData = {
                school_id: localStorage.getItem('schoolId')
                // school_id:24
            }
            const { data } = await axios.post(baseUrl + "/api/get_programs", postData);
            setProgramsData(data.data);
            setProgramsLoading(true);
        } catch (error) {
            console.log(error)
        }
    }

    const [template, setTemplate] = useState("");
    const [templateData, setTemplateData] = useState([]);
    const selectedTemplate = templateData.find(data => data.id === template);
    const fetchTemplateData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/get_template",
            data: {
                school_id: localStorage.getItem('schoolId')
            },
            headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setTemplateData(response.data.data);
                console.log(response);
            }
        });
    }

    const [fileError, setFileError] = useState('');
    const [selectedImage, setSelectedImage] = useState([])
    const [selectedDocument, setSelectedDocument] = useState([])
    //------------------- image upload -------------
    // const handleImageChange = (event) => {
    //     const files = event.target.files;
    //     const file = event.target.files[0];
    //     setSelectedImage(file);
    // };
    // ----------------------------------------------
    //------------------- document upload -----------
    // const handleDocumentChange = (event) => {
    //     const files = event.target.files;
    //     const file = event.target.files[0];
    //     setSelectedDocument(file);
    // };
    // ----------------------------------------------

    const [postTitle, setPostTitle] = useState('');
    const handleImageChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
        const maxSize = 5 * 1024 * 1024; // 5MB
        let invalidFiles = [];
        let validFiles = [];

        selectedFiles.forEach(file => {
            if (!validTypes.includes(file.type)) {
                invalidFiles.push(file);
            } else if (file.size > maxSize) {
                invalidFiles.push(file);
            } else {
                validFiles.push(file);
            }
        });

        if (invalidFiles.length > 0) {
            setFileError('Some files are invalid. Only JPG, PNG, and GIF images under 5MB are allowed.');
            setOpen(true);
        }

        if (validFiles.length > 0) {
            setSelectedImage(validFiles);
        }
    };

    const handleDocumentChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const validTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
        const maxSize = 5 * 1024 * 1024; // 5MB
        let invalidFiles = [];
        let validFiles = [];

        selectedFiles.forEach(file => {
            if (!validTypes.includes(file.type)) {
                invalidFiles.push(file);
            } else if (file.size > maxSize) {
                invalidFiles.push(file);
            } else {
                validFiles.push(file);
            }
        });

        if (invalidFiles.length > 0) {
            setFileError('Some files are invalid. Only PDF and Word documents under 5MB are allowed.');
            setOpen(true);
        }

        if (validFiles.length > 0) {
            setSelectedDocument(validFiles);
        }
    };

    const downloadDocument = async (documentLink, documentName) => {
        const response = await fetch(baseUrl + "/api/" + documentLink);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = documentName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    const [open, setOpen] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const convertRawContentToHTML = (rawContent) => {
        let html = '';

        rawContent.blocks.forEach((block) => {
            let blockHTML = '';
            switch (block.type) {
                case 'unstyled':
                    blockHTML += `<p>${block.text}</p>`;
                    break;
                case 'header-one':
                    blockHTML += `<h1>${block.text}</h1>`;
                    break;
                case 'header-two':
                    blockHTML += `<h2>${block.text}</h2>`;
                    break;
                case 'header-three':
                    blockHTML += `<h3>${block.text}</h3>`;
                    break;
                case 'header-four':
                    blockHTML += `<h4>${block.text}</h4>`;
                    break;
                case 'header-five':
                    blockHTML += `<h5>${block.text}</h5>`;
                    break;
                case 'header-six':
                    blockHTML += `<h6>${block.text}</h6>`;
                    break;
                case 'blockquote':
                    blockHTML += `<blockquote>${block.text}</blockquote>`;
                    break;
                case 'unordered-list-item':
                    blockHTML += `<li>${block.text}</li>`;
                    break;
                case 'ordered-list-item':
                    blockHTML += `<li>${block.text}</li>`;
                    break;
                default:
                    blockHTML += `<p>${block.text}</p>`;
                    break;
            }
            html += blockHTML;
        });

        return html;
    };


    const slideRef = useRef(null);

    const nextSlide = () => {
        slideRef.current.goNext();
    };

    const previousSlide = () => {
        slideRef.current.goBack();
    };

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [activity, setActivity] = useState({});
    const openModal = (data) => {
        console.log(data)
        setActivity(data)
        console.log(activity)
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
        $('.modal-lightbox').removeClass('visible');
    };

    const divStyle = {
        // Define your div styles here
        height: '400px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    };

    const spanStyle = {
        // Define your span styles here
        position: 'absolute',
        bottom: '20px',
        left: '20px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        padding: '10px',
    };

    const onEditorStateChange = (newState) => {
        setEditorState(newState);
        const contentState = newState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);
        const html = convertRawContentToHTML(rawContentState);
        console.log(html);
        setPostContent(html)
    };

    const getHTMLValue = () => {
        const contentState = editorState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);
        const html = convertRawContentToHTML(rawContentState);
        console.log(html);
        setPostContent(html)

    };

    const slideImages = [
        {
            url: 'https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
            caption: 'Slide 1'
        },
        {
            url: 'https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80',
            caption: 'Slide 2'
        },
        {
            url: 'https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
            caption: 'Slide 3'
        },
    ];

    const [postContent, setPostContent] = useState("");

    const addPost = () => {
        if (selectedImage.length === 0 && selectedDocument.length === 0 && postTitle === '') {
            setFileError("any one field is required")
            setOpen(true);
        }
        // else if (selectedDocument.length === 0) {
        //     setFileError("Please Choose Document")
        //     setOpen(true);
        // }
        // else if (postContent === '') {
        //     setFileError("Please Enter Post Content")
        //     setOpen(true);
        // } 
        // else if (template === '') {
        //     setFileError("Please Select Teplate")
        //     setOpen(true);
        // } 
        else {
            const postData = {
                staff_id: 5,
                post_image: selectedImage,
                attachment_file: selectedDocument,
                template_id: 2,
                post_title: postTitle,
                post_content: postContent,
                reminder: "",
                admin: 1,
                school_id: localStorage.getItem('schoolId'),
                programs_id: programId,
                status: 1,
                created_by: localStorage.getItem('schoolId')
            }
            console.log(postData)

            axios({
                method: "post",
                url: baseUrl + "/api/post_add_edit",
                data: postData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    setSelectedImage([]);
                    setSelectedDocument([]);
                    setTemplate('');
                    setPostContent('');
                    setPostContent("");
                    setEditorState(EditorState.createEmpty());
                    $('.announce-post-inr').slideUp();
                    fetchActivity();
                }
            });
        }

    }

    const [programId, setProgramId] = useState(0);
    const [programs, setPrograms] = useState([]);
    const fetchPrograms = async () => {
        try {
            const response = await axios.post(baseUrl + "/api/get_programs", { school_id: localStorage.getItem('schoolId') });
            setPrograms(response.data.data);
        } catch (error) {
        }
    };

    const [comment, setComment] = useState("");

    const handleSendComment = (postId) => {
        try {
            axios({
                method: "post",
                url: baseUrl + "/api/comments_add_edit",
                data: {
                    school_id: localStorage.getItem('schoolId'),
                    post_id: postId,
                    user_id: localStorage.getItem('schoolId'),
                    user_name: "admin",
                    role_id: 4,
                    comment: comment,
                    status: 1,
                    created_by: localStorage.getItem('schoolId'),
                    updated_by: localStorage.getItem('schoolId')
                },
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    setComment('');
                    setIsModalVisible(false);
                    $('.modal-lightbox').removeClass('visible');
                    fetchActivity();
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    const handleSendLike = (postId, status) => {
        try {
            axios({
                method: "post",
                url: baseUrl + "/api/" + (status === "add" ? "likes_add" : "likes_delete"),
                data: status === "add" ? {
                    school_id: localStorage.getItem('schoolId'),
                    post_id: postId,
                    user_id: localStorage.getItem('schoolId'),
                    role_id: 4,
                    status: 1,
                    created_by: localStorage.getItem('schoolId'),
                    updated_by: localStorage.getItem('schoolId'),
                } : {
                    post_id: postId,
                    user_id: localStorage.getItem('schoolId'),
                    role_id: 4
                },
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    fetchActivity();
                }
            }).catch((error) => {
                console.log(error);
                // Handle error here
            });
        } catch (error) {
            console.log(error);
            // Handle error here if axios request fails to initialize
        }
    };

    const extractTextFromHTML = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    const wordCount = (text) => {
        return text.split(/\s+/).filter(word => word.length > 0).length;
    };

    useEffect(() => {
        fetchProgramsData();
        fetchPrograms();
        fetchTemplateData();
        fetchActivity();
        // $(document).on('click', '.post-images', function () {
        //     $('.modal-lightbox').addClass('visible');
        // })
        // $(document).on('click', '.modal-lightbox .close', function () {
        //     $('.modal-lightbox').removeClass('visible');
        // });
        $("#search-input").keyup(function () {
            var searchTerm = $(this).val().toLowerCase();

            $(".search-items li").each(function () {
                var itemText = $(this).text().toLowerCase();

                if (itemText.indexOf(searchTerm) > -1) {

                    $(this).show();
                } else {
                    $(this).hide();
                }
            });
        });
        const $targetEl = document.getElementById('schoolswitch');

        // options with default values
        const options = {
            placement: 'bottom-right',
            backdrop: 'dynamic',
            backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
            closable: true,
            onHide: () => {
                console.log('modal is hidden');
            },
            onShow: () => {
                console.log('modal is shown');
            },
            onToggle: () => {
                console.log('modal has been toggled');
            }
        };
        $(document).on('click', '#new-template', function () {
            $('.overlay').addClass('visible');
            $('.popup-modal-container#create-template-popup').addClass('visible')
        });

        $('.act-announce-inp input').on('click', function () {
            $('.announce-post-inr').slideDown();
        });
    }, []);

    return (
        <section>
            <div className="outer-container">
                <div id="left-panel" className="sp-left-panel">
                    <div className="sp-top-band">
                        <a className="sp-logo">
                            Scool Panda<sup>Beta</sup>
                        </a>
                        <div className="sp-search-top flexc">
                            <i className="icon-Search" />
                            <input
                                id="search"
                                className="sp-search-btn"
                                placeholder="Quick search..."
                            />
                        </div>
                    </div>
                    <div className="sp-middle-band">
                        <div className="sp-nav y-auto">
                            <div className="">
                                <h4 className="sp-title">Quick Actions</h4>
                                <ul>
                                    <li onClick={() => { navigate('/dashboard'); }}>
                                        <span>
                                            <i className="icon-Dashboard-Outline" />
                                            Dashboard
                                        </span>
                                    </li>
                                    <li className="selected">
                                        <span>
                                            <i className="icon-Activity-Outline" />
                                            Activity
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/calendar'); }}>
                                        <span>
                                            <i className="icon-Timetable-Outline" />
                                            Calendar
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/staff_list'); }}>
                                        <span>
                                            <i className="icon-Staff-Outline" />
                                            Staff
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i className="icon-Message-Outline" />
                                            Messages
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i className="icon-Fees-Outline" />
                                            Fees Management
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i className="icon-Enquiries-Outline" />
                                            Enquiries
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i className="icon-Invites-Outline" />
                                            Invites
                                        </span>
                                    </li>
                                </ul>
                                <h4 className="sp-title flexc justifySp">
                                    Programs{" "}
                                    <span id="add-programs" className="sp-plus">
                                        +
                                    </span>
                                </h4>
                                <ul>
                                    {programsLoading && programsData && programsData.length > 0 ?
                                        programsData.map((data) => {
                                            return (
                                                <li onClick={() => { navigate('/student_list'); }}>
                                                    <span>
                                                        <i className="icon-Program-Outline-1" />
                                                        {data.program_name}
                                                    </span>
                                                </li>
                                            );
                                        }) :
                                        <Loader type="spinner-cub" bgColor="#4D6EE3" size={30} />
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="sp-bottom sp-nav">
                            <ul>
                                <li>
                                    <span>
                                        <i className="icon-Import-Outline" />
                                        Notifications
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <i className="icon-Invites-Outline" />
                                        Upgrade Pro
                                    </span>
                                </li>
                                <li onClick={() => navigate("/signout")}>
                                    <span>
                                        <i className="icon-Invites-Outline" />
                                        Log Out
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* my code */}
                <div className="sp-right-panel">
                    <div className="activity-container">
                        <div className="activity-inr">
                            <div className="act-announce-sh">
                                <div className="act-announce-inp flexc act_input">
                                    <img src={avatar1} width={38} height={38} />
                                    <input
                                        type="text"
                                        placeholder="What's an announcement today?"
                                        value={postTitle}
                                        onChange={ev => setPostTitle(ev.target.value)}
                                    />
                                </div>
                                <div className="announce-post-inr">
                                    <Editor
                                        editorState={editorState}
                                        toolbarHidden
                                        editorClassName="editorClassName"
                                        onEditorStateChange={onEditorStateChange}
                                    />
                                    <div className="announce-post-cont flexc justifySp">
                                        <div className="announce-post-left flexc">
                                            <Snackbar
                                                open={open}
                                                onClose={handleClose}
                                                autoHideDuration={3000}
                                                message={fileError}
                                            />
                                            <span className="ft-13" style={{ marginLeft: "10px", width: "100%" }}>
                                                <div>
                                                    <input
                                                        type="file"
                                                        id="imageInput"
                                                        style={{ display: "none", width: "100%" }}
                                                        accept=".jpg, .jpeg, .png, .gif"
                                                        multiple
                                                        onChange={handleImageChange}
                                                    />
                                                    <label
                                                        className="sp-post-bt ft-wt500 flexc"
                                                        htmlFor="imageInput"
                                                        style={{ paddingRight: "10px" }}
                                                    >
                                                        <img
                                                            src={imgDoc}
                                                            className="mr-rt10"
                                                            alt="avatar"
                                                        />
                                                        Photo
                                                    </label>
                                                    {selectedImage.length > 0 && <p style={{ fontSize: "10px" }}>{selectedImage.length} file{selectedImage.length > 1 && "s"} choosed</p>}

                                                </div>
                                            </span>
                                            <span className="ft-13" style={{ marginLeft: "10px", width: "100%", cursor: "pointer" }}>
                                                <div>
                                                    <input
                                                        type="file"
                                                        id="documentInput"
                                                        style={{ display: "none", width: "100%" }}
                                                        accept=".pdf, .doc, .docx"
                                                        multiple
                                                        onChange={handleDocumentChange}
                                                    />
                                                    <label
                                                        className="sp-post-bt ft-wt500 flexc"
                                                        htmlFor="documentInput"
                                                        style={{ paddingRight: "10px" }}
                                                    >
                                                        <img
                                                            src={imgDoc}
                                                            className="mr-rt10"
                                                            alt="avatar"
                                                        />
                                                        Document
                                                    </label>
                                                    {selectedDocument.length > 0 && <p style={{ fontSize: "10px" }}>{selectedDocument.length} file{selectedDocument.length > 1 && "s"} choosed</p>}

                                                </div>
                                            </span>

                                            <span className="flexc ft-13 sp-post-bt" style={{ marginLeft: "10px", cursor: "pointer" }}>
                                                <div className="act_program" >
                                                    <select
                                                        type="text"
                                                        value={programId}
                                                        onChange={ev => setProgramId(ev.target.value)}
                                                        style={{
                                                            borderColor: "unset",
                                                            borderRadius: "unset",
                                                            padding: "0px",
                                                            paddingRight: "2.5rem",
                                                            border: "unset",
                                                            lineHeight: "1rem"
                                                        }}
                                                    >
                                                        <option value={0}>All Program</option>
                                                        {programs.map(data => (
                                                            <option key={data.id} value={data.id} >
                                                                {data.program_name}
                                                            </option>
                                                        ))}
                                                    </select>

                                                </div>
                                            </span>
                                            <span className="flexc ft-13 sp-post-bt" style={{ marginLeft: "20px", background: "unset" }}>

                                            </span>
                                        </div>
                                        <button className="flexc" onClick={() => { addPost(); }}>
                                            {/* <i className="fa fa-paper-plane" style={{ marginRight: "5px" }} /> */}
                                            <img
                                                src={imgSend}
                                                // className="mr-rt10"
                                                alt="avatar"
                                                style={{ marginRight: "3px" }}
                                            />
                                            Post
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="sp-announcement">
                                {activitysLoading && activityData && activityData.length > 0 ? (
                                    activityData.map((data, index) => {
                                        const textContent = extractTextFromHTML(data.post_content);
                                        const isContentLong = wordCount(textContent) > 50;
                                        return (
                                            <div key={index} className="announcement-posts act-announce-sh mt-4">
                                                <div className="annonce-pers flexc justifySp">
                                                    <div className="annonce-pers-left flexc">
                                                        <img
                                                            src={avatar1}
                                                            className="mr-rt10"
                                                            width={38}
                                                            height={38}
                                                            alt="avatar"
                                                        />
                                                        <div className="annonce-pers-left-cont">
                                                            <p>
                                                                <span className="ft-wt700">{data.user}</span>
                                                                <span className="sp-crt-dot" />{data.program_name}
                                                            </p>
                                                            <span className="post-re-tm ft-13">{data.created_on}</span>
                                                        </div>
                                                    </div>
                                                    <span>
                                                        <i className="icon-More-Icon" />
                                                    </span>
                                                </div>
                                                <div className="annoce-poster-inr">
                                                    <span className="ft-wt700">{data.post_title}</span><br />
                                                    <div className="mt-4">
                                                        <div dangerouslySetInnerHTML={{ __html: data.post_content }} />
                                                        {isContentLong && (
                                                            <div className="ft-13 mt-4 ft-wt600" style={{ cursor: "pointer" }} onClick={() => { openModal(data); }}>See more...</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="post-images flex gap-1 flex-wrap cp mt-4" onClick={() => { openModal(data); }}>
                                                    {data.post_image && data.post_image.length > 0 ? (
                                                        <>
                                                            <div className="image-row flexc justifySp three-column">
                                                                {data.post_image.slice(0, 3).map((image, index) => (
                                                                    <img key={index} src={baseUrl + "/api/" + image} alt={`Image ${index + 1}`} />
                                                                ))}
                                                            </div>
                                                            {data.post_image.length > 3 && (
                                                                <div className="image-row flexc two-column justifySp relative">
                                                                    {data.post_image.slice(3, 5).map((image, index) => (
                                                                        <img key={index} src={baseUrl + "/api/" + image} alt={`Image ${index + 4}`} />
                                                                    ))}
                                                                    {data.post_image.length > 4 &&
                                                                        <div className="more-images absolute">
                                                                            <span className="more-text ft-17">+{data.post_image.length - 5}</span>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (<div></div>)}
                                                </div>
                                                <div>
                                                    {data.document && data.document.length > 0 ? (
                                                        data.document.map((documentData, docIndex) => (
                                                            <div key={docIndex} className="announce-post-left" style={{ marginTop: "5px" }}>
                                                                <span onClick={() => { downloadDocument(documentData.attachment_file, documentData.attachment_name); }} className="flexc ft-13 sp-post-bt" style={{ cursor: "pointer", width: "50%" }}>
                                                                    <i className="icon-Enquiries-Outline" />
                                                                    <p style={{ fontSize: "10px" }}>{documentData.attachment_name}</p>
                                                                </span>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div></div>
                                                    )}
                                                </div>
                                                <div className="annoce-poster-btm flexc mt-4">
                                                    <span className="flexc" style={{ cursor: "pointer" }} onClick={() => { handleSendLike(data.id, (!data.like ? "add" : "delete")) }}>
                                                        <div className="act_like">
                                                            <i className={`${!data.like ? 'fa fa-heart-o' : 'fa fa-heart'}`}
                                                                aria-hidden="true"></i>
                                                        </div>
                                                        {data.likes || 0}
                                                    </span>
                                                    <span className="flexc" style={{ cursor: "pointer" }} onClick={() => { $(`#post-comments-inr-${index}`).slideToggle() }}>
                                                        <i className="icon-Message-Outline" />{data.comments || 0}
                                                    </span>
                                                </div>
                                                <div id={`post-comments-inr-${index}`} className="post-comments-inr" style={{ display: "none" }}>
                                                    <div className="comments-box">
                                                        {
                                                            data.comments_data.length !== 0 &&
                                                            <div className="comments">
                                                                {data.comments_data.map((comment, commentIndex) => (
                                                                    <div className="mt-5 borderbt pb-2.5" key={commentIndex}>
                                                                        <div className="annonce-pers-left flexc">
                                                                            <img
                                                                                src={avatar5}
                                                                                className="mr-rt10"
                                                                                width={38}
                                                                                height={38}
                                                                                alt="avatar"
                                                                            />
                                                                            <div className="annonce-pers-left-cont">
                                                                                <p className="ft-13">
                                                                                    <span className="ft-wt600">{comment.user_name}</span>
                                                                                </p>
                                                                                <span className="post-re-tm ft-12">{new Date(comment.created_on).toLocaleString('en-US', {
                                                                                    year: 'numeric',
                                                                                    month: 'long',
                                                                                    day: 'numeric',
                                                                                    hour: '2-digit',
                                                                                    minute: '2-digit',
                                                                                    hour12: true
                                                                                })}</span>
                                                                            </div>
                                                                        </div>
                                                                        <p className="ft-13 leading-normal mt-4">
                                                                            {comment.comment}
                                                                        </p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        }

                                                        <div className="sticky comment-type">
                                                            <div className="flexc flex-wrap">
                                                                <div className="profile-pic">
                                                                    <a>
                                                                        <img src={profile} className="rounded-full" />
                                                                    </a>
                                                                </div>
                                                                <div className="box-comment ml-2">
                                                                    <div className="input-container">
                                                                        <input
                                                                            type="text"
                                                                            value={comment}
                                                                            onChange={ev => setComment(ev.target.value)}
                                                                            placeholder="Write a comment"
                                                                            className="input-cmt ft-13"
                                                                        />
                                                                        <i
                                                                            className={`fa fa-paper-plane ${!comment.trim() ? 'disabled' : ''}`} aria-hidden="true"
                                                                            onClick={() => { handleSendComment(data.id) }}
                                                                        />
                                                                        {/* <i
                                                                            className={`icon-Message-Outline ${!comment.trim() ? 'disabled' : ''}`}
                                                                            onClick={() => { handleSendComment(data.id) }}
                                                                        /> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <Loader type="spinner-cub" bgColor="#4D6EE3" size={30} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* light-box */}
            {isModalVisible && (
                <div className="modal-lightbox flex visible">
                    {
                        (activity.post_image.length > 1) ? (
                            <div className="img-container flexc alignC justify-center relative">
                                <span className="left slide-arrow absolute left-10">
                                    <button className="active_col left-arr" onClick={previousSlide}>
                                        <span className="arrow"></span>
                                    </button>
                                </span>
                                <div className="slide-container" style={{ width: "60%" }}>
                                    <Slide ref={slideRef} arrows={false}>
                                        {activity.post_image.map((slideImage, index) => (
                                            <img
                                                key={index}
                                                src={baseUrl + "/api/" + slideImage}
                                                alt={`Image ${index + 1}`}
                                                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                            />
                                        ))}
                                    </Slide>
                                </div>
                                <span className="right slide-arrow absolute right-10">
                                    <button className="active_col right-arr" onClick={nextSlide}>
                                        <span className="arrow"></span>
                                    </button>
                                </span>
                            </div>
                        ) : (
                            <div className="img-container flexc alignC justify-center relative">
                                <span className="left slide-arrow absolute left-10" style={{ backgroundColor: "unset" }}>
                                    <button className="active_col left-arr"></button>
                                </span>
                                <div className="images-slide-box flexc alignC">
                                    {activity.post_image.length === 1 &&
                                        <img
                                            src={baseUrl + "/api/" + activity.post_image[0]}
                                            alt="slide image"
                                            style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                        />
                                    }

                                </div>
                                <span className="right slide-arrow absolute right-10" style={{ backgroundColor: "unset" }}>
                                    <button className="active_col right-arr"></button>
                                </span>
                            </div>
                        )
                    }
                    <div className="message-box p-6 overflow-y-auto h-screen py-0">
                        <span className="close icon-Dropdown_Close flexc cp ft-10" onClick={closeModal} />
                        <div className="annonce-pers-left flexc mt-7">
                            <img
                                src={avatar5}
                                className="mr-rt10"
                                width={38}
                                height={38}
                            />
                            <div className="annonce-pers-left-cont">
                                <p className="ft-13">
                                    <span className="ft-wt600">{activity.user}</span>
                                    <span className="sp-crt-dot" />{activity.program_name}</p>
                                <span className="post-re-tm ft-12">{activity.created_on}</span>
                            </div>
                        </div>
                        <div className="annoce-poster-inr">
                            <div dangerouslySetInnerHTML={{ __html: activity.post_content }} />
                        </div>
                        <div className="comments-box">
                            {activity.comments_data.map((comment, index) => (
                                <div className="mt-5 borderbt pb-2.5">
                                    <div className="annonce-pers-left flexc">
                                        <img
                                            src={avatar5}
                                            className="mr-rt10"
                                            width={38}
                                            height={38}
                                        />
                                        <div className="annonce-pers-left-cont">
                                            <p className="ft-13">
                                                <span className="ft-wt600">{comment.user_name}</span>
                                            </p>
                                            <span className="post-re-tm ft-12">{new Date(comment.created_on).toLocaleString('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true
                                            })}</span>
                                        </div>
                                    </div>
                                    <p className="ft-13 leading-normal mt-4">
                                        {comment.comment}
                                    </p>
                                </div>
                            ))}
                            <div className="sticky comment-type">
                                <div className="flexc flex-wrap">
                                    <div className="profile-pic">
                                        <a href="javascript:void(0)">
                                            <img src={profile} className="rounded-full" />
                                        </a>
                                    </div>
                                    <div className="box-comment ml-2">
                                        <div className="input-container">
                                            <input
                                                type="text"
                                                value={comment}
                                                onChange={ev => setComment(ev.target.value)}
                                                placeholder="Write a comment"
                                                className="input-cmt ft-13"
                                            />
                                            <i
                                                className={`fa fa-paper-plane ${!comment.trim() ? 'disabled' : ''}`} aria-hidden="true"
                                                onClick={() => { handleSendComment(activity.id) }}
                                            />
                                            {/* <i
                                                className={`icon-Message-Outline ${!comment.trim() ? 'disabled' : ''}`}
                                                onClick={() => { handleSendComment(activity.id) }}
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
            {/* light-box */}
            <div className="popup-modal-container" id="create-template-popup">
                <div className="popup-wrap activ-pop">
                    <h2 className="ft-wt700">Add New Template</h2>
                    <div className="sp-pop-header">
                        <div className="form-section-default flexc justifySp fwrap">
                            <div className="field-label halfplus-field">
                                <label>Template Name</label>
                                <div className="field-value-holder margin_top10p">
                                    <input className="sp-field-input" placeholder="Type name.." />
                                </div>
                            </div>
                            <div className="field-label halfmin-field">
                                <label>Template Type</label>
                                <div
                                    id="dropdownHelperButton"
                                    className="btn-dropdown margin_top10p"
                                    data-dropdown-toggle="dropdownHelper1"
                                    data-dropdown-offset-distance={1}
                                    data-dropdown-placement="bottom"
                                >
                                    <span className="flexc justifySp">
                                        Select Type
                                        <i className="icon-Down-Arrow" />
                                    </span>
                                </div>
                                <div className="btn-dropdown-fieldval hidden" id="dropdownHelper1">
                                    <ul className="activity-temp">
                                        <li className="selected">
                                            <span className="icon-rating sp-red-theme1" />
                                            Announcement
                                        </li>
                                        <li>
                                            <span className="icon-rating sp-green-theme1" />
                                            Event
                                        </li>
                                        <li>
                                            <span className="icon-rating sp-red-theme1" />
                                            Special Day
                                        </li>
                                        <li>
                                            <span className="icon-rating sp-orange-theme1" />
                                            Holiday
                                        </li>
                                        <li className="flexc new-doc mt-2">
                                            <i className="icon-Enquiries-Outline sp-violet-theme1" />
                                            Create New
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="field-label full-field">
                                <div className="field-value-holder margin_top10p">
                                    <div className="text-decrt">
                                        <button className="flexc ft-13 text-styl">
                                            Normal Text
                                            <span className="arrow up-arr" />
                                        </button>
                                    </div>
                                    <textarea
                                        className="sp-field-input"
                                        rows={10}
                                        defaultValue={""}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sp-bottom-sticky">
                        <button className="sp-btn-create" id="save-btn">
                            Save
                        </button>
                        <button className="sp-btn-cancel">Cancel</button>
                    </div>
                </div>
            </div>
        </section>
    );
}





