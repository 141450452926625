import $ from "jquery";

$(document).ready(function() {

    $(".search-sch-list").keypress(function(event) {
        $('.next-btn').attr('disabled', false);  
        var value = $(this).val().toLowerCase();
        $('#schoolList').addClass('active');
        $("#schoolList div").filter(function() {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
        $('.close-search').removeClass('active');
        $(".sch-text-op").empty();
    });
    $("#schoolList div.search-li ").click(function() {
        $(".sch-text-op ").empty();
        $(".search-sch-list ").val('');
        $(".sch-text-op ").append($(this).find('span:first-child').html() + '<span>' + $(this).find('.sch-name').data('schl-name') + '</span> - ' + '<span>' + $(this).find('em:last-child').data('schl-loc') + '</span>');
        $(".search-sch-list ").val($(this).find('.sch-name').data('schl-name'));
        $('#schoolList').removeClass('active');
        $('.close-search').addClass('active');
        $('.next-btn').attr('disabled', false);
    });
    $(document).on('click', '.close-search', function() {
        $('.close-search').removeClass('active');
        $(".sch-text-op ").empty();
        $(".search-sch-list ").val('');
        $('.next-btn').attr('disabled', true);
    });
    $('.last_div').click(function() {
        $('.next-btn').hide();
        $('.last_button').show();
        
    });
    $(document).on('click', '.next-btn', function() {
        $('.box-container>div.sp-sec-active').addClass('completed');
        $('.box-container>div.sp-sec-active').removeClass('sp-sec-active').next().addClass('sp-sec-active');
        if ($('.box-container>div.sp-sec-active').hasClass('completed')) {
            $('.next-btn').attr('disabled', false);
        } else {
            $('.next-btn').attr('disabled', true);
        }
        var count = 0;
        $('.box-container>div').each(function(index) {
            if (!$(this).hasClass('completed')) {
                count += 1;
            }
        });
        $('.progress-bar').find('.progress-bar-fill').css('width', parseFloat(100 / (count)) + "%");
    });
    $(document).on('click', '.scl-size-cont button', function() {
        $('.scl-size-cont button').removeClass('sp-selected');
       $('.label-container input').removeAttr('checked');
        $(this).addClass('sp-selected');
        $(this).find('.label-container input').attr('checked', 'checked');
        $('.box-container>div.sp-sec-active').addClass('completed');
        $('.next-btn').attr('disabled', false);
    });
    $(document).on('click', '.scl-prg button', function() {
        if ($(this).hasClass('sp-selected')) {
            $(this).removeClass('sp-selected');
            $(this).find('.label-container input').removeAttr('checked');
        } else {
            $(this).addClass('sp-selected');
            $(this).find('.label-container input').attr('checked', 'checked');
        }
        $('.box-container>div.sp-sec-active').addClass('completed');
        $('.next-btn').attr('disabled', false);
    });

});