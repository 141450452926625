import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useStateContext } from "../../context/ContextProvider.jsx";
import "../../common/components/css/style1.css";
import panda_image from "./images/panda.png";
import password_image from "./images/password-icon.png";
import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import $ from "jquery";
import Snackbar from '@mui/material/Snackbar';
import validator from 'validator';
import { baseUrl } from "../../common/common";

export default function Signup() {
    
    const [status, setStatus] = useState(false);
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [password, setPassword] = useState("");
    const [termsChecked, setTermsChecked] = useState(true);

    const [fullNameError, setFullNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [mobileNumberError, setMobileNumberError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [termsCheckedError, setTermsCheckedError] = useState(null);

    const navigate = useNavigate();

    const [verificationId, setVerificationId] = useState("");
    const [otp, setOTP] = useState("");
    const [otpError, setOtpError] = useState(null);

    let firebaseConfig = {
        apiKey: "AIzaSyDxStEcHP8L-wZMdcEwnklVSl4lHovSXuw",
        authDomain: "scool-panda.firebaseapp.com",
        projectId: "scool-panda",
        storageBucket: "scool-panda.appspot.com",
        messagingSenderId: "144297568045",
        appId: "1:144297568045:web:3e141a30b477e8409bd5a4",
        measurementId: "G-EDN8HRGH52"
    };

    firebase.initializeApp(firebaseConfig);

    const sendOTP = async () => {
        let valid = true;
        if (fullName === "") {
            setFullNameError("Please Enter Name");
            valid = false;
        } else {
            setFullNameError(null);
        }
        if (!validator.isEmail(email)) {
            setEmailError("Please Enter Valid Email");
            valid = false;
        } else {
            setEmailError(null);
        }
        if (!validator.isMobilePhone(mobileNumber.trim()) || mobileNumber.trim().length !== 10) {
            setMobileNumberError("Please Enter Valid Mobile Number");
            valid = false;
        } else {
            setMobileNumberError(null);
        }
        if (password === "") {
            setPasswordError("Please Enter Password");
            valid = false;
        } else {
            setPasswordError(null);
        }
        if (termsChecked === false) {
            setTermsCheckedError("Please agree to the Terms & Conditions and Privacy Policy");
            valid = false;
        } else {
            setTermsCheckedError(null);
        }
        if (valid) {
            await axios({
                method: "post",
                url: baseUrl + "/api/school_register_validation",
                data: {
                    phone: mobileNumber.trim(),
                    email: email.trim(),
                    password: password.trim()
                },
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                if (response['data']['status'] == true) {
                    setMobileNumberError(null);
                    setEmailError(null);
                    setPasswordError(null);
                    setStatus(true);
                    setOpen(true);
                    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
                        "recaptcha-container", {
                        size: "invisible"
                    });
                    firebase
                        .auth()
                        .signInWithPhoneNumber("+91" + mobileNumber, recaptchaVerifier)
                        .then((confirmationResult) => {
                            setVerificationId(confirmationResult.verificationId);
                            setOtpError(null);
                        })
                        .catch((error) => {
                            setOtpError(error.message);
                        });
                } else {
                    setMobileNumberError(response['data']['phone_error']);
                    setEmailError(response['data']['email_error']);
                    setPasswordError(response['data']['password_error']);
                }
            });
        }
    };

    const verifyOTP = () => {
        const credential = firebase.auth.PhoneAuthProvider.credential(
            verificationId,
            otpValues.join('')
        );

        firebase
            .auth()
            .signInWithCredential(credential)
            .then((result) => {
                navigate('/school_info',
                    {
                        state: {
                            name: fullName,
                            email: email,
                            phone: mobileNumber,
                            password: password,
                            agree: termsChecked === true ? 1 : 0,
                            otp: otpValues.join(''),
                        }
                    }
                );
            })
            .catch((error) => {
                setOtpError(error.message);
            });
    };

    const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
    const handleChange = (index, value) => {
        const newOtpValues = [...otpValues];
        newOtpValues[index] = value;
        setOtpValues(newOtpValues);
        if (value && index < 5) {
            document.getElementById(`otpInput${index + 1}`).focus();
        }
    };

    const [open, setOpen] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleKeyDown = (e, nextInputId) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const nextInput = document.getElementById(nextInputId);
            if (nextInput) {
                nextInput.focus();
            }
        }
    };


    // const [searchTerm, setSearchTerm] = useState('');

    // const handleSearch = (event) => {
    //     setSearchTerm(event.target.value);
    // };

    // const filteredCountries = countries.filter(country =>
    //     country.name.toLowerCase().includes(searchTerm.toLowerCase())
    // );
    const [countries] = useState([
        { name: "Afghanistan", code: "AF", phone: 93 },
        { name: "Aland Islands", code: "AX", phone: 358 },
        { name: "Albania", code: "AL", phone: 355 },
        { name: "Algeria", code: "DZ", phone: 213 },
        { name: "American Samoa", code: "AS", phone: 1684 },
      ]);
    
      const [searchQuery, setSearchQuery] = useState('');
      const [selectedCountry, setSelectedCountry] = useState({
        name: "United Kingdom",
        code: "GB",
        phone: 44,
      });
    
      const selectBoxRef = useRef(null);
      const searchBoxRef = useRef(null);
    
      const handleSelectOption = (country) => {
        setSelectedCountry(country);
        selectBoxRef.current.classList.remove('active');
      };
    
      const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
      };
    
      const handleToggleSelectBox = () => {  
        selectBoxRef.current.classList.toggle('active');
      };
    
      const filteredCountries = countries.filter(country =>
        country.name.toLowerCase().includes(searchQuery)
      );
    
      useEffect(() => {
        const handleClickOutside = (event) => {
          if (selectBoxRef.current && !selectBoxRef.current.contains(event.target)) {
            selectBoxRef.current.classList.remove('active');
          }
        };
    
        document.addEventListener('click', handleClickOutside);
    
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);

    useEffect(() => {
        $(document).ready(function () {
            $(document).on('click', '#newStudentCreate', function () {
                $('.overlay').addClass('visible');
                $('.popup-modal-container').addClass('visible')
            });
            $(document).on('click', '.sp-btn-cancel', function () {
                $('.overlay').removeClass('visible');
                $('.popup-modal-container').removeClass('visible')
            });
            $(document).on('click', '.sp-table-body>div .checkbox', function () {
                $(this).find('i').toggleClass('checked');
                $(this).parent().toggleClass('m-checked');
                var c = 0;
                $('.sp-table-body>div .checkbox').each(function () {
                    if ($(this).parent().hasClass('m-checked')) {
                        c += 1;
                    }
                });
                if (c > 0) {
                    $('.sp-menugroup').addClass('hidden');
                    $('.sp-menu').removeClass('hidden');
                } else {
                    $('.sp-menugroup').removeClass('hidden');
                    $('.sp-menu').addClass('hidden');
                }
            });
            $(document).on('click', '.sp-btn-create', function () {
                $('.overlay').removeClass('visible');
                $('.popup-modal-container').removeClass('visible')
                setTimeout(function () {
                    $('.popup-student-details').addClass('visible');
                }, 500);
            });
            $(document).on('click', '.popup-student-details .close', function () {
                $('.popup-student-details').removeClass('visible');
            });
            $(document).on('click', '.sp-list-view', function (index) {
                if ($(this).index() === 0) {
                    //window.open('/index.html');
                } else if ($(this).index() === 1) {
                    // window.open('/staffattendance.html');
                }
            });
        });
    }, []);

    // useEffect(() => {
    //     const countries = [
    //       { name: "Afghanistan", code: "AF", phone: 93 },
    //       { name: "Aland Islands", code: "AX", phone: 358 },
    //       { name: "Albania", code: "AL", phone: 355 },
    //       { name: "Algeria", code: "DZ", phone: 213 },
    //       { name: "American Samoa", code: "AS", phone: 1684 },
    //     ];
    
    //     const selectBox = document.querySelector('.options'),
    //           searchBox = document.querySelector('.search-box'),
    //           selectedOption = document.querySelector('.sp-btn-number');
        
    //     let options = null;
        
    //     for (const country of countries) {
    //       const option = `
    //         <li class="option">
    //             <div>
    //                 <span class="iconify" data-icon="flag:${country.code.toLowerCase()}-4x3"></span>
    //                 <span class="country-name">${country.name}</span>
    //             </div>
    //             <em>+${country.phone}</em>
    //         </li> `;
          
    //       selectBox.querySelector('ol').insertAdjacentHTML('beforeend', option);
    //       options = document.querySelectorAll('.option');
    //     }
    
    //     function selectOption() {
    //       const icon = this.querySelector('.iconify').cloneNode(true),
    //             phoneCode = this.querySelector('em').cloneNode(true);
    
    //       selectedOption.innerHTML = '';
    //       selectedOption.append(icon, phoneCode);
    
    //       selectBox.classList.remove('active');
    //       selectedOption.classList.remove('active');
    
    //       searchBox.value = '';
    //       selectBox.querySelectorAll('.hide').forEach(el => el.classList.remove('hide'));
    //     }
    
    //     function searchCountry() {
    //       const searchQuery = searchBox.value.toLowerCase();
    //       for (const option of options) {
    //         const isMatched = option.querySelector('.country-name').innerText.toLowerCase().includes(searchQuery);
    //         option.classList.toggle('hide', !isMatched);
    //       }
    //     }
    
    //     selectedOption.addEventListener('click', () => {
    //       selectBox.classList.toggle('active');
    //       selectedOption.classList.toggle('active');
    //     });
    
    //     options.forEach(option => option.addEventListener('click', selectOption));
    //     searchBox.addEventListener('input', searchCountry);
    //   }, []);

    return (
        <>
            <section>
                <div className="whole-container sign_page">
                    <div className="log-container">
                        <div className="login-cont-inr flex-prop">
                            <div className="login-cont-left">
                                <div className="log-left-inr flex-prop">
                                    <div className="mrg-cen main_panda">
                                        <img src={panda_image} style={{ width: "80px" }} width="80" height="100" alt="School Panda" />
                                        <h3 className="scl-name">School Panda</h3>
                                        <span>Transforming Learning Communication</span>
                                    </div>
                                </div>
                            </div>
                            <div className="login-cont-right">
                                <div className="login-cont-inner flex-prop ">
                                    <div className="sign-inp mrg-cen">
                                        {status === false ? (
                                            <div className="sign-form">
                                                <h1>Starting your new journey It’s very easy to setup!</h1>
                                                <div className="forms-fields-text">
                                                    <label htmlFor="fullName">Full Name</label>
                                                    <input
                                                        type="text"
                                                        id="fullName"
                                                        value={fullName}
                                                        onChange={(e) => setFullName(e.target.value)}
                                                        onKeyDown={(e) => handleKeyDown(e, "email")}
                                                        placeholder="Enter your full name"
                                                        style={{ borderColor: fullNameError ? 'red' : '' ,paddingLeft:"15px" }}
                                                    />
                                                    {fullNameError && <div className="error">{fullNameError}</div>}
                                                </div>
                                                <div className="forms-fields-text">
                                                    <label htmlFor="email">Email</label>
                                                    <input
                                                        type="email"
                                                        id="email"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        onKeyDown={(e) => handleKeyDown(e, "mobileNumber")}
                                                        placeholder="Enter your email"
                                                        style={{ borderColor: emailError ? 'red' : '' ,paddingLeft:"15px"}}
                                                    />
                                                    {emailError && <div className="error">{emailError}</div>}
                                                </div>
                                                <div className="num-cont flex-prop select-box forms-fields-text">
                                                {/* <div>
                                                    <input
                                                        type="text"
                                                        placeholder="Search for a country..."
                                                        value={searchTerm}
                                                        onChange={handleSearch}
                                                    />
                                                    <select>
                                                        {filteredCountries.map((country) => (
                                                            <option key={country.code} value={country.code}>
                                                                {country.name} (+{country.phone})
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div> */}
                                                    <div className="num-box">
                                                    <label>Code</label>
                                                    <button className="sp-btn-number" onClick={handleToggleSelectBox}>
                                                        <span className="iconify" data-icon={`flag:${selectedCountry.code.toLowerCase()}-4x3`}></span>
                                                        <span>+{selectedCountry.phone}</span>
                                                    </button>
                                                    <div className="options" ref={selectBoxRef}>
                                                        <input
                                                        type="text"
                                                        className="search-box"
                                                        placeholder="Search Country Name"
                                                        style={{ paddingLeft: "15px" }}
                                                        value={searchQuery}
                                                        onChange={handleSearchChange}
                                                        ref={searchBoxRef}
                                                        />
                                                        <ol>
                                                        {filteredCountries.map(country => (
                                                            <li
                                                            key={country.code}
                                                            className="option"
                                                            onClick={() => handleSelectOption(country)}
                                                            >
                                                            <div>
                                                                <span className="iconify" data-icon={`flag:${country.code.toLowerCase()}-4x3`}></span>
                                                                <span className="country-name">{country.name}</span>
                                                            </div>
                                                            <em>+{country.phone}</em>
                                                            </li>
                                                        ))}
                                                        </ol>
                                                    </div>
                                                    </div>
                                                    <p>
                                                        <label htmlFor="mobileNumber">Mobile Number</label>
                                                        <input
                                                            type="text"
                                                            id="mobileNumber"
                                                            value={mobileNumber}
                                                            onChange={(e) => setMobileNumber(e.target.value)}
                                                            onKeyDown={(e) => handleKeyDown(e, "password")}
                                                            placeholder="Enter Mobile Number"
                                                            style={{ borderColor: mobileNumberError ? 'red' : '' ,paddingLeft:"15px" }}
                                                        />
                                                        {mobileNumberError && <div className="error">{mobileNumberError}</div>}
                                                    </p>
                                                </div>
                                                <div className="forms-fields-text">
                                                    <label htmlFor="password">Password</label>
                                                    <input
                                                        type="password"
                                                        id="password"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        onKeyDown={(e) => handleKeyDown(e, "sp-verfybtn")}
                                                        placeholder="Type password"
                                                        style={{ borderColor: passwordError ? 'red' : '' ,paddingLeft:"15px" }}
                                                    />
                                                    {passwordError && <div className="error">{passwordError}</div>}
                                                </div>
                                                <div className="terms-cont forms-fields-text">
                                                    <label className="label-container">
                                                        <input
                                                            type="checkbox"
                                                            checked={termsChecked}
                                                            onChange={(e) => setTermsChecked(e.target.checked)}
                                                        />
                                                        <span className="tick-ele"></span>
                                                    </label>
                                                    <span>I agree to the <a>Terms & Conditions</a> and <a>Privacy Policy</a></span>
                                                </div>
                                                {termsCheckedError && <div className="error">{termsCheckedError}</div>}
                                                <button  onClick={sendOTP} className="verify-btn" id="sp-verfybtn">Verify</button>
                                            </div>
                                        ) : (
                                            <div className="login-otp-inr">
                                                <div className="flex-prop ">
                                                    <div className="login-cont-right flex-prop otp-right-inr main_panda">
                                                        <div className="main_panda">
                                                            <Snackbar
                                                                open={open}
                                                                onClose={handleClose}
                                                                autoHideDuration={3000}
                                                                message="OTP Sent Successfully"
                                                            />
                                                            <img src={password_image} style={{ width: "50px" }} width="50" alt="School Panda" />
                                                            <p>OTP Verification</p>
                                                            <span>OTP has sent to your mobile number and email address</span>
                                                            <div className="opt-boxes flex-prop otp_font">
                                                                {otpValues.map((value, index) => (
                                                                    <input
                                                                   

                                                                    // style={{ fontSize: "26px!important" }}
                                                                        key={index}
                                                                        type="number"
                                                                        id={`otpInput${index}`}
                                                                        className="sp-otp-inout "
                                                                        value={value}
                                                                        onChange={(e) => handleChange(index, e.target.value)}
                                                                        maxLength={1}
                                                                        onKeyDown={(e) => handleKeyDown(e, `otpInput${index + 1}`)}
                                                                      
                                                                    />
                                                                ))}
                                                            </div>
                                                            {otpError && <div className="error">{otpError}</div>}
                                                            <button onClick={verifyOTP} className="verify-btn" disabled={otpValues.some(value => !value)}>START A NEW JOURNEY</button>
                                                            <div>
                                                                <span>Didn’t Receive OTP? <span className="resent_otp" onClick={sendOTP}>Resend OTP</span></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div id="recaptcha-container"></div>
        </>
    );
}

// import React, { useState, useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { useStateContext } from "../../context/ContextProvider.jsx";
// import "../../common/components/css/style1.css";
// import panda_image from "./images/panda.png";
// import password_image from "./images/password-icon.png";
// import axios from "axios";
// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
// import $ from "jquery";
// import Snackbar from '@mui/material/Snackbar';
// import validator from 'validator';
// import { baseUrl } from "../../common/common";

// export default function Signup() {
//     const [status, setStatus] = useState(false);
//     const [fullName, setFullName] = useState("");
//     const [email, setEmail] = useState("");
//     const [mobileNumber, setMobileNumber] = useState("");
//     const [password, setPassword] = useState("");
//     const [termsChecked, setTermsChecked] = useState(true);

//     const [fullNameError, setFullNameError] = useState(null);
//     const [emailError, setEmailError] = useState(null);
//     const [mobileNumberError, setMobileNumberError] = useState(null);
//     const [passwordError, setPasswordError] = useState(null);
//     const [termsCheckedError, setTermsCheckedError] = useState(null);

//     const navigate = useNavigate();

//     const [verificationId, setVerificationId] = useState("");
//     const [otp, setOTP] = useState("");
//     const [error, setOtpError] = useState(null);

//     let firebaseConfig = {
//         apiKey: "AIzaSyDxStEcHP8L-wZMdcEwnklVSl4lHovSXuw",
//         authDomain: "scool-panda.firebaseapp.com",
//         projectId: "scool-panda",
//         storageBucket: "scool-panda.appspot.com",
//         messagingSenderId: "144297568045",
//         appId: "1:144297568045:web:3e141a30b477e8409bd5a4",
//         measurementId: "G-EDN8HRGH52"
//     };

//     firebase.initializeApp(firebaseConfig);

//     const sendOTP = async () => {
//         let valid = true;
//         if (fullName === "") {
//             setFullNameError("Please Enter Name");
//             valid = false;
//         } else {
//             setFullNameError(null);
//         }

//         if (!validator.isEmail(email)) {
//             setEmailError("Please Enter Valid Email");
//             valid = false;
//         } else {
//             setEmailError(null);
//         }

//         if (!validator.isMobilePhone(mobileNumber.trim()) || mobileNumber.trim().length !== 10) {
//             setMobileNumberError("Please Enter Valid Mobile Number");
//             valid = false;
//         } else {
//             setMobileNumberError(null);
//         }

//         if (password === "") {
//             setPasswordError("Please Enter Password");
//             valid = false;
//         } else {
//             setPasswordError(null);
//         }

//         if (termsChecked === false) {
//             setTermsCheckedError("Please agree to the Terms & Conditions and Privacy Policy");
//             valid = false;
//         } else {
//             setTermsCheckedError(null);
//         }

//         if (valid) {
//             await axios({
//                 method: "post",
//                 url: baseUrl + "/api/school_register_validation",
//                 data: {
//                     phone: mobileNumber.trim(),
//                     email: email,
//                     password: password.trim()
//                 },
//                 headers: { "Content-Type": "multipart/form-data" },
//             }).then((response) => {
//                 if (response['data']['status'] == true) {
//                     setMobileNumberError(null);
//                     setStatus(true);
//                     setOpen(true);
//                     const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
//                         "recaptcha-container", {
//                         size: "invisible"
//                     });
//                     firebase
//                         .auth()
//                         .signInWithPhoneNumber("+91" + mobileNumber, recaptchaVerifier)
//                         .then((confirmationResult) => {
//                             setVerificationId(confirmationResult.verificationId);
//                             setOtpError(null);
//                         })
//                         .catch((error) => {
//                             setOtpError(error.message);
//                         });
//                 } else {
//                     setMobileNumberError(response['data']['message']);
//                     // setError(response['data']['message']);
//                 }
//             });
//         }
//     };

//     const verifyOTP = () => {
//         const credential = firebase.auth.PhoneAuthProvider.credential(
//             verificationId,
//             otpValues.join('')
//         );

//         firebase
//             .auth()
//             .signInWithCredential(credential)
//             .then((result) => {
//                 navigate('/school_info',
//                     {
//                         state: {
//                             name: fullName,
//                             email: email,
//                             phone: mobileNumber,
//                             password: password,
//                             agree: termsChecked === true ? 1 : 0,
//                             otp: otpValues.join(''),
//                         }
//                     }
//                 );
//             })
//             .catch((error) => {
//                 setOtpError(error.message);
//             });
//     };

//     const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
//     const handleChange = (index, value) => {
//         const newOtpValues = [...otpValues];
//         newOtpValues[index] = value;
//         setOtpValues(newOtpValues);
//         if (value && index < 5) {
//             document.getElementById(`otpInput${index + 1}`).focus();
//         }
//     };

//     const [open, setOpen] = useState(false);

//     const handleClose = (event, reason) => {
//         if (reason === 'clickaway') {
//             return;
//         }
//         setOpen(false);
//     };

//     useEffect(() => {
//         $(document).ready(function () {
//             $(document).on('click', '#newStudentCreate', function () {
//                 $('.overlay').addClass('visible');
//                 $('.popup-modal-container').addClass('visible')
//             });
//             $(document).on('click', '.sp-btn-cancel', function () {
//                 $('.overlay').removeClass('visible');
//                 $('.popup-modal-container').removeClass('visible')
//             });
//             $(document).on('click', '.sp-table-body>div .checkbox', function () {
//                 $(this).find('i').toggleClass('checked');
//                 $(this).parent().toggleClass('m-checked');
//                 var c = 0;
//                 $('.sp-table-body>div .checkbox').each(function () {
//                     if ($(this).parent().hasClass('m-checked')) {
//                         c += 1;
//                     }
//                 });
//                 if (c > 0) {
//                     $('.sp-menugroup').addClass('hidden');
//                     $('.sp-menu').removeClass('hidden');
//                 } else {
//                     $('.sp-menugroup').removeClass('hidden');
//                     $('.sp-menu').addClass('hidden');
//                 }
//             });
//             $(document).on('click', '.sp-btn-create', function () {
//                 $('.overlay').removeClass('visible');
//                 $('.popup-modal-container').removeClass('visible')
//                 setTimeout(function () {
//                     $('.popup-student-details').addClass('visible');
//                 }, 500);
//             });
//             $(document).on('click', '.popup-student-details .close', function () {
//                 $('.popup-student-details').removeClass('visible');
//             });
//             $(document).on('click', '.sp-list-view', function (index) {
//                 if ($(this).index() === 0) {
//                     //window.open('/index.html');
//                 } else if ($(this).index() === 1) {
//                     // window.open('/staffattendance.html');
//                 }
//             });
//         });
//     }, []);

//     return (
//         <>
//             <section>
//                 <div className="whole-container sign_page">
//                     <div className="log-container">
//                         <div className="login-cont-inr flex-prop">
//                             <div className="login-cont-left">
//                                 <div className="log-left-inr flex-prop">
//                                     <div className="mrg-cen main_panda">
//                                         <img src={panda_image} style={{ width: "80px" }} width="80" height="100" alt="School Panda" />
//                                         <h3 className="scl-name">School Panda</h3>
//                                         <span>Transforming Learning Communication</span>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="login-cont-right">
//                                 <div className="login-cont-inner flex-prop ">
//                                     <div className="sign-inp mrg-cen">
//                                         {status === false ? (
//                                             <div className="sign-form">
//                                                 <h1>Starting your new journey It’s very easy to setup!</h1>
//                                                 <div className="forms-fields-text">
//                                                     <label htmlFor="fullName">Full Name</label>
//                                                     <input                                                        
//                                                         type="text"
//                                                         id="fullName"
//                                                         value={fullName}
//                                                         onChange={(e) => setFullName(e.target.value)}
//                                                         placeholder="Enter your full name"
//                                                         style={{ borderColor: fullNameError ? 'red' : '' }}
//                                                     />
//                                                     {fullNameError && <div className="error">{fullNameError}</div>}
//                                                 </div>
//                                                 <div className="forms-fields-text">
//                                                     <label htmlFor="email">Email</label>
//                                                     <input
//                                                         type="email"
//                                                         id="email"
//                                                         value={email}
//                                                         onChange={(e) => setEmail(e.target.value)}
//                                                         placeholder="Enter your email"
//                                                         style={{ borderColor: emailError ? 'red' : '' }}
//                                                     />
//                                                     {emailError && <div className="error">{emailError}</div>}
//                                                 </div>
//                                                 <div className="num-cont flex-prop select-box forms-fields-text">
//                                                     <div className="num-box">
//                                                         <label>Code</label>
//                                                         <button className="sp-btn-number">
//                                                             <span className="iconify" data-icon="flag:gb-4x3"></span>
//                                                             <span>+91</span>
//                                                         </button>
//                                                         <div className="options">
//                                                             <input type="text" className="search-box" placeholder="Search Country Name" />
//                                                             <ol></ol>
//                                                         </div>
//                                                     </div>
//                                                     <p>
//                                                         <label htmlFor="mobileNumber">Mobile Number</label>
//                                                         <input
//                                                             type="text"
//                                                             id="mobileNumber"
//                                                             value={mobileNumber}
//                                                             onChange={(e) => setMobileNumber(e.target.value)}
//                                                             placeholder="Enter Mobile Number"
//                                                             style={{ borderColor: mobileNumberError ? 'red' : '' }}
//                                                         />
//                                                         {mobileNumberError && <div className="error">{mobileNumberError}</div>}
//                                                     </p>
//                                                 </div>
//                                                 <div className="forms-fields-text">
//                                                     <label htmlFor="password">Password</label>
//                                                     <input
//                                                         type="password"
//                                                         id="password"
//                                                         value={password}
//                                                         onChange={(e) => setPassword(e.target.value)}
//                                                         placeholder="Type password"
//                                                         style={{ borderColor: passwordError ? 'red' : '' }}
//                                                     />
//                                                     {passwordError && <div className="error">{passwordError}</div>}
//                                                 </div>
//                                                 <div className="terms-cont forms-fields-text">
//                                                     <label className="label-container">
//                                                         <input
//                                                             type="checkbox"
//                                                             checked={termsChecked}
//                                                             onChange={(e) => setTermsChecked(e.target.checked)}
//                                                         />
//                                                         <span className="tick-ele"></span>
//                                                     </label>
//                                                     <span>I agree to the <a>Terms & Conditions</a> and <a>Privacy Policy</a></span>
                                                    
//                                                 </div>
//                                                 {termsCheckedError && <div className="error">{termsCheckedError}</div>}
//                                                 <button onClick={sendOTP} className="verify-btn" id="sp-verfybtn">Verify</button>
//                                             </div>
//                                         ) : (
//                                             <div className="login-otp-inr">
//                                                 <div className="flex-prop ">
//                                                     <div className="login-cont-right flex-prop otp-right-inr main_panda">
//                                                         <div className="main_panda">
//                                                             <Snackbar
//                                                                 open={open}
//                                                                 onClose={handleClose}
//                                                                 autoHideDuration={3000}
//                                                                 message="OTP Sent Successfully"
//                                                             />
//                                                             <img src={password_image} style={{ width: "50px" }} width="50" alt="School Panda" />
//                                                             <p>OTP Verification</p>
//                                                             <span>OTP has sent to your mobile number and email address</span>
//                                                             <div className="opt-boxes flex-prop">
//                                                                 {otpValues.map((value, index) => (
//                                                                     <input
//                                                                         key={index}
//                                                                         type="number"
//                                                                         id={`otpInput${index}`}
//                                                                         className="sp-otp-inout"
//                                                                         value={value}
//                                                                         onChange={(e) => handleChange(index, e.target.value)}
//                                                                         maxLength={1}
//                                                                     />
//                                                                 ))}
//                                                             </div>
//                                                             <button onClick={verifyOTP} className="verify-btn" disabled={otpValues.some(value => !value)}>START A NEW JOURNEY</button>
//                                                             <div>
//                                                                 <span>Didn’t Receive OTP? <span className="resent_otp" onClick={sendOTP}>Resend OTP</span></span>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         )}
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//             <div id="recaptcha-container"></div>
//         </>
//     );
// }





