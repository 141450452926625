import React, { useState, useEffect } from "react";
// import {Alert} from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css'; 
import panda_image from "../../pages/signup/images/panda.png";
import school_logo from "../../pages/signup/images/schoollogo.png";
import {useLocation,useNavigate} from "react-router-dom";
import "../../common/components/css/style1.css";
// import "../signup/style.css";
import '../../common/components/js/school_info.js';
import axios from "axios";
import $ from "jquery";
import { baseUrl } from "../../common/common";

export default function School_info() {

    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    const [schoolName, setschoolName] = useState("");
    const [schoolSize, setschoolSize] = useState("");
    // const [schoolProgram, setschoolProgram] = useState({languages: [],response: [],
    // });
    // const handleschoolProgram = (e) => {
    //     // Destructuring
    //     const { value, checked } = e.target;
    //     const { languages } = schoolProgram;
 
    //     console.log(`${value} is ${checked}`);
 
    //     // Case 1 : The user checks the box
    //     if (checked) {
    //         setschoolProgram({
    //             languages: [...languages, value],
    //             response: [...languages, value],
    //         });
    //     }
 
    //     // Case 2  : The user unchecks the box
    //     else {
    //         setschoolProgram({
    //             languages: languages.filter(
    //                 (e) => e !== value
    //             ),
    //             response: languages.filter(
    //                 (e) => e !== value
    //             ),
    //         });
    //     }
    // };

    const [schoolProgram, setschoolProgram]= useState([]); 
    //const [selectedPermissions, setSelectedPermissions] = useState([]); // User selected permissions
    const handlePermissionChange = (permissionId) => {
 
        $('.next-btn').hide();
        $('.last_button').show(); 
      
        const newSelection = schoolProgram.includes(permissionId)
            ? schoolProgram.filter(id => id !== permissionId)
            : [...schoolProgram, permissionId];
            setschoolProgram(newSelection);
    };


    const userData = {
        "full_name":location.state.name,
        "email":location.state.email,
        "phone":location.state.phone,
        "password":location.state.password,
        "agree":location.state.agree,
        "size_id":schoolSize,
        "otp_verify":1,
        "otp":location.state.otp,
        "status":1,
        "created_by":1,
        "updated_by":1,
        "school_name":schoolName,
        "programs":schoolProgram
    };
    
    const saveUserData = () => {
        if(schoolProgram.length===0){
          setError("Plese Select Programs");
        }else{
            setError(null);
            axios({
                method: "post",
                url:baseUrl+"/api/signup",
                data:userData,
                headers: { "Content-Type": "multipart/form-data" },
                }).then((response) => {   
                if(response['data']['status']===true){
                    navigate('/student_list');
                    window.location.reload();    
                    localStorage.setItem('schoolId', response['data']['data']);       
                    console.log(response);           
                }
             });
        }
    }
    
    const [schoolSizeData, setSchoolSizeData] = useState({});
    const [schoolSizeLoading, setSchoolSizeLoading] = useState(false);
   
    const fetchSchoolSizeData = async() => {
        try{
            const {data} = await axios.post(baseUrl+"/api/get_size_master");     
            setSchoolSizeData(data.data);
            setSchoolSizeLoading(true);
        } catch(error) {
            console.log(error)
        }
    }

    const [schoolProgramsData, setSchoolProgramsData] = useState({});
    const [schoolProgramsLoading, setSchoolProgramsLoading] = useState(false);
    
    const fetchSchoolProgramsData = async() => {
        try{
            const {data} = await axios.post(baseUrl+"/api/get_programs_master");     
            setSchoolProgramsData(data.data); 
            setSchoolProgramsLoading(true)           
        } catch(error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchSchoolSizeData();  
        $(document).ready(function() {         
         
            $(".search-sch-list").keypress(function(event) {
                $('.next-btn').attr('disabled', false);  
                var value = $(this).val().toLowerCase();
                $('#schoolList').addClass('active');
                $("#schoolList div").filter(function() {
                    $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
                });
                $('.close-search').removeClass('active');
                $(".sch-text-op").empty();
            });
            // $("#schoolList div.search-li ").click(function() {
            //     $(".sch-text-op ").empty();
            //     $(".search-sch-list ").val('');
            //     $(".sch-text-op ").append($(this).find('span:first-child').html() + '<span>' + $(this).find('.sch-name').data('schl-name') + '</span> - ' + '<span>' + $(this).find('em:last-child').data('schl-loc') + '</span>');
            //     $(".search-sch-list ").val($(this).find('.sch-name').data('schl-name'));
            //     $('#schoolList').removeClass('active');
            //     $('.close-search').addClass('active');
            //     $('.next-btn').attr('disabled', false);
            // });
            // $(document).on('click', '.close-search', function() {
            //     $('.close-search').removeClass('active');
            //     $(".sch-text-op ").empty();
            //     $(".search-sch-list ").val('');
            //     $('.next-btn').attr('disabled', true);
            // });
            // $('.last_div').click(function() {           
            //     $('.next-btn').hide();
            //     $('.last_button').show();                
            // });
            // $(document).on('click', '.next-btn', function() {
            //     $('.box-container>div.sp-sec-active').addClass('completed');
            //     $('.box-container>div.sp-sec-active').removeClass('sp-sec-active').next().addClass('sp-sec-active');
            //     if ($('.box-container>div.sp-sec-active').hasClass('completed')) {
            //         $('.next-btn').attr('disabled', false);
            //     } else {
            //         $('.next-btn').attr('disabled', true);
            //     }
            //     var count = 0;
            //     $('.box-container>div').each(function(index) {
            //         if (!$(this).hasClass('completed')) {
            //             count += 1;
            //         }
            //     });
            //     $('.progress-bar').find('.progress-bar-fill').css('width', parseFloat(100 / (count)) + "%");
            // });
        
            // $(document).on('click', '.scl-size-cont button', function() {
            //     $('.scl-size-cont button').removeClass('sp-selected');
            //     $('.label-container input').removeAttr('checked');
            //     $(this).addClass('sp-selected');
            //     $(this).find('.label-container input').attr('checked', 'checked');
            //     $('.box-container>div.sp-sec-active').addClass('completed');
            //     $('.next-btn').attr('disabled', false);
            // });
            // $(document).on('click', '.scl-prg button', function() {
            //     if ($(this).hasClass('sp-selected')) {
            //         $(this).removeClass('sp-selected');
            //         $(this).find('.label-container input').removeAttr('checked');
            //     } else {
            //         $(this).addClass('sp-selected');
            //         $(this).find('.label-container input').attr('checked', 'checked');
            //     }
            //     $('.box-container>div.sp-sec-active').addClass('completed');
            //     $('.next-btn').attr('disabled', false);
            // });
            
        
        });
        fetchSchoolProgramsData();
    });

    return (
        <section>
            <div className="log-address-inr flex-prop">
                <div className="log-address-cont">
                    <div className="log-address-top flex-prop">
                        <div className="address-top1 flex-prop">
                            <img src={panda_image} style={{ width: "30px"}} width="30" height="35" alt="panda" />
                            <h4 className="scl-name">School Panda</h4>
                        </div>
                        <p>Welcome, {location.state.name}!</p>                       
                    </div>
                    <div className="box-container">
                        <div className="sp-search-school sp-sec-active">
                            <h3>What’s your School Name?</h3>
                            <div className="pos">
                                <input
                                    type="text"
                                    placeholder="Search your school"
                                    className="search-sch-list"
                                    onChange={(e) => setschoolName(e.target.value)}
                                />
                                {/* <div className="sch-text-op" />
                                <span className="close-search">X</span>
                                <div className="search-list" id="schoolList">
                                    <div className="search-li">
                                        <span>
                                            <img src={school_logo} />
                                        </span>
                                        <span className="title-text">
                                            <em
                                                className="dis-block ft-600 sch-name"
                                                data-schl-name="Vidya Mandir Estancia"
                                            >
                                                Vidya Mandir Estancia
                                            </em>
                                            <em data-schl-loc="Kattankulathur, Tamil Nadu 603202">
                                                Estancia, Potheri, SRM Nagar, Chennai, Kattankulathur, Tamil Nadu
                                                603202
                                            </em>
                                        </span>
                                    </div>
                                    <div className="search-li">
                                        <span>
                                            <img src={school_logo} />
                                        </span>
                                        <span className="title-text">
                                            <em
                                                className="dis-block ft-600 sch-name"
                                                data-schl-name="SRM School"
                                            >
                                                SRM School
                                            </em>
                                            <em data-schl-loce="Kattankulathur, Tamil Nadu 603202">
                                                Estancia, Potheri, SRM Nagar, Chennai, Kattankulathur, Tamil Nadu
                                                603202
                                            </em>
                                        </span>
                                    </div>
                                    <div className="search-li">
                                        <span>
                                            <img src={school_logo} />
                                        </span>
                                        <span className="title-text">
                                            <em
                                                className="dis-block ft-600 sch-name"
                                                data-schl-name="Vidyashri"
                                            >
                                                Vidyashri
                                            </em>
                                            <em data-schl-loc="Kattankulathur, Tamil Nadu 603202">
                                                Estancia, Potheri, SRM Nagar, Chennai, Kattankulathur, Tamil Nadu
                                                603202
                                            </em>
                                        </span>
                                    </div>
                                    <div className="search-li">
                                        <span>
                                            <img src={school_logo} />
                                        </span>
                                        <span className="title-text">
                                            <em
                                                className="dis-block ft-600 sch-name"
                                                data-schl-name="Vidya Mandir Estancia"
                                            >
                                                Vidya Mandir Estancia
                                            </em>
                                            <em data-schl-loc="Kattankulathur, Tamil Nadu 603202">
                                                Estancia, Potheri, SRM Nagar, Chennai, Kattankulathur, Tamil Nadu
                                                603202
                                            </em>
                                        </span>
                                    </div>
                                    <div className="search-li">
                                        <span>
                                            <img src={school_logo} />
                                        </span>
                                        <span className="title-text">
                                            <em
                                                className="dis-block ft-600 sch-name"
                                                data-schl-name="Vidya Mandir Estancia"
                                            >
                                                Vidya Mandir Estancia
                                            </em>
                                            <em data-schl-loc="Kattankulathur, Tamil Nadu 603202">
                                                {" "}
                                                Estancia, Potheri, SRM Nagar, Chennai, Kattankulathur, Tamil Nadu
                                                603202
                                            </em>
                                        </span>
                                    </div>
                                    <div className="search-li">
                                        <span>
                                            <img src={school_logo} />
                                        </span>
                                        <span className="title-text">
                                            <em
                                                className="dis-block ft-600 sch-name"
                                                data-schl-name="Vidya Mandir Estancia"
                                            >
                                                Vidya Mandir Estancia
                                            </em>
                                            <em data-schl-loc="Kattankulathur, Tamil Nadu 603202">
                                                Estancia, Potheri, SRM Nagar, Chennai, Kattankulathur, Tamil Nadu
                                                603202
                                            </em>
                                        </span>
                                    </div>
                                    <div className="search-li">
                                        <span>
                                            <img src={school_logo} />
                                        </span>
                                        <span className="title-text">
                                            <em
                                                className="dis-block ft-600 sch-name"
                                                data-schl-name="Vidya Mandir Estancia"
                                            >
                                                Vidya Mandir Estancia
                                            </em>
                                            <em data-schl-loc="Kattankulathur, Tamil Nadu 603202">
                                                Estancia, Potheri, SRM Nagar, Chennai, Kattankulathur, Tamil Nadu
                                                603202
                                            </em>
                                        </span>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="scl-size-cont">
                            <h3>What’s your School Size?</h3>
                            {(schoolSizeLoading && schoolSizeData && schoolSizeData.length > 0) ? (
    <div className="size-cont1 flex-prop">
        {schoolSizeData.slice(0, 5).map((data) => (
            <button key={data.id} onClick={() => setschoolSize(data.id)}>
                <label className="label-container">
                    <input type="radio" name="radio" onChange={(e) => setschoolSize(data.id)} />
                    <span className="circle-ele" />
                </label>
                {data.school_size}
            </button>
        ))}
    </div>
) : (
    <div>Loading....</div>
)}

{(schoolSizeLoading && schoolSizeData && schoolSizeData.length > 0) ? (
    <div className="size-cont1 flex-prop">
        {schoolSizeData.slice(5, 9).map((data) => (
            <button key={data.id} onClick={() => setschoolSize(data.id)}>
                <label className="label-container">
                    <input type="radio" name="radio" onChange={(e) => setschoolSize(data.id)} />
                    <span className="circle-ele" />
                </label>
                {data.school_size}
            </button>
        ))}
    </div>
) : (
    <div>Loading....</div>
)}



                        </div>
                        <div className="scl-prg">
                            <h3>What are the programs you have in your school?</h3>
                            {/* {error &&
                            <Alert variant="danger">  
                                <div className="">{error}</div>
                            </Alert>
                            } */}
                            <div className="scl-prg-name">
                            <div className="size-cont1 program-size flex-prop ">
                                {(schoolProgramsLoading &&schoolProgramsData && schoolProgramsData.length > 0) ?
                                schoolProgramsData.map((data) =>(
                                <button  key={data.id} onClick={() => handlePermissionChange(data.id)} className="last_div">
                                    <label className="label-container">
                                    <input type="checkbox"checked={schoolProgram.includes(data.id)} onChange={() => handlePermissionChange(data.id)} />
                                        <span className="tick-ele" />
                                    </label>
                                    {data.program_name}
                                </button>                                                       
                                )):<div>Loading....</div>}
                            </div>                                                                     
                            </div>
                        </div>
                    </div>
                    <div className="login-bottom">
                        <div className="progress-bar">
                            <div className="progress-bar-fill"></div>
                        </div>
                        <div className="sp-progress-btn flex-prop">
                            <button className="back-btn">Back</button>
                            {/* <Link to="/student_list"><button className="last_button">Next</button></Link> */}
                            <button onClick={() =>saveUserData()}  className="last_button">Next</button>
                            <button className="next-btn" disabled>Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
