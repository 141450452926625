import React, { useState, useRef, useMemo, useEffect } from "react";
import { Link, Navigate, Outlet, useNavigate } from "react-router-dom";
// import 'bootstrap/dist/css/bootstrap.min.css';
import Avatar1 from "../school/images/avatar1.png";
import Avatar2 from "../school/images/avatar2.png";
import Avatar3 from "../school/images/avatar3.png";
import Avatar4 from "../school/images/avatar4.png";
// import "../../common/components/css/main.css";
// import "../signup/style.css";
import Avatarprofile from "../school/images/profile1.png";
import Avatardata from "../school/images/data-show.png";
import Avatarprofile1 from "../school/images/profile1.png";
import Attendance from "../school/images/data-show.png";
import axios from "axios";
import validator from "validator";
// import { Alert } from 'react-bootstrap';
import $ from "jquery";
import { baseUrl } from "../../common/common";
import Loader from "react-js-loader";
import { Dropdown } from "flowbite-react";
import "../../index.css";
import { HiCheck } from "react-icons/hi";
import ImportComponent from "../Import/Import";

export default function Staff_list() {
  const navigate = useNavigate();

  const [roles, setRoles] = useState([]);
  // const [error, setError] = useState(null);
  const [permissions, setPermissions] = useState([]); // Available permissions
  const [selectedPermissions, setSelectedPermissions] = useState([]); // User selected permissions
  const [programs, setPrograms] = useState([]); // Available permissions
  const [selectedPrograms, setSelectedPrograms] = useState([]); // User selected permissions

  const [user, setUser] = useState({
    // job_title: '',
    first_name: "",
    last_name: "",
    // gender: '',
    email: "",
    phone: "",
    dob: "",
    joining_date: "",
    // blood_group: '',
    address: "",
    // assign_classes: '',
    role_id: "",
    // permissions_id:'',
    //permissions_id:[],
    // status: '',
    // created_by: '',
    // updated_by: '',
    // created_on: '',
    // updated_on: '',
  });

  const [displayUser, displaySetUser] = useState({
    id: "",
    first_name: "",
    email: "",
    phone: "",
    dob: "",
    joining_date: "",
    address: "",
    assign_classes: "",
    role_id: "",
    permissions_id: "",
  });

  const onSubmit = (ev) => {
    // ev.preventDefault();

    if (validateForm()) {
      const postData = {
        school_id: localStorage.getItem("schoolId"),
        job_title: "",
        first_name: user.first_name,
        last_name: "",
        gender: "",
        email: user.email,
        phone: user.phone,
        dob: user.dob,
        joining_date: user.joining_date,
        blood_group: "",
        address: user.address,
        assign_classes: selectedPrograms.join(","),
        role_id: user.role_id,
        permissions_id: selectedPermissions.join(","),
        status: 1,
        created_by: 1,
        updated_by: "",
        created_on: "",
        updated_on: "",
        profile_image: "",
      };

      setError(null);

      axios({
        method: "post",
        url: baseUrl + "/api/staff_add",
        data: postData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => {
        if (response["data"]["status"] === true) {
          displaySetUser({
            first_name: response["data"]["data"]["first_name"],
            programs_id: response["data"]["data"]["programs_id"],
            email: response["data"]["data"]["email"],
            phone: response["data"]["data"]["phone"],
            dob: response["data"]["data"]["dob"],
            joining_date: response["data"]["data"]["joining_date"],
            address: response["data"]["data"]["address"],
            assign_classes: response["data"]["data"]["assign_classes"],
            role_id: response["data"]["data"]["role_id"],
            permissions_id: response["data"]["data"]["permissions_id"],
          });

          $(".overlay").removeClass("visible");
          $(".popup-modal-container").removeClass("visible");
          setTimeout(function () {
            $(".popup-student-details").addClass("visible");
          }, 500);

          setUser({
            first_name: "",
            email: "",
            phone: "",
            dob: "",
            joining_date: "",
            address: "",
            role_id: "",
          });

          fetchStaffData();
          setSelectedPermissions([]);
          setSelectedPrograms([]);

          console.log(response);
        }
      });
    }
  };

  const handleImportStatus = (status) => {
    if (status == true) {
      fetchStaffData();
      setimportstatus(false);
    }
  };

  const updateUser = () => {
    const postData = {
      id: studentId,
      school_id: localStorage.getItem("schoolId"),
      job_title: "",
      first_name: user.first_name,
      last_name: "",
      gender: "",
      email: user.email,
      phone: user.phone,
      dob: user.dob,
      joining_date: user.joining_date,
      blood_group: "",
      address: user.address,
      assign_classes: selectedPrograms.join(","),
      role_id: user.role_id,
      permissions_id: selectedPermissions.join(","),
      status: 1,
      created_by: localStorage.getItem("schoolId"),
      updated_by: localStorage.getItem("schoolId"),
      created_on: "",
      updated_on: "",
      profile_image: "",
    };
    setError(null);
    console.log(postData);
    axios({
      method: "post",
      url: baseUrl + "/api/staff_add",
      data: postData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((response) => {
      if (response["data"]["status"] === true) {
        displaySetUser({
          first_name: response["data"]["data"]["first_name"],
          programs_id: response["data"]["data"]["programs_id"],
          email: response["data"]["data"]["email"],
          phone: response["data"]["data"]["phone"],
          dob: response["data"]["data"]["dob"],
          joining_date: response["data"]["data"]["joining_date"],
          address: response["data"]["data"]["address"],
          assign_classes: response["data"]["data"]["assign_classes"],
          role_id: response["data"]["data"]["role_id"],
          permissions_id: response["data"]["data"]["permissions_id"],
        });
        $(".overlay").removeClass("visible");
        $(".popup-modal-container").removeClass("visible");
        setTimeout(function () {
          $(".popup-student-details").addClass("visible");
        }, 500);
        setUser({
          first_name: "",
          email: "",
          phone: "",
          dob: "",
          joining_date: "",
          address: "",
          role_id: "",
        });
        setSelectedPermissions([]);
        setSelectedPrograms([]);
        fetchStaffData();
        // localStorage.setItem('userId', response['data']['user_id']);
        console.log(response);
      }
    });
  };

  const openStudentPopup = (studentData) => {
    displaySetUser({
      id: studentData.id,
      first_name: studentData.first_name,
      programs_id: studentData.programs_id,
      email: studentData.email,
      phone: studentData.phone,
      dob: studentData.dob,
      joining_date: studentData.joining_date,
      address: studentData.address,
      assign_classes: studentData.assign_classes,
      role_id: studentData.role_id,
      permissions_id: studentData.permissions_id,
    });
    $(".overlay").removeClass("visible");
    $(".popup-modal-container").removeClass("visible");
    setTimeout(function () {
      $(".popup-student-details").addClass("visible");
    }, 500);
  };
  const [staffData, setStaffData] = useState({});
  const [loading, setLoading] = useState(false);
  const [importstatus, setimportstatus] = useState(false);
  const [programId, setProgramId] = useState(false);

  const [filterValue, setFilterValue] = useState('');
  const handleInputChange = async (event) => {
    setFilterValue(event.target.value);
    try {
      const postData = {
        school_id: localStorage.getItem("schoolId"),
        search: event.target.value.trim(),
        programs_id: "",
        status: 1,
      };
      const { data } = await axios.post(baseUrl + "/api/get_staff", postData);
      setStaffData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchStaffData = async () => {
    setLoading(false);
    try {
      const postData = {
        school_id: localStorage.getItem("schoolId"),
        search: "",
        programs_id: "",
        status: 1,
      };
      const { data } = await axios.post(baseUrl + "/api/get_staff", postData);
      setStaffData(data.data);
      setLoading(true);
      console.log(staffData, "d");
    } catch (error) {
      console.log(error);
    }
  };
  const [studentId, setStudentId] = useState(null);
  const openStudentUpdatePopup = (studentData) => {
    $(".popup-student-details").removeClass("visible");
    setError(null);
    setValidationErrors({});
    setStudentId(studentData.id);
    setUser({
      first_name: studentData.first_name,
      email: studentData.email,
      phone: studentData.phone,
      last_name: studentData.first_name,
      // programs_id:studentData.programs_id,
      role_id: studentData.role_id,
      dob: studentData.dob,
      joining_date: studentData.joining_date,
      address: studentData.address,
    });
    // {(studentData.assign_classes.split(",")).map((programs) => (
    //     alert(programs)
    //     // handleProgramsChange(programs)
    // ))}
    setSelectedPermissions(studentData.permissions_id.split(","));
    setSelectedPrograms(studentData.assign_classes.split(","));
    $(".overlay").addClass("visible");
    $(".popup-modal-container").addClass("visible");
  };
  const [programsData, setProgramsData] = useState({});
  const [programsLoading, setProgramsLoading] = useState(false);
  const fetchProgramsData = async () => {
    setProgramsLoading(false);
    try {
      const postData = {
        school_id: localStorage.getItem("schoolId"),
        // school_id:24
      };
      const { data } = await axios.post(
        baseUrl + "/api/get_programs",
        postData
      );
      setProgramsData(data.data);
      setProgramsLoading(true);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchRoles = async () => {
    try {
      const response = await axios.post(baseUrl + "/api/get_role");
      setRoles(response.data.data);
    } catch (error) {
      console.error("Error fetching roles:", error);
      setError("Failed to fetch roles");
    }
  };
  const handlePermissionChange = (permissionId) => {
    const newSelection = selectedPermissions.includes(permissionId)
      ? selectedPermissions.filter((id) => id !== permissionId)
      : [...selectedPermissions, permissionId];
    setSelectedPermissions(newSelection);
  };
  // const handleProgramsChange = (permissionId) => {
  //     const newSelection = selectedPrograms.includes(permissionId)
  //         ? selectedPrograms.filter(id => id !== permissionId)
  //         : [...selectedPrograms, permissionId];
  //     setSelectedPrograms(newSelection);
  // };
  const handleProgramsChange = (programId) => {
    setSelectedPrograms((prevSelected) =>
      prevSelected.includes(programId)
        ? prevSelected.filter((id) => id !== programId)
        : [...prevSelected, programId]
    );
  };
  const fetchPermissions = async () => {
    try {
      const response = await axios.post(
        baseUrl + "/api/get_permission"
      );
      setPermissions(response.data.data);
    } catch (error) {
      // console.error("Error fetching permissions:", error);
      // setError("Failed to fetch permissions");
    }
  };
  const importStudents1 = () => {
    setimportstatus(true);
  };
  const fetchPrograms = async () => {
    try {
      const response = await axios.post(baseUrl + "/api/get_programs", {
        school_id: localStorage.getItem("schoolId"),
      });
      setPrograms(response.data.data);
    } catch (error) { }
  };
  //------------------- import students -----------------
  const [selectedImport, setSelectedImport] = useState("");
  const importStudents = (event) => {
    const files = event.target.files;
    //setUser({ ...user, profile_image: files[0] });
    const importFile = event.target.files[0];

    axios({
      method: "post",
      url: baseUrl + "/api/staff_import",
      data: {
        school_id: localStorage.getItem("schoolId"),
        file: importFile,
      },
      headers: { "Content-Type": "multipart/form-data" },
    }).then((response) => {
      console.log(response);
      if (response["data"]["status"] === true) {
        fetchStaffData();
        console.log(response);
      }
    });
    //setSelectedImport(file);
  };
  // ------------------------------------------------------
  const selectedRoles = roles.find((data) => data.id === user.role_id);
  // const selectedFoodTime = foodTimeData.find(data => data.id === foodDetails.foodTime);
  useEffect(() => {
    $(document).on("click", "#newStaffCreate", function () {
      setError(null);
      setStudentId(null);
      setValidationErrors({});
      setUser({
        first_name: "",
        email: "",
        phone: "",
        dob: "",
        joining_date: "",
        address: "",
        role_id: "",
      });
      setSelectedPermissions([]);
      setSelectedPrograms([]);
      $(".overlay").addClass("visible");
      $(".popup-modal-container").addClass("visible");
    });
    $(document).on("click", ".popup-student-details .close", function () {
      $(".popup-student-details").removeClass("visible");
    });
    $(document).on("click", ".sp-btn-cancel", function () {
      setError(null);
      $(".overlay").removeClass("visible");
      $(".popup-modal-container").removeClass("visible");
    });
    fetchProgramsData();
    fetchRoles();
    fetchPrograms();
    fetchPermissions();
    fetchStaffData();
  }, []);

  const firstNameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const pnoneInputRef = useRef(null);
  const dobInputRef = useRef(null);
  const joingInputRef = useRef(null);
  const adressInputRef = useRef(null);
  const handleKeyPress = (event, nextInputRef) => {
    if (event.key === "Enter") {
      event.preventDefault();
      console.log("Next input ref:", nextInputRef);
      if (nextInputRef && nextInputRef.current) {
        nextInputRef.current.focus();
      } else {
        console.log("Next input ref is null");
      }
    }
  };
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({
    first_name: "",
    email: "",
    phone: "",
    dob: "",
    joining_date: "",
    address: "",
    role_id: "",
    programs: "",
    permissions_id: "",

    // Add other fields here
  });
  const validateForm = () => {
    let errors = {};
    if (user.first_name === "") {
      errors.first_name = "Please Enter Name";
    }
    if (!validator.isEmail(user.email)) {
      errors.email = "Please Enter Valid Email";
    }
    if (
      !validator.isMobilePhone(user.phone.trim()) ||
      user.phone.trim().length !== 10
    ) {
      errors.phone = "Please Enter Valid Number";
    }
    if (user.dob === "") {
      errors.dob = "Please Enter Date of Birth";
    }
    if (user.joining_date === "") {
      errors.joining_date = "Please Enter Joining Date";
    }
    if (user.address === "") {
      errors.address = "Please Enter Address";
    }
    if (user.role_id === "") {
      errors.role_id = "Please Enter StudentID";
    }
    if (selectedPrograms.length === 0) {
      errors.programs = "Ats least one permission must be selected.";
    }
    if (selectedPermissions.length === 0) {
      errors.permissions_id = "At least one permission must be selected.";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleTabSelect = (event) => {
    // Change background color of the selected tab
    document.querySelectorAll(".select_field_point").forEach((element) => {
      element.style.backgroundColor = "";
    });
    event.currentTarget.style.backgroundColor = "#0000000A";
  };

  return (
    <section>
      <div className="outer-container student_input">
        <div className="overlay" />
        <div id="left-panel" className="sp-left-panel">
          <div className="sp-top-band">
            <a className="sp-logo">
              Scool Panda<sup>Beta</sup>
            </a>
            <div className="sp-search-top flexc">
              <i className="icon-Search" />
              <input
                id="search"
                className="sp-search-btn"
                placeholder="Quick search..."
              />
            </div>
          </div>
          <div className="sp-middle-band">
            <div className="sp-nav y-auto">
              <div className="">
                <h4 className="sp-title">Quick Actions</h4>
                <ul>
                  <li onClick={() => { navigate('/dashboard'); }}>
                    <span>
                      <i className="icon-Dashboard-Outline" />
                      Dashboard
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/activity");
                    }}
                  >
                    <span>
                      <i className="icon-Activity-Outline" />
                      Activity
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/calendar");
                    }}
                  >
                    <span>
                      <i className="icon-Timetable-Outline" />
                      Calendar
                    </span>
                  </li>
                  <li className="selected">
                    <span>
                      <i className="icon-Staff-Outline" />
                      Staff
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Message-Outline" />
                      Messages
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Fees-Outline" />
                      Fees Management
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Enquiries-Outline" />
                      Enquiries
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Invites-Outline" />
                      Invites
                    </span>
                  </li>
                </ul>
                <h4 className="sp-title flexc justifySp">
                  Programs{" "}
                  <span id="add-programs" className="sp-plus">
                    +
                  </span>
                </h4>
                <ul>
                  {programsLoading &&
                    programsData &&
                    programsData.length > 0 ? (
                    programsData.map((data) => {
                      return (
                        <li
                          onClick={() => {
                            navigate("/student_list");
                          }}
                        >
                          <span>
                            <i className="icon-Program-Outline-1" />
                            {data.program_name}
                          </span>
                        </li>
                      );
                    })
                  ) : (
                    <Loader type="spinner-cub" bgColor="#4D6EE3" size={30} />
                  )}
                </ul>
              </div>
            </div>
            <div className="sp-bottom sp-nav">
              <ul>
                <li>
                  <span>
                    <i className="icon-Import-Outline" />
                    Notifications
                  </span>
                </li>
                <li>
                  <span>
                    <i className="icon-Invites-Outline" />
                    Upgrade Pro
                  </span>
                </li>
                <li onClick={() => navigate("/signout")}>
                  <span>
                    <i className="icon-Invites-Outline" />
                    Log Out
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="sp-right-panel">
          <div className="sp-top-bar flexc justifySp">
            <div className="ft-wt600 flexc">
              <span className="selected-icon">
                <i className="icon-Program-Outline-1" />
              </span>
              Staff
              <i className="icon-More-Icon" />
            </div>
            <div className="flexc">
              <div className="file-input-container">
                <label
                  className="sp-view-btn ft-wt500 flexc"
                  //   htmlFor="fileInput"
                  onClick={() => {
                    importStudents1();
                  }}
                >
                  <i className="icon-Import-Outline" />
                  Import
                </label>
              </div>
              <button className="createN ft-wt500 mr-lf10" id="newStaffCreate">
                + New Staff
              </button>
            </div>
          </div>
          <div className="sp-header">
            <nav className="flexc">
              {/* <div class="sp-list-view flexc hidden"><i class="icon-Attendance-Outline"></i>Student List</div> */}
              <div className="sp-list-view flexc selected">
                <i className="icon-Attendance-Outline" />
                Staff List
              </div>
              <div
                className="sp-list-view flexc"
                onClick={() => {
                  navigate("/staff_attendance");
                }}
              >
                <i className="icon-Attendance-Outline" />
                Attendance
              </div>
              <div className="sp-list-view flexc">
                <i className="icon-Calendar-Outline" />
                Timetable
              </div>
            </nav>
            <div className="sp-menu-bar flexc justifySp">
              <div className="sp-menugroup">
                <div className="flexc">
                  <div className="btn-dropdown">
                    <span className="flexc">
                      Active Students
                      <i className="icon-Down-Arrow" />
                    </span>
                  </div>
                  <div className="btn-search-bar flexc">
                    <i className="icon-Search" />
                    <input
                      type="text"
                      placeholder="Search Staff"
                      value={filterValue}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="flexc sp-menugroup">
                <div className="sp-menubar-clslist flexc">
                  <span className="ft-wt500 ft-14">Class Teachers</span>
                  <ul className="flexc">
                    <li>
                      <img src={Avatar1} />
                    </li>
                    <li>
                      <img src={Avatar1} />
                    </li>
                    <li>
                      <img src={Avatar1} />
                    </li>
                  </ul>
                  <span className="sp-plus">+</span>
                </div>
              </div>
            </div>
          </div>
          <div className="load-container">
            <div className="sp-table-load overxy-auto">
              <div className="sp-table-container">

                {!staffData || staffData.length === 0 ? (
                  <ImportComponent
                    importStudent={"staff"}
                    handleImportStatus={handleImportStatus}
                  />
                ) : importstatus ? (
                  <ImportComponent
                    importStudent={"staff"}
                    handleImportStatus={handleImportStatus}
                  />
                ) : (
                  <>
                    <div className="sp-table-header flexc sp-table-header">
                      <div className="checkbox">
                        <i className="un-check icon-sp-present" />
                      </div>
                      <div className="sp-title flex-2">
                        <span>Staff Name</span>
                      </div>
                      <div className="sp-title flex-2">
                        <span>Email</span>
                      </div>
                      <div className="sp-title flex-1">
                        <span>Phone Number</span>
                      </div>
                      <div className="sp-title flex-1">
                        <span>Role</span>
                      </div>
                      <div className="sp-title flex-1">
                        <span>Program</span>
                      </div>
                      <div className="sp-title flex-1">
                        <span>Joining Date</span>
                      </div>
                      <div className="sp-title flex-1">
                        <span>Date of Birth</span>
                      </div>
                    </div>
                    <div className="sp-table-body" style={{ overflowX: "auto" }}>
                      {loading && staffData && staffData.length > 0 ? (
                        staffData.map((student) => (
                          <div
                            className="select_field_point"
                            style={{ cursor: "pointer" }}
                            onClick={(event) => handleTabSelect(event)}
                          >
                            <div className="row flexc">
                              <div class="checkbox">
                                <i class="un-check icon-Tick"></i>
                              </div>
                              <div
                                className="sp-td-content flex-2"
                                onClick={() => {
                                  openStudentPopup(student);
                                }}
                              >
                                <span className="ft-500 flexc">
                                  <img
                                    src={Avatar1}
                                    width={40}
                                    alt={student?.first_name}
                                  />
                                  <span className="mr-lf10 truncate">
                                    {student?.first_name} {student?.last_name}
                                  </span>
                                </span>
                              </div>
                              <div
                                className="sp-td-content flex-2"
                                onClick={() => {
                                  openStudentPopup(student);
                                }}
                              >
                                <span className="ft-400 truncate">{student?.email}</span>
                              </div>
                              <div
                                className="sp-td-content flex-1"
                                onClick={() => {
                                  openStudentPopup(student);
                                }}
                              >
                                <span className="ft-400">{student?.phone}</span>
                              </div>
                              <div
                                className="sp-td-content flex-1"
                                onClick={() => {
                                  openStudentPopup(student);
                                }}
                              >
                                <span className="ft-400 truncate">
                                  {
                                    // student?.role_id
                                    roles.map((role) =>
                                      role.id === parseInt(student?.role_id)
                                        ? role.role_name
                                        : null
                                    )
                                  }
                                </span>
                              </div>
                              <div
                                className="sp-td-content flex-1 but_update_div"
                                onClick={() => {
                                  openStudentPopup(student);
                                }}
                              >
                                {/* <span className="ft-400">{student?.assign_classes}</span> */}
                                <span className="ft-400">
                                  {student?.assign_classes
                                    .split(",")
                                    .map((subProgram, index) =>
                                      programs.map((program) =>
                                        subProgram === program.id.toString() ? (
                                          <button
                                            key={program.id}
                                            className="last_div sp-selected-bt ft-wt600 ft-13"
                                            style={{ margin: "5px" }}
                                          >
                                            {program.program_name}
                                          </button>
                                        ) : null
                                      )
                                    )}
                                </span>
                              </div>
                              <div
                                className="sp-td-content flex-1"
                                onClick={() => {
                                  openStudentPopup(student);
                                }}
                              >
                                <span className="ft-400">
                                  {String(student?.joining_date).split("T")[0]}
                                </span>
                              </div>
                              <div
                                className="sp-td-content flex-1"
                                onClick={() => {
                                  openStudentPopup(student);
                                }}
                              >
                                <span className="ft-400">
                                  {String(student?.dob).split("T")[0]}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : staffData.length == 0 ? (
                        <div className="sp-title flex-3">Data Not Found</div>
                      ) : (
                        <Loader
                          type="spinner-cub"
                          bgColor="#4D6EE3"
                          color="#4D6EE3"
                          title="Loading..."
                          size={50}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Create NewStudent */}
        <div className="popup-modal-container">
          <div className="popup-wrap">
            {studentId !== null ? (
              <h2 className="ft-wt700">Update Staff</h2>
            ) : (
              <h2 className="ft-wt700">Create New Staff</h2>
            )}
            <div className="sp-pop-header">
              <h3 className="ft-wt600">Staff Details</h3>
              <div className="form-section-default flexc justifySp fwrap">
                <div className="field-label half-field">
                  <label>Staff Name</label>
                  <div className="field-value-holder">
                    <input
                      ref={firstNameInputRef}
                      type="text"
                      value={user.first_name}
                      onChange={(ev) =>
                        setUser({ ...user, first_name: ev.target.value })
                      }
                      onKeyPress={(event) =>
                        handleKeyPress(event, emailInputRef)
                      }
                      className="sp-field-input"
                      placeholder="Enter full name"
                      style={{
                        borderColor: validationErrors.first_name
                          ? "red"
                          : "#cccccc", // Replace '#cccccc' with your default border color if different
                        borderWidth: "1px",
                        borderStyle: "solid",
                      }}
                    />
                  </div>
                  {validationErrors.first_name && (
                    <span className="error">{validationErrors.first_name}</span>
                  )}
                </div>
                <div className="field-label half-field">
                  <label>Email</label>
                  <div className="field-value-holder ">
                    <input
                      ref={emailInputRef}
                      type="email"
                      value={user.email}
                      onChange={(ev) =>
                        setUser({ ...user, email: ev.target.value })
                      }
                      onKeyPress={(event) =>
                        handleKeyPress(event, pnoneInputRef)
                      }
                      className="sp-field-input"
                      placeholder="Enter your email"
                      style={{
                        borderColor: validationErrors.email ? "red" : "#cccccc", // Replace '#cccccc' with your default border color if different
                        borderWidth: "1px",
                        borderStyle: "solid",
                      }}
                    />
                  </div>
                  {validationErrors.email && (
                    <span className="error">{validationErrors.email}</span>
                  )}
                </div>
                <div className="field-label half-field">
                  <label>Phone Number</label>
                  <div className="field-value-holder ">
                    <input
                      ref={pnoneInputRef}
                      type="text"
                      value={user.phone}
                      onChange={(ev) =>
                        setUser({ ...user, phone: ev.target.value })
                      }
                      onKeyPress={(event) => handleKeyPress(event, dobInputRef)}
                      className="sp-field-input"
                      placeholder="Phone Number"
                      style={{
                        borderColor: validationErrors.phone ? "red" : "#cccccc", // Replace '#cccccc' with your default border color if different
                        borderWidth: "1px",
                        borderStyle: "solid",
                      }}
                    />
                  </div>
                  {validationErrors.phone && (
                    <span className="error">{validationErrors.phone}</span>
                  )}
                </div>
                <div className="field-label half-field">
                  <label>Date of Birth</label>
                  <div className="field-value-holder ">
                    <input
                      ref={dobInputRef}
                      type="date"
                      value={user.dob ? user.dob.split("T")[0] : ""} //
                      onChange={(ev) =>
                        setUser({ ...user, dob: ev.target.value })
                      }
                      onKeyPress={(event) =>
                        handleKeyPress(event, joingInputRef)
                      }
                      className="sp-field-input"
                      placeholder="DD-MM-YYYY"
                      style={{
                        borderColor: validationErrors.dob ? "red" : "#cccccc",
                        borderWidth: "1px",
                        borderStyle: "solid",
                      }}
                    />
                  </div>
                  {validationErrors.dob && (
                    <span className="error">{validationErrors.dob}</span>
                  )}
                </div>
                <div className="field-label half-field">
                  <label>Joining Date</label>
                  <div className="field-value-holder">
                    <input
                      ref={joingInputRef}
                      type="date"
                      value={
                        user.joining_date ? user.joining_date.split("T")[0] : ""
                      }
                      onChange={(ev) =>
                        setUser({ ...user, joining_date: ev.target.value })
                      }
                      onKeyPress={(event) =>
                        handleKeyPress(event, adressInputRef)
                      }
                      className="sp-field-input"
                      placeholder="DD-MM-YYYY"
                      style={{
                        borderColor: validationErrors.joining_date
                          ? "red"
                          : "#cccccc",
                        borderWidth: "1px",
                        borderStyle: "solid",
                      }}
                    />
                  </div>
                  {validationErrors.joining_date && (
                    <span className="error">
                      {validationErrors.joining_date}
                    </span>
                  )}
                </div>
                <div className="field-label full-field">
                  <label>Address</label>
                  <div className="field-value-holder ">
                    <textarea
                      ref={adressInputRef}
                      value={user.address}
                      onChange={(ev) =>
                        setUser({ ...user, address: ev.target.value })
                      }
                      className="sp-field-input"
                      placeholder="Address"
                      defaultValue={""}
                      style={{
                        borderColor: validationErrors.address
                          ? "red"
                          : "#cccccc",
                        borderWidth: "1px",
                        borderStyle: "solid",
                      }}
                    // onKeyPress={event => handleKeyPress(event, rollInputRef)}
                    />
                  </div>
                  {validationErrors.address && (
                    <span className="error">{validationErrors.address}</span>
                  )}
                </div>
              </div>
              <h3 className="ft-wt600 mt-30">Roles &amp; Permissions</h3>
              <div className="form-section-default flexc justifySp fwrap">
                <div className="field-label half-field">
                  <label>Role</label>
                  <div
                    className="field-value-holder dropbutton"
                    // style={{ width: '100%' }}
                    style={{
                      borderColor: validationErrors.role_id ? "red" : "#cccccc",
                      width: "100%",
                      borderWidth: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    <Dropdown
                      label={
                        <span
                          style={{ color: "#707070", fontSize: "0.8825em" }}
                        >
                          {selectedRoles
                            ? selectedRoles.role_name
                            : "Select Role"}
                        </span>
                      }
                      style={{ width: "100%" }}
                    >
                      {roles.map((item) => (
                        <Dropdown.Item
                          className={
                            selectedRoles && selectedRoles.id === item.id
                              ? "dd_select"
                              : null
                          }
                          icon={
                            selectedRoles && selectedRoles.id === item.id
                              ? HiCheck
                              : null
                          }
                          onClick={() => setUser({ ...user, role_id: item.id })}
                          key={item.id}
                        >
                          {item.role_name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown>
                  </div>
                  {validationErrors.role_id && (
                    <span className="error">{validationErrors.role_id}</span>
                  )}
                </div>
                <div className="field-label full-field">
                  <label>Programs</label>
                  <div
                    className="label-permissions mt-3 scl-prg "
                    style={{ display: "flex", margin: "10px" }}
                  >
                    {programs.map((programs) => (
                      <a
                        key={programs.id}
                        className={`last_div ft-wt600 ft-13 ${selectedPrograms.includes(programs.id.toString())
                          ? "sp-selected"
                          : ""
                          }`}
                        style={{
                          display: "inline-block",
                          padding: "10px 20px",
                          margin: "5px",
                          textDecoration: "none",
                          color: selectedPrograms.includes(
                            programs.id.toString()
                          )
                            ? "#ffff"
                            : "#4D6EE3",
                          backgroundColor: selectedPrograms.includes(
                            programs.id.toString()
                          )
                            ? "#4D6EE3"
                            : "#ffff",
                          borderRadius: "5px",
                          cursor: "pointer",
                          textAlign: "center",
                          border: selectedPrograms.includes(
                            programs.id.toString()
                          )
                            ? "1px solid #4D6EE3"
                            : "1px solid #4D6EE3",
                        }}
                      >
                        <label
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <input
                            type="checkbox"
                            checked={selectedPrograms.includes(
                              programs.id.toString()
                            )}
                            onChange={(e) =>
                              handleProgramsChange(
                                programs.id.toString(),
                                e.target.checked
                              )
                            }
                            style={{ marginRight: "8px" }}
                          />
                          {programs.program_name}
                        </label>
                      </a>
                    ))}
                  </div>
                  {validationErrors.programs && (
                    <span className="error">{validationErrors.programs}</span>
                  )}
                </div>
                <div className="field-label full-field">
                  <label>Permissions</label>
                  <div
                    className="label-permissions mt-3 scl-prg "
                    style={{ display: "flex", margin: "10px" }}
                  >
                    {permissions.map((permission) => (
                      <a
                        key={permission.id}
                        className={`last_div ft-wt600 ft-13 ${selectedPermissions.includes(permission.id.toString())
                          ? "sp-selected"
                          : ""
                          }`}
                        style={{
                          display: "inline-block",
                          padding: "10px 20px",
                          margin: "5px",
                          textDecoration: "none",
                          color: selectedPermissions.includes(
                            permission.id.toString()
                          )
                            ? "#ffff"
                            : "#4D6EE3",
                          backgroundColor: selectedPermissions.includes(
                            permission.id.toString()
                          )
                            ? "#4D6EE3"
                            : "#ffff",
                          borderRadius: "5px",
                          cursor: "pointer",
                          textAlign: "center",
                          border: selectedPermissions.includes(
                            permission.id.toString()
                          )
                            ? "1px solid #4D6EE3"
                            : "1px solid #4D6EE3",
                          borderColor: validationErrors.permission_name
                            ? "red"
                            : "#cccccc",
                        }}
                      >
                        <label
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <input
                            type="checkbox"
                            checked={selectedPermissions.includes(
                              permission.id.toString()
                            )}
                            onChange={(e) =>
                              handlePermissionChange(
                                permission.id.toString(),
                                e.target.checked
                              )
                            }
                            style={{ marginRight: "8px" }}
                          />
                          {permission.permission_name}
                        </label>
                      </a>
                    ))}
                  </div>
                  {validationErrors.permissions_id && (
                    <span className="error">
                      {validationErrors.permissions_id}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="sp-bottom-sticky">
              {studentId === null ? (
                <button className="sp-btn-create" onClick={onSubmit}>
                  Create
                </button>
              ) : (
                <button className="sp-btn-create" onClick={updateUser}>
                  Update
                </button>
              )}

              {/* <button className="sp-btn-create" onClick={onSubmit}>Create</button> */}
              <button className="sp-btn-cancel">Cancel</button>
            </div>
          </div>
        </div>
        {/* Creating afterPopup */}
        <div className="popup-student-details">
          <span className="close icon-Dropdown_Close flexc cp" />
          <div className="student-details-page">
            <div className="sp-top-details flexc">
              <div className="sp-profile-head">
                {/* <img src="images/avatar-profile.png" width={62} height={62} /> */}
                <span className="flexc margin_20p">
                  <em className="ft-wt600 ft-18">{displayUser.first_name}</em>
                  <label className="sp-status">check In</label>
                </span>
              </div>
              <div className="sp-profile-rt flexc">
                {/* <span className="sp-accbox icon-Message-Outline flexc" />{" "} */}
                <span
                  className="sp-accbox mr-lf10 icon-Message-Outline flexc"
                  onClick={() => {
                    openStudentUpdatePopup(displayUser);
                  }}
                />
              </div>
            </div>
            <div className="sp-details-box">
              <div className="sp-tt ft-14 flexc">
                <i className="icon-Invites-Outline" />
                Staff Details
              </div>
              <div className="form-section-default flexc justifySp fwrap">
                <div className="field-label half-field">
                  <label className="label-title">Staff Name</label>
                  <div className="label-filled">{displayUser.first_name}</div>
                </div>
                <div className="field-label half-field">
                  <label className="label-title">Email ID</label>
                  <div className="label-filled">{displayUser.email}</div>
                </div>
                {/* <div className="field-label half-field">
                                      <label className="label-title">Program</label>
                                      <div className="label-filled">{displayUser.programs_id}</div>
                                  </div> */}
                <div className="field-label half-field">
                  <label className="label-title">Phone Number</label>
                  <div className="label-filled">{displayUser.phone}</div>
                </div>
                <div className="field-label half-field">
                  <label className="label-title">Date of Birth</label>
                  <div className="label-filled">
                    {String(displayUser.dob).split("T")[0]}
                  </div>
                </div>
                <div className="field-label half-field">
                  <label className="label-title">Date of Joining</label>
                  <div className="label-filled">
                    {String(displayUser.joining_date).split("T")[0]}
                  </div>
                </div>
                <div className="field-label full-field">
                  <label className="label-title">Address</label>
                  <div className="label-filled">{displayUser.address}</div>
                </div>
              </div>
            </div>

            <div className="sp-details-box">
              <div className="sp-tt ft-14 flexc">
                <i className="icon-Invites-Outline" />
                Roles &amp; Permissions
              </div>
              <div className="form-section-default flexc justifySp fwrap">
                <div className="field-label full-field">
                  <label className="label-title">Role</label>
                  <div className="label-filled">
                    {" "}
                    {roles.map((role) =>
                      role.id === parseInt(displayUser.role_id)
                        ? role.role_name
                        : null
                    )}
                  </div>
                </div>
                <div className="field-label full-field">
                  <label className="label-title">Premissions</label>
                  <div className="flexc margin_top10p label-fill-value fwrap gap-10">
                    {displayUser.permissions_id
                      .split(",")
                      .map((subProgram, index) => (
                        <div key={index}>
                          {permissions.map((program) =>
                            subProgram === program.id.toString() ? (
                              <button
                                key={program.id}
                                className="last_div sp-selected-bt ft-wt600 ft-13"
                                style={{ margin: "5px" }}
                              >
                                {program.permission_name}
                              </button>
                            ) : null
                          )}
                        </div>
                      ))}
                    {/* {Array.isArray(displayUser.permissions_id) ? displayUser.permissions_id.map(permission => (
                                              <button key={permission} className="sp-selected-bt ft-wt400 ft-13">
                                                  {permission}
                                              </button>
                                          )) : null} */}
                  </div>
                </div>
              </div>
            </div>
            <div className="sp-details-box">
              <div className="sp-tt ft-14 flexc">
                <i className="icon-Invites-Outline" />
                Emergency Contact
              </div>
              <div className="form-section-default flexc justifySp fwrap">
                <div className="field-label full-field text-center">
                  <label className="label-title">No Contact found</label>
                  <div className="margin_top10p label-fill-value">
                    <button className="sp-selected-bt ft-wt400 ft-13 inline-flex items-center">
                      <i className="icon-Button-Plus" />
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="sp-details-box">
              <div className="sp-tt ft-14 flexc">
                <i className="icon-Invites-Outline" />
                Attendance Detail
              </div>
              <div className="sp-dashboard">
                <img src={Attendance} width={100} />
              </div>
              <div className="field-label-op">
                <div className="flexc justifySp margin_20p">
                  <span className="flexc ft-13">
                    <span className="sp-lpdr icon-sp-present mr-rt10" />
                    Present
                  </span>
                  <span className="ft-13">130 Days</span>
                </div>
                <div className="flexc justifySp margin_20p">
                  <span className="flexc ft-13">
                    <span className="cross-ele mr-rt10" />
                    Leave
                  </span>
                  <span className="ft-13">20 Days</span>
                </div>
                <div className="flexc justifySp margin_20p">
                  <span className="flexc ft-13">
                    <span className="notapp-ele mr-rt10" />
                    Leave
                  </span>
                  <span className="ft-13">20 Days</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
