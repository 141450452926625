import React, { useState, useMemo, useEffect } from "react";
import { Link, Navigate, Outlet } from "react-router-dom";

export default function Header() {
    return (
        <header>
            <head>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Document</title>
                <link href="https://fonts.googleapis.com/css2?family=Aldrich&family=Inter:wght@100..900&family=Lato:ital,wght@0,300;0,400;0,700;1,300&family=Roboto:ital,wght@0,100;1,100;1,300;1,400&display=swap" rel="stylesheet" />
                <script src="https://code.iconify.design/3/3.1.0/iconify.min.js"></script>
                <script src="https://code.jquery.com/ui/1.13.2/jquery-ui.min.js"></script>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />

            </head>
        </header>
    );
}
