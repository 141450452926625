import React, { useState, useRef, useEffect } from "react";
import $ from "jquery";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Avatar1 from "../school/images/avatar1.png";
import Avatar2 from "../school/images/avatar2.png";
import Avatar3 from "../school/images/avatar3.png";
import Avatar4 from "../school/images/avatar4.png";
// import "../../common/components/css/main.css";
import "../../common/components/css/style1.css";
import { baseUrl } from "../../common/common";
import Avatarprofile from "../school/images/profile1.png";
import Avatardata from "../school/images/data-show.png";
import Avatarprofile1 from "../school/images/profile1.png";
import Loader from "react-js-loader";
import validator from "validator";
// import {Alert} from 'react-bootstrap';
import { Dropdown, Datepicker } from "flowbite-react";
import "../../index.css";
import { HiCheck } from "react-icons/hi";
import { Tooltip } from "react-tooltip";
import ImportComponent from "../Import/Import";

export default function Student_list() {
  
  const location = useLocation();
  const navigate = useNavigate();
  const formData = new FormData();

  const gender1 = [
    {
      id: 1,
      value: "Male",
    },
    {
      id: 2,
      value: "Female",
    },
  ];

  const Blood1 = [
    {
      id: 1,
      value: "A+",
    },
    {
      id: 2,
      value: "A-",
    },
    {
      id: 3,
      value: "O+",
    },
    {
      id: 4,
      value: "B-",
    },
    {
      id: 5,
      value: "B+",
    },
    {
      id: 6,
      value: "B+",
    },
  ];

  const [user, setUser] = useState({
    school_id: localStorage.getItem("schoolId"),
    studentID: "",
    first_name: "",
    last_name: "",
    gender: "",
    programs_id: "",
    dob: "",
    joining_date: "",
    blood_group: "",
    address: "",
    father_name: "",
    mother_name: "",
    primary_email: "",
    secondary_email: "",
    primary_phone: "",
    secondary_phone: "",
    student_qr_code: "",
    status: 1,
    created_by: "",
    updated_by: "",
    profile_image: null,
  });

  const [displayUser, displaySetUser] = useState({
    id: "",
    school_id: localStorage.getItem("schoolId"),
    studentID: "",
    first_name: "",
    last_name: "",
    gender: "",
    programs_id: "",
    dob: "",
    joining_date: "",
    blood_group: "",
    address: "",
    father_name: "",
    mother_name: "",
    primary_email: "",
    secondary_email: "",
    primary_phone: "",
    secondary_phone: "",
    student_qr_code: "",
    status: 1,
    created_by: "",
    updated_by: "",
    profile_image: null,
  });



  //------------------- image upload
  // const handleImageChange = (event) => {
  //     const files = event.target.files;
  //     setUser({ ...user, profile_image: files[0] });
  //     const file = event.target.files[0];
  //     setSelectedImage(file);
  // };
  const [selectedImage, setSelectedImage] = useState(null);
  const [importstatus, setimportstatus] = useState(false);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setUser({ ...user, profile_image: file });
    setSelectedImage(file);
  };

  // ------------------------------
  const handleImportStatus = (status) => {
    if (status == true) {
      fetchStudentData(programId);
      setimportstatus(false);
    }
  };
  //------------------- import students -----------
  const [selectedImport, setSelectedImport] = useState("");
  const importStudents = (event) => {
    const files = event.target.files;
    //setUser({ ...user, profile_image: files[0] });
    const importFile = event.target.files[0];
    setimportstatus(true);

    axios({
      method: "post",
      url: baseUrl + "/api/student_import",
      data: {
        school_id: localStorage.getItem("schoolId"),
        programs_id: programId,
        file: importFile,
      },
      headers: { "Content-Type": "multipart/form-data" },
    }).then((response) => {
      console.log(response);
      if (response["data"]["status"] === true) {
        fetchStudentData(programId);
        console.log(response);
      }
    });
    //setSelectedImport(file);
  };
  const importStudents1 = () => {
    setimportstatus(true);
  };
  // ------------------------------

  const onSubmit = (ev) => {
    ev.preventDefault();
    Object.entries(user).forEach(([key, value]) => {
      formData.append(key, value);
    });
    if (validateForm()) {
      console.log(user);
      axios({
        method: "post",
        url: baseUrl + "/api/student_add",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => {
        console.log(response);
        if (response["data"]["status"] === true) {
          displaySetUser({
            // school_id: 1,
            first_name: response["data"]["data"]["first_name"],
            //last_name: '',
            studentID: response["data"]["data"]["studentID"],
            gender: response["data"]["data"]["gender"],
            dob: response["data"]["data"]["dob"],
            joining_date: response["data"]["data"]["joining_date"],
            blood_group: response["data"]["data"]["blood_group"],
            address: response["data"]["data"]["address"],
            father_name: response["data"]["data"]["father_name"],
            mother_name: response["data"]["data"]["mother_name"],
            primary_email: response["data"]["data"]["primary_email"],
            secondary_email: response["data"]["data"]["secondary_email"],
            primary_phone: response["data"]["data"]["primary_phone"],
            secondary_phone: response["data"]["data"]["secondary_phone"],
            profile_image: response["data"]["data"]["profile_image"],
            programs_id: response["data"]["data"]["programs_id"],
          });
          $(".overlay").removeClass("visible");
          $(".popup-modal-container").removeClass("visible");
          setTimeout(function () {
            $(".popup-student-details").addClass("visible");
          }, 500);
          setUser({
            school_id: localStorage.getItem("schoolId"),
            first_name: "",
            last_name: "",
            gender: "",
            programs_id: "",
            dob: "",
            joining_date: "",
            blood_group: "",
            address: "",
            father_name: "",
            mother_name: "",
            primary_email: "",
            secondary_email: "",
            primary_phone: "",
            secondary_phone: "",
            student_qr_code: "",
            status: 1,
            created_by: "",
            updated_by: "",
            studentID: "",
            profile_image: null,
          });
          fetchStudentData(programId);
          console.log(response);
        }
      });
    }
  };

  const updateUser = () => {
    setError(null);
    console.log(user);
    axios({
      method: "post",
      url: baseUrl + "/api/student_add",
      data: {
        id: studentId,
        school_id: localStorage.getItem("schoolId"),
        first_name: user.first_name,
        last_name: "",
        gender: user.gender,
        dob: user.dob,
        programs_id: user.programs_id,
        joining_date: user.joining_date,
        blood_group: user.blood_group,
        address: user.address,
        father_name: user.father_name,
        mother_name: user.mother_name,
        primary_email: user.primary_email,
        secondary_email: user.secondary_email,
        primary_phone: user.primary_phone,
        secondary_phone: user.secondary_phone,
        profile_image: user.profile_image,
        student_qr_code: "",
        status: 1,
        created_by: "",
        updated_by: localStorage.getItem("schoolId"),
        studentID: user.studentID,
      },
      headers: { "Content-Type": "multipart/form-data" },
    }).then((response) => {
      console.log(response);
      if (response["data"]["status"] === true) {
        displaySetUser({
          // school_id: 1,
          first_name: response["data"]["data"]["first_name"],
          //last_name: '',
          studentID: response["data"]["data"]["studentID"],
          gender: response["data"]["data"]["gender"],
          dob: response["data"]["data"]["dob"],
          joining_date: response["data"]["data"]["joining_date"],
          blood_group: response["data"]["data"]["blood_group"],
          address: response["data"]["data"]["address"],
          father_name: response["data"]["data"]["father_name"],
          mother_name: response["data"]["data"]["mother_name"],
          primary_email: response["data"]["data"]["primary_email"],
          secondary_email: response["data"]["data"]["secondary_email"],
          primary_phone: response["data"]["data"]["primary_phone"],
          secondary_phone: response["data"]["data"]["secondary_phone"],
          profile_image: response["data"]["data"]["profile_image"],
          programs_id: response["data"]["data"]["programs_id"],
        });
        $(".overlay").removeClass("visible");
        $(".popup-modal-container").removeClass("visible");
        setTimeout(function () {
          $(".popup-student-details").addClass("visible");
        }, 2500);
        setUser({
          school_id: localStorage.getItem("schoolId"),
          first_name: "",
          last_name: "",
          gender: "",
          programs_id: "",
          dob: "",
          joining_date: "",
          blood_group: "",
          address: "",
          father_name: "",
          mother_name: "",
          primary_email: "",
          secondary_email: "",
          primary_phone: "",
          secondary_phone: "",
          student_qr_code: "",
          status: 1,
          created_by: "",
          updated_by: "",
          studentID: "",
          profile_image: null,
        });
        fetchStudentData(programId);
        // localStorage.setItem('userId', response['data']['user_id']);
        console.log(response);
      }
    });
  };



  const [studentData, setStudentData] = useState({});
  const [loading, setLoading] = useState(false);
  const [programId, setProgramId] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const handleInputChange = async (event) => {
    setimportstatus(false);
    setFilterValue(event.target.value);
    try {
      const postData = {
        school_id: localStorage.getItem("schoolId"),
        search: event.target.value.trim(),
        programs_id: programId,
        status: 1,
      };
      const { data } = await axios.post(baseUrl + "/api/get_student", postData);
      console.log(data.data, "d");
      setStudentData(data.data);
      // setLoading(true);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchStudentData = async (programsId) => {
    setimportstatus(false);
    setLoading(false);
    setProgramId(programsId);
    try {
      const postData = {
        school_id: localStorage.getItem("schoolId"),
        search: "",
        programs_id: programsId,
        status: 1,
      };
      const { data } = await axios.post(baseUrl + "/api/get_student", postData);
      console.log(data.data, "d");
      setStudentData(data.data);
      setLoading(true);
    } catch (error) {
      console.log(error);
    }
  };

  const [programsData, setProgramsData] = useState([]);
  const [programsLoading, setProgramsLoading] = useState(false);
  const [programs, setPrograms] = useState([]); //
  const [programName, setProgramName] = useState(""); //
  const fetchProgramsData = async () => {
    setProgramsLoading(false);
    try {
      const postData = {
        school_id: localStorage.getItem("schoolId"),
        // school_id:1
      };
      const { data } = await axios.post(
        baseUrl + "/api/get_programs",
        postData
      );
      setProgramsData(data.data);
      setProgramsLoading(true);
      localStorage.setItem("programId", data.data[0].id);
      localStorage.setItem("programName", data.data[0].program_name);
      // setProgramName(data.data[0].program_name);
      // fetchStudentData(data.data[0].id);
      setProgramName(localStorage.getItem("programName"));
      fetchStudentData(localStorage.getItem("programId"));
    } catch (error) {
      console.log(error);
    }
  };

  const openStudentPopup = (studentData) => {
    displaySetUser({
      // school_id: 1,
      id: studentData.id,
      first_name: studentData.first_name,
      //last_name: '',
      studentID: studentData.studentID,
      gender: studentData.gender,
      dob: studentData.dob,
      joining_date: studentData.joining_date,
      blood_group: studentData.blood_group,
      address: studentData.address,
      father_name: studentData.father_name,
      mother_name: studentData.mother_name,
      primary_email: studentData.primary_email,
      secondary_email: studentData.secondary_email,
      primary_phone: studentData.primary_phone,
      secondary_phone: studentData.secondary_phone,
      profile_image: studentData.profile_image,
      programs_id: studentData.programs_id,
    });
    $(".overlay").removeClass("visible");
    $(".popup-modal-container").removeClass("visible");
    setTimeout(function () {
      $(".popup-student-details").addClass("visible");
    }, 500);
  };

  const [studentId, setStudentId] = useState(null);
  const openStudentUpdatePopup = (studentData) => {
    $(".popup-student-details").removeClass("visible");
    setError(null);
    setSelectedImage(null);
    setValidationErrors({
      first_name: "",
      gender: "",
      dob: "",
      programs_id: "",
      profile_image: "",
      joining_date: "",
      studentID: "",
      blood_group: "",
      address: "",
      father_name: "",
      primary_phone: "",
      primary_email: "",
      mother_name: "",
      secondary_phone: "",
      secondary_email: "",
    });
    setStudentId(studentData.id);
    setUser({
      school_id: localStorage.getItem("schoolId"),
      first_name: studentData.first_name,
      last_name: studentData.first_name,
      gender: studentData.gender,
      programs_id: studentData.programs_id,
      dob: studentData.dob,
      joining_date: studentData.joining_date,
      blood_group: studentData.blood_group,
      address: studentData.address,
      father_name: studentData.father_name,
      mother_name: studentData.mother_name,
      primary_email: studentData.primary_email,
      secondary_email: studentData.secondary_email,
      primary_phone: studentData.primary_phone,
      secondary_phone: studentData.secondary_phone,
      student_qr_code: "",
      status: 1,
      created_by: "",
      updated_by: "",
      studentID: studentData.studentID,
      profile_image: studentData.profile_image,
    });
    $(".overlay").addClass("visible");
    $(".popup-modal-container").addClass("visible");
  };

  const fetchPrograms = async () => {
    try {
      const response = await axios.post(baseUrl + "/api/get_programs", {
        school_id: localStorage.getItem("schoolId"),
      });
      setPrograms(response.data.data);
    } catch (error) { }
  };

  const selectedProgram = programs.find(
    (program) => program.id === user.programs_id
  );

  useEffect(() => {
    fetchPrograms();
    fetchProgramsData();
    $(document).on("click", "#newStudentCreate", function () {
      setStudentId(null);
      setSelectedImage(null);
      setUser({
        school_id: localStorage.getItem("schoolId"),
        first_name: "",
        last_name: "",
        gender: "",
        programs_id: "",
        dob: "",
        joining_date: "",
        blood_group: "",
        address: "",
        father_name: "",
        mother_name: "",
        primary_email: "",
        secondary_email: "",
        primary_phone: "",
        secondary_phone: "",
        student_qr_code: "",
        status: 1,
        created_by: "",
        updated_by: "",
        studentID: "",
        profile_image: null,
      });
      setValidationErrors({
        first_name: "",
        gender: "",
        dob: "",
        programs_id: "",
        profile_image: "",
        joining_date: "",
        studentID: "",
        blood_group: "",
        address: "",
        father_name: "",
        primary_phone: "",
        primary_email: "",
        mother_name: "",
        secondary_phone: "",
        secondary_email: "",
      });
      $(".overlay").addClass("visible");
      $(".popup-modal-container").addClass("visible");
    });
    $(document).on("click", ".sp-btn-cancel", function () {
      setError(null);
      $(".overlay").removeClass("visible");
      $(".popup-modal-container").removeClass("visible");
    });
  }, []);

  const firstNameInputRef = useRef(null);
  const genderInputRef = useRef(null);
  const dobInputRef = useRef(null);
  const programInputRef = useRef(null);
  const imageInputRef = useRef(null);
  const joingInputRef = useRef(null);
  const studentInputRef = useRef(null);
  const bloodInputRef = useRef(null);
  const adressInputRef = useRef(null);
  const fathernameInputRef = useRef(null);
  const fatherphoneInputRef = useRef(null);
  const fatheremailInputRef = useRef(null);
  const mathernameInputRef = useRef(null);
  const matherphoneInputRef = useRef(null);
  const matheremailInputRef = useRef(null);
  const submitButtonRef = useRef(null);

  const handleDropdownKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      imageInputRef.current.focus(); // Move focus to the next input field
    }
  };

  const handleKeyPress = (event, nextInputRef) => {
    if (event.key === "Enter") {
      event.preventDefault();
      console.log("Next input ref:", nextInputRef); // Log the next input ref
      if (nextInputRef && nextInputRef.current) {
        nextInputRef.current.focus();
      } else {
        console.log("Next input ref is null");
      }
    }
  };

  // const handleImageChange = (event) => {
  //     const file = event.target.files[0];
  //     setUser({ ...user, profile_image: file });
  // };

  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({
    first_name: "",
    gender: "",
    dob: "",
    programs_id: "",
    profile_image: "",
    joining_date: "",
    studentID: "",
    blood_group: "",
    address: "",
    father_name: "",
    primary_phone: "",
    primary_email: "",
    mother_name: "",
    secondary_phone: "",
    secondary_email: "",
  });

  const validateForm = () => {
    let errors = {};

    if (user.first_name === "") {
      errors.first_name = "Please Enter Name";
    }
    if (user.gender === "") {
      errors.gender = "Please Enter Gender";
    }
    if (user.dob === "") {
      errors.dob = "Please Enter Date of Birth";
    }
    if (user.programs_id === "") {
      errors.programs_id = "Please Enter Programs";
    }
    if (!user.profile_image) {
      errors.profile_image = "Please Upload Profile Image";
    }
    if (user.joining_date === "") {
      errors.joining_date = "Please Enter Joining Date";
    }
    if (user.studentID === "") {
      errors.studentID = "Please Enter StudentID";
    }
    if (user.blood_group === "") {
      errors.blood_group = "Please Enter Blood Group";
    }
    if (user.address === "") {
      errors.address = "Please Enter Address";
    }
    if (user.father_name === "") {
      errors.father_name = "Please Enter Father Name";
    }
    if (
      user.primary_phone === "" ||
      !validator.isMobilePhone(user.primary_phone.trim()) ||
      user.primary_phone.trim().length !== 10
    ) {
      errors.primary_phone = "Please Enter Valid Mobile Number";
    }
    if (user.primary_email === "" || !validator.isEmail(user.primary_email)) {
      errors.primary_email = "Please Enter Valid Email";
    }
    if (user.mother_name === "") {
      errors.mother_name = "Please Enter Mother Name";
    }
    if (
      user.secondary_phone === "" ||
      !validator.isMobilePhone(user.secondary_phone.trim()) ||
      user.secondary_phone.trim().length !== 10
    ) {
      errors.secondary_phone = "Please Enter Valid Mobile Number";
    }
    if (
      user.secondary_email === "" ||
      !validator.isEmail(user.secondary_email)
    ) {
      errors.secondary_email = "Please Enter Valid Email";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleTabSelect = (event) => {
    document.querySelectorAll(".select_field_point").forEach((element) => {
      element.style.backgroundColor = "";
    });
    event.currentTarget.style.backgroundColor = "#0000000A";
  };

  return (
    <section>
      <link
        href="https://fonts.googleapis.com/css2?family=Aldrich&family=Inter:wght@100..900&family=Lato:ital,wght@0,300;0,400;0,700;1,300&family=Roboto:ital,wght@0,100;1,100;1,300;1,400&display=swap"
        rel="stylesheet"
      />
      {/* <link href="https://cdnjs.cloudflare.com/ajax/libs/flowbite/2.3.0/flowbite.min.css" rel="stylesheet" /> */}
      <div className="outer-container student_input input_error_bor">       
        <div id="left-panel" className="sp-left-panel">
          <div className="sp-top-band">
            <a className="sp-logo">
              Scool Panda<sup>Beta</sup>
            </a>
            <div className="sp-search-top flexc search_input_">
              <i className="icon-Search" />
              <input
                id="search"
                className="sp-search-btn"
                placeholder="Quick search..."
              />
            </div>
          </div>
          <div className="sp-middle-band">
            <div className="sp-nav y-auto">
              <div className="">
                <h4 className="sp-title">Quick Actions</h4>
                <ul>
                  <li
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                  >
                    <span>
                      <i className="icon-Dashboard-Outline" />
                      Dashboard
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/activity");
                    }}
                  >
                    <span>
                      <i className="icon-Activity-Outline" />
                      Activity
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/calendar");
                    }}
                  >
                    <span>
                      <i className="icon-Timetable-Outline" />
                      Calendar
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/staff_list");
                    }}
                  >
                    <span>
                      <i className="icon-Staff-Outline" />
                      Staff
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Message-Outline" />
                      Messages
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Fees-Outline" />
                      Fees Management
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Enquiries-Outline" />
                      Enquiries
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Invites-Outline" />
                      Invites
                    </span>
                  </li>
                </ul>
                <h4 className="sp-title flexc justifySp">
                  Programs{" "}
                  <span id="add-programs" className="sp-plus">
                    +
                  </span>
                </h4>
                <ul>
                  {programsLoading &&
                    programsData &&
                    programsData.length > 0 ? (
                    programsData.map((data, index) => {
                      return (
                        <li
                          className={
                            data.id ===parseInt(localStorage.getItem("programId"))? "selected": ""
                          }
                          onClick={() => {
                            localStorage.setItem("programId", data.id);
                            localStorage.setItem(
                              "programName",
                              data.program_name
                            );
                            setProgramName(data.program_name);
                            fetchStudentData(data.id);
                          }}
                        >
                          <span>
                            <i className="icon-Program-Outline-1" />
                            {data.program_name}
                          </span>
                        </li>
                      );
                    })
                  ) : (
                    <Loader type="spinner-cub" bgColor="#4D6EE3" size={30} />
                  )}
                </ul>
              </div>
            </div>
            <div className="sp-bottom sp-nav">
              <ul>
                <li>
                  <span>
                    <i className="icon-Import-Outline" />
                    Notifications
                  </span>
                </li>
                <li>
                  <span>
                    <i className="icon-Invites-Outline" />
                    Upgrade Pro
                  </span>
                </li>
                <li onClick={() => navigate("/signout")}>
                  <span>
                    <i className="icon-Invites-Outline" />
                    Log Out
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="sp-right-panel">
          <div className="sp-top-bar flexc justifySp">
            <div className="ft-wt600 flexc">
              <span className="selected-icon">
                <i className="icon-Program-Outline-1" />
              </span>
              {localStorage.getItem("programName")}
              <i className="icon-More-Icon" />
            </div>
            {/* <div className="file-upload">
                            <label htmlFor="file-input" className="file-upload-label">
                            Choose File
                            </label>
                            <input type="file" id="file-input" className="file-input" />
                        </div> */}
            <div className="flexc">
              <div className="file-input-container">
                <input
                  //   type="button"
                  //   id="fileInput"
                  style={{ display: "none" }}
                // accept="image/*"
                />
                <label
                  className="sp-view-btn ft-wt500 flexc"
                  //   htmlFor="fileInput"
                  onClick={() => {
                    importStudents1();
                  }}
                >
                  <i className="icon-Import-Outline" />
                  Import
                </label>
              </div>
              <button
                className="createN ft-wt500 mr-lf10"
                id="newStudentCreate"
              >
                + New Student
              </button>
            </div>
          </div>
          <div className="sp-header">
            <nav className="flexc">
              <div className="sp-list-view flexc selected">
                <i className="icon-Students-Outline" />
                Student List
              </div>
              <div
                className="sp-list-view flexc"
                onClick={() => {
                  navigate("/student_attendance");
                }}
              >
                <i className="icon-Attendance-Outline" />
                Attendance
              </div>
              <div
                className="sp-list-view flexc"
                onClick={() => {
                  navigate("/food");
                }}
              >
                <i className="icon-Food" />
                Food
              </div>
              <div
                className="sp-list-view flexc"
                onClick={() => {
                  navigate("/sleep");
                }}
              >
                <i className="icon-Sleep" />
                Sleep
              </div>
              <div
                className="sp-list-view flexc"
                onClick={() => {
                  navigate("/student_timetable");
                }}
              >
                <i className="icon-Calendar-Outline" />
                Timetable
              </div>
            </nav>
            <div className="sp-menu-bar flexc justifySp">
              <div className="sp-menugroup">
                <div className="flexc">
                  <div className="btn-dropdown">
                    <span className="flexc">
                      Active Students
                      <i className="icon-Down-Arrow" />
                    </span>
                  </div>
                  <div className="btn-search-bar flexc">
                    <i className="icon-Search" />
                    <input
                      type="text"
                      placeholder="Search Students"
                      value={filterValue}
                      onChange={handleInputChange}
                    />
                    {/* <input placeholder="Search Students" /> */}
                  </div>
                </div>
              </div>
              <div className="sp-menu hidden">
                <div className="flexc"></div>
              </div>
              <div className="flexc sp-menugroup">
                <div className="sp-menubar-clslist flexc">
                  <span className="ft-wt500 ft-14">Class Teachers</span>
                  <ul className="flexc">
                    <li>
                      <img src={Avatar1} width={40} alt="panda" />
                    </li>
                    <li>
                      <img src={Avatar2} width={40} alt="panda" />
                    </li>
                    <li>
                      <img src={Avatar3} width={40} alt="panda" />
                    </li>
                  </ul>
                  <span className="sp-plus">+</span>
                </div>
              </div>
            </div>
          </div>

          <div className="load-container">
            <div className="sp-table-load overxy-auto">
              <div className="sp-table-container">
                {!studentData || studentData.length === 0 ? (
                  <ImportComponent
                    importStudent={"Student"}
                    handleImportStatus={handleImportStatus}
                  />
                ) : importstatus ? (
                  <ImportComponent
                    importStudent={"Student"}
                    handleImportStatus={handleImportStatus}
                  />
                ) : (
                  <>
                    <div className="sp-table-header flexc sp-table-header">
                      <div className="table_input_sty">
                        <input type="checkbox" id="check-all" />
                      </div>
                      <div className="sp-title flex-2">
                        <span>Student name</span>
                      </div>
                      <div className="sp-title flex-2">
                        <span>Father Name</span>
                      </div>
                      <div className="sp-title flex-2">
                        <span>Mother Name</span>
                      </div>
                      <div className="sp-title flex-2">
                        <span>Father Contact Number</span>
                      </div>
                      <div className="sp-title flex-2">
                        <span> Mother Contact Number</span>
                      </div>
                      <div className="sp-title flex-2">Parents Email</div>
                    </div>
                    <div className="sp-table-body " >
                      {loading && studentData && studentData.length > 0 ? (
                        studentData.map((student) => (
                          <div
                            className="select_field_point"
                            style={{ cursor: "pointer" }}
                            onClick={(event) => handleTabSelect(event)}
                          >
                            <div className="row flexc">
                              <div className="table_input_sty">
                                <input type="checkbox" className="sub_select" />
                              </div>
                              <div
                                className="sp-td-content flex-2"
                                onClick={() => {
                                  openStudentPopup(student);
                                }}
                              >
                                <span className="ft-500 flexc">
                                  <img
                                    src={
                                      student?.profile_image !== null
                                        ? baseUrl +
                                        "/api/" +
                                        student?.profile_image
                                        : Avatar1
                                    }
                                    width={40}
                                    alt={student?.first_name}
                                  />
                                  <span className="mr-lf10 truncate">
                                    {student?.first_name} {student?.last_name}
                                  </span>
                                </span>
                              </div>
                              <div
                                className="sp-td-content flex-2"
                                onClick={() => {
                                  openStudentPopup(student);
                                }}
                              >
                                <span className="ft-400 truncate">
                                  {student?.father_name}
                                </span>
                              </div>
                              <div
                                className="sp-td-content flex-2"
                                onClick={() => {
                                  openStudentPopup(student);
                                }}
                              >
                                <span className="ft-400 truncate">
                                  {student?.mother_name}
                                </span>
                              </div>
                              <div
                                className="sp-td-content flex-2"
                                onClick={() => {
                                  openStudentPopup(student);
                                }}
                              >
                                <span className="ft-400">
                                  {student?.primary_phone}
                                </span>
                              </div>
                              <div
                                className="sp-td-content flex-2"
                                onClick={() => {
                                  openStudentPopup(student);
                                }}
                              >
                                <span className="ft-400">
                                  {student?.secondary_phone}
                                </span>
                              </div>
                              <div
                                className="sp-td-content flex-3"
                                onClick={() => {
                                  openStudentPopup(student);
                                }}
                              >
                                <span className="ft-400 text-ellp">
                                  <span className="sp-tag-selected">
                                    {student?.primary_email}
                                  </span>
                                  <span className="sp-tag-selected mr-lf10">
                                    {student?.secondary_email}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : studentData.length === 0 ? (
                        <div className="sp-title flex-3">Data Not Found</div>
                      ) : (
                        <Loader
                          type="spinner-cub"
                          bgColor="#4D6EE3"
                          color="#4D6EE3"
                          title="Loading..."
                          size={50}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Create NewStudent */}
        <div className="popup-modal-container" onSubmit={onSubmit}>
          <div className="popup-wrap">
            {studentId !== null ? (
              <h2 className="ft-wt700">Update Student</h2>
            ) : (
              <h2 className="ft-wt700">Create New Student</h2>
            )}
            {/* {error &&
                            // <Alert variant="danger" dismissible>
                            <Alert variant="danger">
                                <div className="alert-danger">{error}</div>
                            </Alert>
                        } */}
            <div className="sp-pop-header">
              <h3 className="ft-wt600">Student Details</h3>
              <div className="form-section-default flexc justifySp fwrap">
                <div className="field-label half-field">
                  <label>Name</label>
                  <div className="field-value-holder">
                    <input
                      style={{
                        borderColor: validationErrors.first_name ? "red" : "",
                      }}
                      ref={firstNameInputRef}
                      type="text"
                      value={user.first_name}
                      onChange={(ev) =>
                        setUser({ ...user, first_name: ev.target.value })
                      }
                      onKeyPress={(event) =>
                        handleKeyPress(event, genderInputRef)
                      }
                      className="sp-field-input"
                      placeholder="Enter full name"
                    />
                  </div>
                  {validationErrors.first_name && (
                    <span className="error">{validationErrors.first_name}</span>
                  )}
                </div>
                <div className="field-label half-field">
                  <label>Gender</label>
                  <div className="field-value-holder">
                    <select
                      style={{
                        borderColor: validationErrors.gender ? "red" : "",
                      }}
                      ref={genderInputRef}
                      type="text"
                      value={user.gender}
                      onChange={(ev) =>
                        setUser({ ...user, gender: ev.target.value })
                      }
                      onKeyPress={(event) => handleKeyPress(event, dobInputRef)}
                      className="sp-field-select sp-field-input"
                    >
                      <option key={"no"} value={"no"}>
                        Select Gender
                      </option>
                      {gender1.map((gender) => (
                        <option key={gender["value"]} value={gender["value"]}>
                          {gender["value"]}
                        </option>
                      ))}
                    </select>
                  </div>
                  {validationErrors.gender && (
                    <span className="error">{validationErrors.gender}</span>
                  )}
                </div>
                <div className="field-label half-field ">
                  <label>Date of Birth</label>
                  <div className="field-value-holder ">
                    <input
                      style={{ borderColor: validationErrors.dob ? "red" : "" }}
                      ref={dobInputRef}
                      type="date"
                      value={user.dob ? user.dob.split("T")[0] : ""} // Extracting only the date portion
                      onChange={(ev) =>
                        setUser({ ...user, dob: ev.target.value })
                      }
                      onKeyPress={(event) =>
                        handleKeyPress(event, programInputRef)
                      }
                      className="sp-field-input"
                      placeholder="DD-MM-YYYY"
                    />
                  </div>
                  {validationErrors.dob && (
                    <span className="error">{validationErrors.dob}</span>
                  )}
                </div>
                <div className="field-label half-field">
                  <label>Programs</label>
                  <div
                    ref={programInputRef} // Assign the ref here
                    className="field-value-holder dropbutton"
                    style={{
                      width: "100%",
                      borderColor: validationErrors.programs_id ? "red" : "",
                    }}
                    onKeyPress={handleDropdownKeyPress}
                  >
                    <Dropdown
                      label={
                        <span
                          style={{ color: "#707070", fontSize: "0.8825em" }}
                        >
                          {selectedProgram
                            ? selectedProgram.program_name
                            : "Select a Program"}
                        </span>
                      }
                      style={{ width: "100%" }}
                    >
                      {programs.map((item) => (
                        <Dropdown.Item
                          className={
                            selectedProgram && selectedProgram.id === item.id
                              ? "dd_select"
                              : null
                          }
                          icon={
                            selectedProgram && selectedProgram.id === item.id
                              ? HiCheck
                              : null
                          }
                          onClick={() =>
                            setUser({ ...user, programs_id: parseInt(item.id) })
                          }
                          key={item.id}
                        >
                          {item.program_name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown>
                  </div>
                  {validationErrors.programs_id && (
                    <span className="error">
                      {validationErrors.programs_id}
                    </span>
                  )}
                </div>
                <div className="field-label half-field">
                  <label>Student Photo</label>
                  <div className="field-value-holder">
                    <span className="sp-upload-btn flexc justifySp">
                      {selectedImage === null ? (
                        "Upload"
                      ) : (
                        <p> {selectedImage.name}</p>
                      )}
                      <button className="sp-file-browse">Browse</button>
                    </span>

                    <input
                      style={{
                        borderColor: selectedImage === null ? "red" : "",
                      }}
                      ref={imageInputRef}
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      onKeyPress={(event) =>
                        handleKeyPress(event, joingInputRef)
                      }
                    />
                  </div>
                  {validationErrors.profile_image && (
                    <span className="error">
                      {validationErrors.profile_image}
                    </span>
                  )}
                </div>
                <div className="field-label half-field">
                  <label>Joining Date</label>
                  <div className="field-value-holder">
                    {/* <input type="date"  value={user.joining_date} onChange={ev => setUser({ ...user, joining_date: ev.target.value })}
                                            className="sp-field-input" placeholder="DD-MM-YYYY"
                                        /> */}
                    <input
                      style={{
                        borderColor: validationErrors.joining_date ? "red" : "",
                      }}
                      ref={joingInputRef}
                      type="date"
                      value={
                        user.joining_date ? user.joining_date.split("T")[0] : ""
                      } // Extracting only the date portion
                      onChange={(ev) =>
                        setUser({ ...user, joining_date: ev.target.value })
                      }
                      onKeyPress={(event) =>
                        handleKeyPress(event, studentInputRef)
                      }
                      className="sp-field-input"
                      placeholder="DD-MM-YYYY"
                    />
                  </div>
                  {validationErrors.joining_date && (
                    <span className="error">
                      {validationErrors.joining_date}
                    </span>
                  )}
                </div>
                <div className="field-label half-field">
                  <label>Student ID</label>
                  <div className="field-value-holder">
                    <input
                      style={{
                        borderColor: validationErrors.studentID ? "red" : "",
                      }}
                      ref={studentInputRef}
                      type="text"
                      value={user.studentID}
                      onChange={(ev) =>
                        setUser({ ...user, studentID: ev.target.value })
                      }
                      onKeyPress={(event) =>
                        handleKeyPress(event, bloodInputRef)
                      }
                      className="sp-field-input"
                      placeholder="001"
                    />
                  </div>
                  {validationErrors.studentID && (
                    <span className="error">{validationErrors.studentID}</span>
                  )}
                </div>
                <div className="field-label half-field">
                  <label>Blood Group</label>
                  <div className="field-value-holder">
                    <select
                      style={{
                        borderColor: validationErrors.blood_group ? "red" : "",
                      }}
                      ref={bloodInputRef}
                      type="text"
                      value={user.blood_group}
                      onChange={(ev) =>
                        setUser({ ...user, blood_group: ev.target.value })
                      }
                      onKeyPress={(event) =>
                        handleKeyPress(event, adressInputRef)
                      }
                      className="sp-field-select sp-field-input"
                    >
                      <option key={"no"} value={"no"}>
                        Select Blood Group
                      </option>
                      {Blood1.map((blood_group) => (
                        <option
                          key={blood_group["value"]}
                          value={blood_group["value"]}
                        >
                          {blood_group["value"]}
                        </option>
                      ))}
                    </select>
                  </div>
                  {validationErrors.blood_group && (
                    <span className="error">
                      {validationErrors.blood_group}
                    </span>
                  )}
                </div>
                <div className="field-label full-field">
                  <label>Address</label>
                  <div className="field-value-holder">
                    <textarea
                      style={{
                        borderColor: validationErrors.address ? "red" : "",
                      }}
                      ref={adressInputRef}
                      value={user.address}
                      onChange={(ev) =>
                        setUser({ ...user, address: ev.target.value })
                      }
                      className="sp-field-input"
                      placeholder="Address"
                      defaultValue={""}
                      onKeyPress={(event) =>
                        handleKeyPress(event, fathernameInputRef)
                      }
                    />
                  </div>
                  {validationErrors.address && (
                    <span className="error">{validationErrors.address}</span>
                  )}
                </div>
              </div>
              <h3 className="ft-wt600 mt-30">Father Details</h3>
              <div className="form-section-default flexc justifySp fwrap">
                <div className="field-label half-field">
                  <label>Name</label>
                  <div className="field-value-holder">
                    <input
                      style={{
                        borderColor: validationErrors.father_name ? "red" : "",
                      }}
                      ref={fathernameInputRef}
                      type="text"
                      value={user.father_name}
                      onChange={(ev) =>
                        setUser({ ...user, father_name: ev.target.value })
                      }
                      className="sp-field-input"
                      placeholder="Enter Full name"
                      onKeyPress={(event) =>
                        handleKeyPress(event, fatherphoneInputRef)
                      }
                    />
                  </div>
                  {validationErrors.father_name && (
                    <span className="error">
                      {validationErrors.father_name}
                    </span>
                  )}
                </div>
                <div className="field-label half-field">
                  <label>Phone Number</label>
                  <div className="field-value-holder">
                    <input
                      style={{
                        borderColor: validationErrors.primary_phone
                          ? "red"
                          : "",
                      }}
                      ref={fatherphoneInputRef}
                      type="text"
                      value={user.primary_phone}
                      onChange={(ev) =>
                        setUser({ ...user, primary_phone: ev.target.value })
                      }
                      className="sp-field-input"
                      placeholder="Phone Number"
                      onKeyPress={(event) =>
                        handleKeyPress(event, fatheremailInputRef)
                      }
                    />
                  </div>
                  {validationErrors.primary_phone && (
                    <span className="error">
                      {validationErrors.primary_phone}
                    </span>
                  )}
                </div>
                <div className="field-label half-field">
                  <label>Email</label>
                  <div className="field-value-holder">
                    <input
                      style={{
                        borderColor: validationErrors.primary_email
                          ? "red"
                          : "",
                      }}
                      ref={fatheremailInputRef}
                      type="email"
                      value={user.primary_email}
                      onChange={(ev) =>
                        setUser({ ...user, primary_email: ev.target.value })
                      }
                      onKeyPress={(event) =>
                        handleKeyPress(event, mathernameInputRef)
                      }
                      className="sp-field-input"
                      placeholder="Enter your email"
                    />
                  </div>
                  {validationErrors.primary_email && (
                    <span className="error">
                      {validationErrors.primary_email}
                    </span>
                  )}
                </div>
              </div>
              <h3 className="ft-wt600 mt-30">Mother Details</h3>
              <div className="form-section-default flexc justifySp fwrap">
                <div className="field-label half-field">
                  <label>Name</label>
                  <div className="field-value-holder">
                    <input
                      style={{
                        borderColor: validationErrors.mother_name ? "red" : "",
                      }}
                      ref={mathernameInputRef}
                      type="text"
                      value={user.mother_name}
                      onChange={(ev) =>
                        setUser({ ...user, mother_name: ev.target.value })
                      }
                      onKeyPress={(event) =>
                        handleKeyPress(event, matherphoneInputRef)
                      }
                      className="sp-field-input"
                      placeholder="Enter Full name"
                    />
                  </div>
                  {validationErrors.mother_name && (
                    <span className="error">
                      {validationErrors.mother_name}
                    </span>
                  )}
                </div>
                <div className="field-label half-field">
                  <label>Phone Number</label>
                  <div className="field-value-holder">
                    <input
                      style={{
                        borderColor: validationErrors.secondary_phone
                          ? "red"
                          : "",
                      }}
                      ref={matherphoneInputRef}
                      type="text"
                      value={user.secondary_phone}
                      onChange={(ev) =>
                        setUser({ ...user, secondary_phone: ev.target.value })
                      }
                      onKeyPress={(event) =>
                        handleKeyPress(event, matheremailInputRef)
                      }
                      className="sp-field-input"
                      placeholder="Phone Number"
                    />
                  </div>
                  {validationErrors.secondary_phone && (
                    <span className="error">
                      {validationErrors.secondary_phone}
                    </span>
                  )}
                </div>
                <div className="field-label half-field">
                  <label>Email</label>
                  <div className="field-value-holder">
                    <input
                      style={{
                        borderColor: validationErrors.secondary_email
                          ? "red"
                          : "",
                      }}
                      ref={matheremailInputRef}
                      type="email"
                      value={user.secondary_email}
                      onChange={(ev) =>
                        setUser({ ...user, secondary_email: ev.target.value })
                      }
                      className="sp-field-input"
                      placeholder="Enter your email"
                      onKeyDown={(event) =>
                        handleKeyPress(event, submitButtonRef)
                      }
                    />
                  </div>
                  {validationErrors.secondary_email && (
                    <span className="error">
                      {validationErrors.secondary_email}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="sp-bottom-sticky">
              {studentId === null ? (
                <button
                  className="sp-btn-create"
                  ref={submitButtonRef}
                  onClick={onSubmit}
                >
                  Create
                </button>
              ) : (
                <button className="sp-btn-create" onClick={updateUser}>
                  Update
                </button>
              )}
              <button className="sp-btn-cancel">Cancel</button>
            </div>
          </div>
        </div>
        <div className="popup-student-details">
          <span className="close icon-Dropdown_Close flexc cp" />
          <div className="student-details-page">
            <div className="sp-top-details flexc">
              <div className="sp-profile-head">
                <img
                  src={
                    displayUser.profile_image !== null
                      ? baseUrl + "/api/" + displayUser.profile_image
                      : Avatarprofile
                  }
                  alt={displayUser.first_name}
                  width={62}
                  height={62}
                />
                <span className="flexc margin_20p">
                  <em className="ft-wt600 ft-18">{displayUser.first_name}</em>
                  <label className="sp-status">check In</label>
                </span>
              </div>
              <div className="sp-profile-rt flexc">
                {/* <span className="sp-accbox icon-Message-Outline flexc" />{" "} */}
                {/* <span className="sp-accbox mr-lf10 icon-Message-Outline flexc"/> */}
                <span
                  className="sp-accbox mr-lf10 icon-Message-Outline flexc"
                  onClick={() => {
                    openStudentUpdatePopup(displayUser);
                  }}
                />
              </div>
            </div>
            <div className="sp-details-box">
              <div className="sp-tt ft-14 flexc ft-wt600">
                <i className="icon-Invites-Outline" />
                Student Details
              </div>
              <div className="form-section-default flexc justifySp fwrap">
                <div className="field-label half-field">
                  <label className="label-title">Gender</label>
                  <div className="label-filled">{displayUser.gender}</div>
                </div>
                <div className="field-label half-field">
                  <label className="label-title">Student ID</label>
                  <div className="label-filled">{displayUser.studentID}</div>
                </div>
                <div className="field-label half-field">
                  <label className="label-title">Admission Date</label>
                  <div className="label-filled">
                    {String(displayUser.joining_date).split("T")[0]}
                  </div>
                </div>
                <div className="field-label half-field">
                  <label className="label-title">Date of Birth</label>
                  <div className="label-filled">
                    {String(displayUser.dob).split("T")[0]}
                  </div>
                </div>
                <div className="field-label half-field">
                  <label className="label-title">Blood Group</label>
                  <div className="label-filled">{displayUser.blood_group}</div>
                </div>
                <div className="field-label full-field">
                  <label className="label-title">Address</label>
                  <div className="label-filled">{displayUser.address}</div>
                </div>
              </div>
            </div>
            <div className="sp-details-box">
              <div className="sp-tt ft-14 flexc ft-wt600">
                <i className="icon-Invites-Outline" />
                Contact Detail
              </div>
              <div className="form-section-default flexc justifySp fwrap">
                <div className="field-label full-field">
                  <label className="label-title">
                    <b>Father Details :</b>
                  </label>
                  <div className="form-section-default flexc justifySp fwrap">
                    <div className="field-label half-field">
                      <label className="label-title">Father Name</label>
                      <div className="label-filled">
                        {displayUser.father_name}
                      </div>
                    </div>
                    <div className="field-label half-field">
                      <label className="label-title">Father Number</label>
                      <div className="ft-500 ft-14 label-filled">
                        {displayUser.primary_phone}
                      </div>
                    </div>
                    <div className="field-label half-field">
                      <label className="label-title">Father Email</label>
                      <div className="ft-400 ft-13 dis-block margin_top10p label-filled">
                        {displayUser.primary_email}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field-label full-field">
                  <label className="label-title">
                    <b>Mother Details :</b>
                  </label>
                  <div className="form-section-default flexc justifySp fwrap">
                    <div className="field-label half-field">
                      <label className="label-title">Mother Name</label>
                      <div className="label-filled">
                        {displayUser.mother_name}
                      </div>
                    </div>
                    <div className="field-label half-field">
                      <label className="label-title">Mother Number</label>
                      <div className="ft-500 ft-14 label-filled">
                        {displayUser.secondary_phone}
                      </div>
                    </div>
                    <div className="field-label half-field">
                      <label className="label-title">Mother Email</label>
                      <div className="ft-400 ft-13 dis-block margin_top10p label-filled">
                        {displayUser.secondary_email}
                      </div>
                    </div>
                  </div>
                  {/* <label className="label-title">Mother</label> */}
                </div>
              </div>
            </div>
            <div className="sp-details-box">
              <div className="sp-tt ft-14 flexc ft-wt600">
                <i className="icon-Invites-Outline" />
                Attendance Detail
              </div>
              <div className="sp-dashboard">
                <img src={Avatardata} width={100} alt="panda" />
              </div>
              <div className="field-label-op">
                <div className="flexc justifySp  margin_20p">
                  <span className="flexc ft-13">
                    <span className="sp-lpdr icon-sp-present mr-rt10" />
                    Present
                  </span>
                  <span className="ft-13">130 Days</span>
                </div>
                <div className="flexc justifySp  margin_20p">
                  <span className="flexc ft-13">
                    <span className="cross-ele mr-rt10" />
                    Leave
                  </span>
                  <span className="ft-13">20 Days</span>
                </div>
                <div className="flexc justifySp  margin_20p">
                  <span className="flexc ft-13">
                    <span className="notapp-ele mr-rt10" />
                    Leave
                  </span>
                  <span className="ft-13">20 Days</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
