import React, { useState, useEffect } from "react";
import axios from 'axios';
import $ from "jquery";
import { useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from "../../common/common";
import Loader from "react-js-loader";
import '../../common/components/css/style.css';
import avatar1 from "./images/avatar1.png";
import { Dropdown } from 'flowbite-react';
import "../../index.css";
import { HiCheck } from "react-icons/hi";

export default function Sleep() {

    const navigate = useNavigate();

    const [programsData, setProgramsData] = useState({});
    const [programsLoading, setProgramsLoading] = useState(false);

    const fetchProgramsData = async () => {
        setProgramsLoading(false);
        try {
            const postData = {
                school_id: localStorage.getItem('schoolId')
                // school_id:24
            }
            const { data } = await axios.post(baseUrl + "/api/get_programs", postData);
            setProgramsData(data.data);
            setProgramsLoading(true);
        } catch (error) {
            console.log(error)
        }
    }

    const [sleepTime, setSleepTime] = useState("");
    const [sleepTimeData, setSleepTimeData] = useState([]);
    const [sleepTimeLoading, setSleepTimeLoading] = useState(false);
    const selectedSleepTime = sleepTimeData.find(data => data.id === sleepTime);
    const fetchSleepTimeData = async () => {
        setSleepTimeLoading(false);
        axios({
            method: "post",
            url: baseUrl + "/api/get_sleep_time",
            // data:{},
            headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setSleepTimeData(response.data.data);
                console.log(response);
                setSleepTimeLoading(true);
            }
        });
    }
    // const [checkedState, setCheckedState] = useState(
    //     new Array(sleepTimeData.length).fill(false)
    // );
    // const [checkedIds, setCheckedIds] = useState([]);
    // const [dropdownOpen, setDropdownOpen] = useState(false);

    // const handleCheckboxChange = (index) => {
    //     const updatedCheckedState = [...checkedState];
    //     updatedCheckedState[index] = !updatedCheckedState[index];
    //     setCheckedState(updatedCheckedState);

    //     const id = sleepTimeData[index].id;
    //     if (updatedCheckedState[index]) {
    //         setCheckedIds((prevCheckedIds) => [...prevCheckedIds, id]);
    //     } else {
    //         setCheckedIds((prevCheckedIds) =>
    //             prevCheckedIds.filter((checkedId) => checkedId !== id)
    //         );
    //     }
    // };

    // const toggleDropdown = () => {
    //     setDropdownOpen(!dropdownOpen);
    // };

    const [foodData, setFoodData] = useState({});
    const [dataHeader, setDataHeader] = useState([]);
    const [foodHeaderdata, setFoodHeaderData] = useState([]);
    const [foodLoading, setFoodLoading] = useState(false);

    const [filterValue, setFilterValue] = useState('');
    const handleInputChange = async (event) => {
        setFilterValue(event.target.value);
        try {
            axios({
                method: "post",
                url: baseUrl + "/api/student_list_admin",
                data: {
                    school_id: localStorage.getItem('schoolId'),
                    programs_id: localStorage.getItem('programId'),
                    search: event.target.value.trim(),
                    list_status: 2
                },
            }).then((response) => {
                console.log(response);
                if (response['data']['status'] === true) {
                    setFoodData(response.data);
                    setDataHeader(response.data.dateresult);
                    setFoodHeaderData(response.data.data);
                    setFoodLoading(true);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    const fetchSleepData = () => {
        setFoodLoading(false);
        try {
            axios({
                method: "post",
                url: baseUrl + "/api/student_list_admin",
                data: {
                    school_id: localStorage.getItem('schoolId'),
                    programs_id: localStorage.getItem('programId'),
                    search: "",
                    list_status: 2
                },
            }).then((response) => {
                console.log(response);
                if (response['data']['status'] === true) {
                    setFoodData(response.data);
                    setDataHeader(response.data.dateresult);
                    setFoodHeaderData(response.data.data);
                    setFoodLoading(true);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    const [sleepTimeError, setSleepTimeError] = useState(null);
    const [foodId, setFoodId] = useState(null);
    const [studentId, setStudentId] = useState("");
    const [foodDate, setFoodDate] = useState("");
    const openAddFoodPopup = (foodData) => {
        setStudentId(foodData.student_id)
        setFoodDate(foodData.formattedDate)
        console.log(foodData)
        const popup = $(".dialog-model");
        $(".sp-add-food").click(function (event) { // Add event parameter here
            setFoodId(null);
            setSleepTime("");
            setSleepTimeError(null);
            event.stopPropagation();
            const clickedButton = $(this);
            $(".sp-add-food").addClass('selected');
            clickedButton.parent().addClass('selected');
            const buttonOffset = clickedButton.offset();
            const popupTop = buttonOffset.top + clickedButton.outerHeight() + 10;
            const popupLeft = buttonOffset.left + (clickedButton.outerWidth() / 2) - (popup.outerWidth() / 2) + 5;
            clickedButton.parent().append(popup);
            popup.css({
                display: "block"
            });
        });

        // $('.dialog-model').click(function(event) { // Add event parameter here
        //     event.stopPropagation();
        // });

        $(".dialog-model .cancel-btn").click(function (event) { // Add event parameter here
            event.stopPropagation();
            $('.outer-container').append(popup);
            $(".dialog-model").css('display', 'none');
            $(".sp-add-food").parent().removeClass('selected');
        });

        // $(".dialog-model .createN").click(function(event) { // Add event parameter here
        //     addFood(foodData.id);
        // });

        // $(document).click(function(event) { // Add event parameter here
        //     const clickedElement = $(event.target);
        //     if (!clickedElement.hasClass("dialog-model") && $(".dialog-model").css("display") === "block") {
        //         $(".dialog-model").css('display', 'none');
        //         $('.outer-container').append(popup);
        //         $(".sp-add-food").parent().removeClass('selected');
        //     }
        // });
    }

    const openUpdateFoodPopup = (foodData) => {
        setFoodId(foodData.id)
        setStudentId(foodData.student_id)
        setFoodDate(foodData.formattedDate)
        console.log(foodData)
        setSleepTime(foodData.sleep_time_id)
        const popup = $(".dialog-model");
        $(".food_date").click(function (event) { // Add event parameter here
            setSleepTimeError(null);
            // event.stopPropagation();
            const clickedButton = $(this);
            $(".sp-add-food").addClass('selected');
            clickedButton.parent().addClass('selected');
            const buttonOffset = clickedButton.offset();
            const popupTop = buttonOffset.top + clickedButton.outerHeight() + 10;
            const popupLeft = buttonOffset.left + (clickedButton.outerWidth() / 2) - (popup.outerWidth() / 2) + 5;
            clickedButton.parent().append(popup);
            popup.css({
                display: "block"
            });
        });

        $(".dialog-model .cancel-btn").click(function (event) { // Add event parameter here
            event.stopPropagation();
            $('.outer-container').append(popup);
            $(".dialog-model").css('display', 'none');
            $(".sp-add-food").parent().removeClass('selected');
        });
    }

    const validateForm = () => {
        let valid = true;
        if (sleepTime === "") {
            setSleepTimeError("Please Select Sleep Time");
            valid = false;
        } else {
            setSleepTimeError(null);
        }
        return valid;
    };

    const addFood = () => {
        if (validateForm()) {
            const postData = {
                sleep_time_id: sleepTime,
                school_id: localStorage.getItem('schoolId'),
                staff_id: 5,
                sleep_date: foodDate,
                programs_id: localStorage.getItem('programId'),
                student_ids: [studentId]
            }
            console.log(postData)
            axios({
                method: "post",
                url: baseUrl + "/api/sleep_add_edit",
                data: postData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    window.location.reload();
                    // fetchFoodData(); 
                }
            });
        }

    }

    const updateFood = () => {
        const postData = {
            id: foodId,
            sleep_time_id: sleepTime,
            school_id: localStorage.getItem('schoolId'),
            staff_id: 5,
            sleep_date: foodDate,
            programs_id: localStorage.getItem('programId'),
            student_ids: [studentId]
        }
        console.log(postData)
        axios({
            method: "post",
            url: baseUrl + "/api/sleep_add_edit",
            data: postData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                fetchSleepData();
                // window.location.reload();
            }
        });
    }

    useEffect(() => {
        fetchProgramsData();
        fetchSleepData();
        fetchSleepTimeData();
        const popup = $(".dialog-model");
        $(".sp-add-food").click(function (event) { // Add event parameter here
            event.stopPropagation();
            const clickedButton = $(this);
            $(".sp-add-food").addClass('selected');
            clickedButton.parent().addClass('selected');
            const buttonOffset = clickedButton.offset();
            const popupTop = buttonOffset.top + clickedButton.outerHeight() + 10;
            const popupLeft = buttonOffset.left + (clickedButton.outerWidth() / 2) - (popup.outerWidth() / 2) + 5;
            clickedButton.parent().append(popup);
            popup.css({
                display: "block"
            });
        });
        $(".dialog-model .cancel-btn").click(function (event) { // Add event parameter here
            event.stopPropagation();
            $('.outer-container').append(popup);
            $(".dialog-model").css('display', 'none');
            $(".sp-add-food").parent().removeClass('selected');
        });

    }, []);

    return (
        <section>
            <div className="outer-container">
                <div id="left-panel" className="sp-left-panel">
                    <div className="sp-top-band">
                        <a className="sp-logo">
                            Scool Panda<sup>Beta</sup>
                        </a>
                        <div className="sp-search-top flexc">
                            <i className="icon-Search" />
                            <input
                                id="search"
                                className="sp-search-btn"
                                placeholder="Quick search..."
                            />
                        </div>
                    </div>
                    <div className="sp-middle-band">
                        <div className="sp-nav y-auto">
                            <div className="">
                                <h4 className="sp-title">Quick Actions</h4>
                                <ul>
                                    <li onClick={() => { navigate('/dashboard'); }}>
                                        <span>
                                            <i className="icon-Dashboard-Outline" />
                                            Dashboard
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/activity'); }}>
                                        <span>
                                            <i className="icon-Activity-Outline" />
                                            Activity
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/calendar'); }}>
                                        <span>
                                            <i className="icon-Timetable-Outline" />
                                            Calendar
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/staff_list'); }}>
                                        <span>
                                            <i className="icon-Staff-Outline" />
                                            Staff
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i className="icon-Message-Outline" />
                                            Messages
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i className="icon-Fees-Outline" />
                                            Fees Management
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i className="icon-Enquiries-Outline" />
                                            Enquiries
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i className="icon-Invites-Outline" />
                                            Invites
                                        </span>
                                    </li>
                                </ul>
                                <h4 className="sp-title flexc justifySp">
                                    Programs{" "}
                                    <span id="add-programs" className="sp-plus">
                                        +
                                    </span>
                                </h4>
                                <ul>
                                    {programsLoading && programsData && programsData.length > 0 ?
                                        programsData.map((data, index) => {
                                            return (
                                                <li className={data.id === parseInt(localStorage.getItem('programId')) ? "selected" : ""} onClick={() => {
                                                    localStorage.setItem('programId', data.id);
                                                    localStorage.setItem('programName', data.program_name);
                                                    fetchSleepData();
                                                    navigate('/sleep');
                                                }}>
                                                    <span>
                                                        <i className="icon-Program-Outline-1" />
                                                        {data.program_name}
                                                    </span>
                                                </li>
                                            );
                                        }) :
                                        <Loader type="spinner-cub" bgColor="#4D6EE3" size={30} />
                                    }

                                </ul>
                            </div>
                        </div>
                        <div className="sp-bottom sp-nav">
                            <ul>
                                <li>
                                    <span>
                                        <i className="icon-Import-Outline" />
                                        Notifications
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <i className="icon-Invites-Outline" />
                                        Upgrade Pro
                                    </span>
                                </li>
                                <li onClick={() => navigate("/signout")}>
                                    <span>
                                        <i className="icon-Invites-Outline" />
                                        Log Out
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="sp-right-panel">
                    <div className="sp-top-bar flexc justifySp">
                        <div className="ft-wt600 flexc">
                            <span className="selected-icon">
                                <i className="icon-Program-Outline-1" />
                            </span>
                            {localStorage.getItem('programName')}
                            <i className="icon-More-Icon" />
                        </div>
                        <div className="flexc">
                            <button className="sp-view-btn ft-wt500 flexc">
                                <i className="icon-Import-Outline" />
                                Import
                            </button>
                            <button className="createN ft-wt500 mr-lf10">+ New Student</button>
                        </div>
                    </div>
                    <div className="sp-header borderbt">
                        <nav className="flexc">
                            <div className="sp-list-view flexc" onClick={() => { navigate('/student_list'); }}>
                                <i className="icon-Students-Outline" />
                                Student List
                            </div>
                            <div className="sp-list-view flexc" onClick={() => { navigate('/student_attendance'); }}>
                                <i className="icon-Attendance-Outline" />
                                Attendance
                            </div>
                            <div className="sp-list-view flexc" onClick={() => { navigate('/food'); }}>
                                <i className="icon-Food" />
                                Food
                            </div>
                            <div className="sp-list-view flexc selected">
                                <i className="icon-Sleep" />
                                Sleep
                            </div>
                            <div className="sp-list-view flexc" onClick={() => { navigate('/student_timetable'); }}>
                                <i className="icon-Calendar-Outline" />
                                Timetable
                            </div>
                        </nav>
                        <div className="sp-menu-bar flexc justifySp">
                            <div className="sp-menugroup">
                                <div className="flexc">
                                    <div className="btn-search-bar flexc">
                                        <i className="icon-Search" />

                                        <input
                                            type="text"
                                            placeholder="Search Students"
                                            value={filterValue}
                                            onChange={handleInputChange}
                                        />                                    </div>
                                    <div className="week-detail flexc">
                                        <button className="set-bord left-arr">
                                            <span className="arrow" />
                                        </button>
                                        <button className="sel-days set-bord">
                                            Week
                                            <span className="arrow up-arr" />
                                        </button>
                                        <button className="set-bord right-arr">
                                            <span className="arrow" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="flexc sp-menugroup">
                                <div className="sp-menubar-clslist flexc">
                                    <span className="ft-wt500 ft-14">Class Teachers</span>
                                    <ul className="flexc">
                                        <li>
                                            <img src={avatar1} />
                                        </li>
                                        <li>
                                            <img src={avatar1} />
                                        </li>
                                        <li>
                                            <img src={avatar1} />
                                        </li>
                                    </ul>
                                    <span className="sp-plus">+</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {foodLoading && foodData ?
                            <div className="std-attence">
                                <div className="attence-top overxy-auto">
                                    <div className="students-add-list">
                                        <ul className="sp-head sp_sticky">
                                            <li>{foodData.student}</li>
                                            {dataHeader && dataHeader.length > 0 ?
                                                (dataHeader).map((data, index) => {
                                                    return (
                                                        <li>{data.date_data}</li>
                                                    );
                                                }) : <div></div>}
                                        </ul>
                                        {foodHeaderdata.map((student, studentIndex) => (
                                            <React.Fragment key={studentIndex}>
                                                <ul>
                                                    <li>
                                                        <span className="ft-500 flexc">
                                                            <img src={student.profile_image || avatar1} width={40} alt="Avatar" />
                                                            <span className="mr-lf10 ft-14 truncate">{student.name}</span>
                                                        </span>
                                                    </li>
                                                    {student.sleep.map((foodData, foodIndex) => (
                                                        <React.Fragment key={foodIndex}>
                                                            {foodData.sleep_value.length > 0 ? (
                                                                <li>
                                                                    <div className="" >
                                                                        {foodData.sleep_value.map((food, timeIndex) => (
                                                                            <div className="sp-tag-selected food_date mr-lf10 " key={timeIndex} style={{ cursor: "pointer" }} onClick={() => {
                                                                                openUpdateFoodPopup({
                                                                                    id: food.sleep_data.id,
                                                                                    student_id: student.id,
                                                                                    formattedDate: foodData.formattedDate,
                                                                                    sleep_time_id: food.sleep_data.sleep_time_id,
                                                                                });
                                                                            }}>
                                                                                {food.sleep_value}
                                                                            </div>
                                                                        ))}

                                                                    </div>
                                                                    {foodData.attendance_status === 1 &&
                                                                        <div className="sp-add-food cp" onClick={() => {
                                                                            openAddFoodPopup(
                                                                                {
                                                                                    student_id: student.id,
                                                                                    formattedDate: foodData.formattedDate
                                                                                }
                                                                            );
                                                                        }}>
                                                                            <i className="icon-plus-w" />
                                                                        </div>
                                                                    }
                                                                </li>
                                                            ) : (
                                                                <li>
                                                                    {foodData.attendance_status === 1 ? (
                                                                        <div className="sp-add-food cp" onClick={() => {
                                                                            openAddFoodPopup(
                                                                                {
                                                                                    student_id: student.id,
                                                                                    formattedDate: foodData.formattedDate
                                                                                }
                                                                            );
                                                                        }}>
                                                                            <i className="icon-plus-w" />
                                                                        </div>
                                                                    ) : foodData.attendance_status === 2 ? (
                                                                        <span className="notapp-ele" />
                                                                    ) : foodData.attendance_status > 2 ? (
                                                                        <span className="cross-ele" />
                                                                    ) : null}
                                                                </li>
                                                            )}
                                                        </React.Fragment>
                                                    ))}
                                                </ul>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </div> : foodHeaderdata.length === 0 ? <div className="sp-title flex-3">Data Not Found</div> : <Loader type="spinner-cub" bgColor="#4D6EE3" color="#4D6EE3" title="Loading..." size={50} />}
                    </div>

                </div>
                {/* my code */}
                <div className="dialog-model absolute w-min">
                    <div className="sp-tt ft-14 flexc ft-wt600">{foodId === null ? "Add" : "Update"} Sleep</div>
                    <div className="form-section-default flexc justifySp fwrap" style={foodId === null ? { display: 'flex', flexDirection: 'column', alignContent: 'flex-start' } : null}>
                        <div className="field-label half-field" style={foodId === null ? { width: 'auto' } : null}>
                            <label>Sleep Time</label>
                            <div className="field-value-holder  margin_top10p dropbutton" style={{ width: '100%' }}>
                                <Dropdown
                                    label={<span style={{ color: 'black', whiteSpace: 'nowrap' }}>{selectedSleepTime ? selectedSleepTime.sleep_time_name : "Select Time"}</span>}
                                    style={{ width: '100%' }}
                                >
                                    {sleepTimeData.map(item => (
                                        <Dropdown.Item
                                            className={selectedSleepTime && selectedSleepTime.id === item.id ? "dd_select" : null}
                                            icon={selectedSleepTime && selectedSleepTime.id === item.id ? HiCheck : null}
                                            onClick={() => setSleepTime(item.id)}
                                            key={item.id}
                                        >
                                            {item.sleep_time_name}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown>
                            </div>
                            {sleepTimeError && <div className="error">{sleepTimeError}</div>}
                        </div>
                        {/* <div className="field-label full-field">
                            {sleepTimeLoading ? (
                                <>
                                    <button
                                        id="dropdownSearchButton"
                                        onClick={toggleDropdown}
                                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                        type="button"
                                        style={{ width: '80%' }}
                                    >
                                        Food Time
                                    </button>
                                    {dropdownOpen && (
                                        <div
                                            id="dropdownSearch"
                                            className="z-10 bg-white rounded-lg shadow w-60 dark:bg-gray-700"
                                        >
                                            <ul
                                                className="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
                                                aria-labelledby="dropdownSearchButton"
                                            >
                                                {sleepTimeData.map((item, index) => (
                                                    <li key={item.id}>
                                                        <div className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                                            <input
                                                                id={`checkbox-item-${item.id}`}
                                                                type="checkbox"
                                                                checked={checkedState[index]}
                                                                onChange={() => handleCheckboxChange(index)}
                                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                                            />
                                                            <label
                                                                htmlFor={`checkbox-item-${item.id}`}
                                                                className="w-full ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                                                            >
                                                                {item.sleep_time_name}
                                                            </label>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <Loader type="spinner-cub" bgColor="#4D6EE3" size={30} />
                            )}
                        </div> */}
                        <div className="flexc mt-4">
                            <div className="flexc">
                                <button className="createN ft-wt500 mr-2" onClick={() => { foodId === null ? addFood() : updateFood() }}>{foodId === null ? "Save" : "Update"}</button>
                                <button className="sp-view-btn ft-wt500 flexc cancel-btn">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}