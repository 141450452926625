import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import Signup from "./pages/signup/Signup";
import DefaultLayout from "./common/layouts/Defaultlayout";
import School_info from "./pages/signup/School_info";
import Student_list from "./pages/school/Student_list";
import Staff_list from "./pages/school/Staff_list";
import Activity from "./pages/activity/Activity";
import Staffattendance from "./pages/staffattendance/Staffattendance";
import Dashboard from "./pages/dashboard/Dashboard";
import Signin from "./pages/signin/Signin";
import NewPassword from "./pages/signin/NewPassword";
import Recovery from "./pages/signin/Recovery";
import Signout from "./pages/signout/Signout";
import Forgotpassword from "./pages/signin/Forgotpassword";
import Sleep from "./pages/sleep/Sleep";
import Food from "./pages/food/Food";
import StudentAttendance from "./pages/schoolattendance/StudentAttendance";
import StudentTimetable from "./pages/studenttimetable/StudentTimetable";
import EventCalendar from "./pages/calendar/EventCalendar";
import Program from "./pages/program/Program";

const isLoggedIn = () => {
    const schoolId = localStorage.getItem('schoolId');
    return schoolId !== null && schoolId !== "";
};

const router = createBrowserRouter([
    {
        path: "",
        element: <DefaultLayout />,
        children: [
            {
                path: "/",
                element: isLoggedIn() ? <Navigate to="/student_list" /> : <Navigate to="/signin" />,
            },
            {
                path: "/signup",
                element: <Signup />,
            },
            {
                path: "/signin",
                element: isLoggedIn() ? <Navigate to="/student_list" /> : <Signin />,
            },
            {
                path: "/signout",
                element: <Signout />,
            },
            {
                path: "/forgot_password",
                element: <Forgotpassword />,
            },
            {
                path: "/new_password",
                element: <NewPassword />,
            },
            {
                path: "/recovery",
                element: <Recovery />,
            },
            {
                path: "/school_info",
                element:<School_info />,
            },
            {
                path: "/student_list",
                element:<Student_list />,
            },
            // {
            //     path: "/student_list",
            //     element: isLoggedIn() ? <Student_list /> : <Navigate to="/signin" />,
            // },
            {
                path: "/staff_list",
                element:<Staff_list />,
            },
            {
                path: "/activity",
                element:<Activity />,
            },
            {
                path: "/staff_attendance",
                element:<Staffattendance />,
            },
            {
                path: "/dashboard",
                element:<Dashboard />,
            },
            {
                path: "/sleep",
                element:<Sleep /> ,
            },
            {
                path: "/food",
                element:<Food />,
            },
            {
                path: "/student_attendance",
                element:<StudentAttendance />,
            },
            {
                path: "/student_timetable",
                element:<StudentTimetable />,
            },
            {
                path: "/calendar",
                element:<EventCalendar />,
            },
            {
                path: "/program",
                element:<Program /> ,
            },
        ],
    }
]);

export default router;
