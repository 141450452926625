import {useEffect} from "react";

export default function Signout() {

    useEffect(() => {
        //return ()=>{
            localStorage.clear();
            sessionStorage.clear();
            // localStorage.removeItem('schoolId');
            // localStorage.removeItem('programId');
            // localStorage.removeItem('programName');
            window.location.href = "/";
        //};    
    })
    
    return(
        <div>...Loading</div>
    );

}