import React, { useState, useEffect } from "react";
import axios from 'axios';
import $ from "jquery";
import { useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from "../../common/common";
import Loader from "react-js-loader";
import '../../common/components/css/style.css';
// import '../../common/components/js/school_info.js';
// import '../../common/components/js/common.js';
// import '../../common/components/js/jquery.min.js';
import avatar1 from "./images/avatar1.png";
import { Dropdown } from 'flowbite-react';
import "../../index.css";
import { HiCheck } from "react-icons/hi";

// import "./js/index.js";
// import {Alert} from 'react-bootstrap';

export default function Food() {

    const navigate = useNavigate();

    const [programsData, setProgramsData] = useState({});
    const [programsLoading, setProgramsLoading] = useState(false);

    const fetchProgramsData = async () => {
        setProgramsLoading(false);
        try {
            const postData = {
                school_id: localStorage.getItem('schoolId')
                // school_id:24
            }
            const { data } = await axios.post(baseUrl + "/api/get_programs", postData);
            setProgramsData(data.data);
            setProgramsLoading(true);
        } catch (error) {
            console.log(error)
        }
    }

    const [foodData, setFoodData] = useState({});
    const [dataHeader, setDataHeader] = useState([]);
    const [foodHeaderdata, setFoodHeaderData] = useState([]);
    const [foodLoading, setFoodLoading] = useState(false);

    const [filterValue, setFilterValue] = useState('');
    const handleInputChange = async (event) => {
        setFilterValue(event.target.value);
        try {
            axios({
                method: "post",
                url: baseUrl + "/api/student_list_admin",
                data: {
                    school_id: localStorage.getItem('schoolId'),
                    programs_id: localStorage.getItem('programId'),
                    search: event.target.value.trim(),
                    list_status: 3
                },
            }).then((response) => {
                console.log(response);
                if (response['data']['status'] === true) {
                    setFoodData(response.data);
                    setDataHeader(response.data.dateresult);
                    setFoodHeaderData(response.data.data);
                    setFoodLoading(true);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    const fetchFoodData = () => {
        setFoodLoading(false);
        try {
            axios({
                method: "post",
                url: baseUrl + "/api/student_list_admin",
                data: {
                    school_id: localStorage.getItem('schoolId'),
                    programs_id: localStorage.getItem('programId'),
                    search: "",
                    list_status: 3
                },
            }).then((response) => {
                console.log(response);
                if (response['data']['status'] === true) {
                    setFoodData(response.data);
                    setDataHeader(response.data.dateresult);
                    setFoodHeaderData(response.data.data);
                    setFoodLoading(true);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    const openAddFoodPopup = (foodData) => {
        setStudentId(foodData.student_id)
        setFoodDate(foodData.formattedDate)
        const popup = $(".dialog-model");
        $(".sp-add-food").click(function (event) { // Add event parameter here
            setFoodId(null);
            setFoodTimeError(null);
            setQuantityError(null);
            setFoodTypeError(null);
            setCategoryError(null);
            setDescriptionError(null);
            setFoodDetails({
                foodTime: '',
                quantity: '',
                foodType: '',
                category: '',
                description: ''
            })
            event.stopPropagation();
            const clickedButton = $(this);
            $(".sp-add-food").addClass('selected');
            clickedButton.parent().addClass('selected');
            const buttonOffset = clickedButton.offset();
            const popupTop = buttonOffset.top + clickedButton.outerHeight() + 10;
            const popupLeft = buttonOffset.left + (clickedButton.outerWidth() / 2) - (popup.outerWidth() / 2) + 5;
            clickedButton.parent().append(popup);
            popup.css({
                display: "block"
            });
        });

        // $('.dialog-model').click(function(event) { // Add event parameter here
        //     event.stopPropagation();
        // });

        $(".dialog-model .cancel-btn").click(function (event) { // Add event parameter here
            event.stopPropagation();
            $('.outer-container').append(popup);
            $(".dialog-model").css('display', 'none');
            $(".sp-add-food").parent().removeClass('selected');
        });

        // $(".dialog-model .createN").click(function(event) { // Add event parameter here
        //     addFood(foodData.id);
        // });

        // $(document).click(function(event) { // Add event parameter here
        //     const clickedElement = $(event.target);
        //     if (!clickedElement.hasClass("dialog-model") && $(".dialog-model").css("display") === "block") {
        //         $(".dialog-model").css('display', 'none');
        //         $('.outer-container').append(popup);
        //         $(".sp-add-food").parent().removeClass('selected');
        //     }
        // });
    }

    const openUpdateFoodPopup = (foodData) => {
        setFoodId(foodData.id)
        setStudentId(foodData.student_id)
        setFoodDate(foodData.formattedDate)
        console.log(foodData)
        setFoodDetails({
            foodTime: foodData.food_time_id,
            quantity: foodData.quantity_id,
            foodType: foodData.type_id,
            category: foodData.category_id,
            description: foodData.message
        })
        const popup = $(".dialog-model");
        $(".food_date").click(function (event) { // Add event parameter here
            setFoodTimeError(null);
            setQuantityError(null);
            setFoodTypeError(null);
            setCategoryError(null);
            setDescriptionError(null);
            // event.stopPropagation();
            const clickedButton = $(this);
            $(".sp-add-food").addClass('selected');
            clickedButton.parent().addClass('selected');
            const buttonOffset = clickedButton.offset();
            const popupTop = buttonOffset.top + clickedButton.outerHeight() + 10;
            const popupLeft = buttonOffset.left + (clickedButton.outerWidth() / 2) - (popup.outerWidth() / 2) + 5;
            clickedButton.parent().append(popup);
            popup.css({
                display: "block"
            });
        });

        $(".dialog-model .cancel-btn").click(function (event) { // Add event parameter here
            event.stopPropagation();
            $('.outer-container').append(popup);
            $(".dialog-model").css('display', 'none');
            $(".sp-add-food").parent().removeClass('selected');
        });
    }

    const [foodDetails, setFoodDetails] = useState({
        foodTime: '',
        quantity: '',
        foodType: '',
        category: '',
        description: 'You children finish the Lunch.'
    })

    const [foodId, setFoodId] = useState(null);
    const [studentId, setStudentId] = useState("");
    const [foodDate, setFoodDate] = useState("");
    // const [quantity, setQuantity] = useState("");
    // const [foodType, setFoodType] = useState("");    
    // const [category, setCategory] = useState("");    
    // const [description, setDescription] = useState("");

    const [foodTimeError, setFoodTimeError] = useState(null);
    const [quantityError, setQuantityError] = useState(null);
    const [foodTypeError, setFoodTypeError] = useState(null);
    const [categoryError, setCategoryError] = useState(null);
    const [descriptionError, setDescriptionError] = useState(null);

    const validateForm = () => {
        let valid = true;
        if (foodDetails.foodTime === "") {
            setFoodTimeError("Please Select Food Time");
            valid = false;
        } else {
            setFoodTimeError(null);
        }
        if (foodDetails.quantity === "") {
            setQuantityError("Please Select Quantity");
            valid = false;
        } else {
            setQuantityError(null);
        }
        if (foodDetails.foodType === "") {
            setFoodTypeError("Please Select Food Type");
            valid = false;
        } else {
            setFoodTypeError(null);
        }
        if (foodDetails.category === "") {
            setCategoryError("Please Select Category");
            valid = false;
        } else {
            setCategoryError(null);
        }
        if (foodDetails.description === "") {
            setDescriptionError("Please Enter Description");
            valid = false;
        } else {
            setDescriptionError(null);
        }
        return valid;
    };

    const addFood = () => {
        if (validateForm()) {
            const postData = {
                school_id: localStorage.getItem('schoolId'),
                staff_id: 5,
                food_date: foodDate,
                programs_id: localStorage.getItem('programId'),
                student_ids: [studentId],
                food_time_id: foodDetails.foodTime,
                type_id: foodDetails.foodType,
                quantity_id: foodDetails.quantity,
                category_id: foodDetails.category,
                message: foodDetails.description,
                created_by: localStorage.getItem('schoolId')
            };
            console.log(postData);
            axios({
                method: "post",
                url: baseUrl + "/api/food_add",
                data: postData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    window.location.reload();
                }
            });
        }
    };

    const updateFood = () => {
        const postData = {
            id: foodId,
            school_id: localStorage.getItem('schoolId'),
            staff_id: 5,
            food_date: foodDate,
            programs_id: localStorage.getItem('programId'),
            student_id: studentId,
            food_time_id: foodDetails.foodTime,
            type_id: foodDetails.foodType,
            quantity_id: foodDetails.quantity,
            category_id: foodDetails.category,
            message: foodDetails.description,
            updated_by: localStorage.getItem('schoolId')
        }
        console.log(postData)
        axios({
            method: "post",
            url: baseUrl + "/api/food_edit",
            data: postData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                // fetchFoodData();
                window.location.reload();
            }
        });

    }

    const [quantityLoading, setQuantityLoading] = useState(false);
    const [quantityData, setQuantityData] = useState([]);
    const fetchQuantityData = async () => {
        setQuantityLoading(false);
        axios({
            method: "post",
            url: baseUrl + "/api/get_food_quantity",
            // data:{},
            headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setQuantityData(response.data.data);
                console.log(response);
                setQuantityLoading(true);
            }
        });
    }

    const [foodTimeLoading, setFoodTimeLoading] = useState(false);
    const [foodTimeData, setFoodTimeData] = useState([]);
    const fetchFoodTimeData = async () => {
        setFoodTimeLoading(false);
        axios({
            method: "post",
            url: baseUrl + "/api/get_time",
            headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setFoodTimeData(response.data.data);
                console.log(response);
                setFoodTimeLoading(true);
            }
        });
    }

    const [foodTypeLoading, setFoodTypeLoading] = useState(false);
    const [foodTypeData, setFoodTypeData] = useState([]);
    const fetchFoodTypeData = async () => {
        setFoodTypeLoading(false);
        axios({
            method: "post",
            url: baseUrl + "/api/get_food_type",
            // data:{},
            headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setFoodTypeData(response.data.data);
                console.log(response);
                setFoodTypeLoading(true);
            }
        });
    }

    const [foodCategoryLoading, setFoodCategoryLoading] = useState(false);
    const [foodCategoryData, setFoodCategoryData] = useState([]);
    const fetchFoodCategoryData = async () => {
        setFoodCategoryLoading(false);
        axios({
            method: "post",
            url: baseUrl + "/api/get_food_category",
            // data:{},
            headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setFoodCategoryData(response.data.data);
                console.log(response);
                setFoodCategoryLoading(true);
            }
        });
    }


    const selectedQuantity = quantityData.find(data => data.id === foodDetails.quantity);
    const selectedFoodTime = foodTimeData.find(data => data.id === foodDetails.foodTime);
    const selectedFoodType = foodTypeData.find(data => data.id === foodDetails.foodType);
    const selectedCategory = foodCategoryData.find(data => data.id === foodDetails.category);

    useEffect(() => {
        fetchFoodTimeData();
        fetchFoodTypeData();
        fetchQuantityData();
        fetchFoodCategoryData();
        fetchProgramsData();
        fetchFoodData();
    }, []);

    return (
        <section>
            <div className="outer-container">
                <div id="left-panel" className="sp-left-panel">
                    <div className="sp-top-band">
                        <a className="sp-logo">
                            Scool Panda<sup>Beta</sup>
                        </a>
                        <div className="sp-search-top flexc">
                            <i className="icon-Search" />
                            <input
                                id="search"
                                className="sp-search-btn"
                                placeholder="Quick search..."
                            />
                        </div>
                    </div>
                    <div className="sp-middle-band">
                        <div className="sp-nav y-auto">
                            <div className="">
                                <h4 className="sp-title">Quick Actions</h4>
                                <ul>
                                    <li onClick={() => { navigate('/dashboard'); }}>
                                        <span>
                                            <i className="icon-Dashboard-Outline" />
                                            Dashboard
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/activity'); }}>
                                        <span>
                                            <i className="icon-Activity-Outline" />
                                            Activity
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/calendar'); }}>
                                        <span>
                                            <i className="icon-Timetable-Outline" />
                                            Calendar
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/staff_list'); }}>
                                        <span>
                                            <i className="icon-Staff-Outline" />
                                            Staff
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i className="icon-Message-Outline" />
                                            Messages
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i className="icon-Fees-Outline" />
                                            Fees Management
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i className="icon-Enquiries-Outline" />
                                            Enquiries
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i className="icon-Invites-Outline" />
                                            Invites
                                        </span>
                                    </li>
                                </ul>
                                <h4 className="sp-title flexc justifySp">
                                    Programs{" "}
                                    <span id="add-programs" className="sp-plus">
                                        +
                                    </span>
                                </h4>
                                <ul>
                                    {programsLoading && programsData && programsData.length > 0 ?
                                        programsData.map((data, index) => {
                                            return (
                                                <li className={data.id === parseInt(localStorage.getItem('programId')) ? "selected" : ""} onClick={() => {
                                                    localStorage.setItem('programId', data.id);
                                                    localStorage.setItem('programName', data.program_name);
                                                    fetchFoodData();
                                                    navigate('/food');
                                                }}>
                                                    <span>
                                                        <i className="icon-Program-Outline-1" />
                                                        {data.program_name}
                                                    </span>
                                                </li>
                                            );
                                        }) :
                                        <Loader type="spinner-cub" bgColor="#4D6EE3" size={30} />
                                    }

                                </ul>
                            </div>
                        </div>
                        <div className="sp-bottom sp-nav">
                            <ul>
                                <li>
                                    <span>
                                        <i className="icon-Import-Outline" />
                                        Notifications
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <i className="icon-Invites-Outline" />
                                        Upgrade Pro
                                    </span>
                                </li>
                                <li onClick={() => navigate("/signout")}>
                                    <span>
                                        <i className="icon-Invites-Outline" />
                                        Log Out
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="sp-right-panel">
                    <div className="sp-top-bar flexc justifySp">
                        <div className="ft-wt600 flexc">
                            <span className="selected-icon">
                                <i className="icon-Program-Outline-1" />
                            </span>
                            {localStorage.getItem('programName')}
                            <i className="icon-More-Icon" />
                        </div>
                        <div className="flexc">
                            <button className="sp-view-btn ft-wt500 flexc">
                                <i className="icon-Import-Outline" />
                                Import
                            </button>
                            <button className="createN ft-wt500 mr-lf10">+ New Student</button>
                        </div>
                    </div>
                    <div className="sp-header">
                        <nav className="flexc">
                            <div className="sp-list-view flexc" onClick={() => { navigate('/student_list'); }}>
                                <i className="icon-Students-Outline" />
                                Student List
                            </div>
                            <div className="sp-list-view flexc" onClick={() => { navigate('/student_attendance'); }}>
                                <i className="icon-Attendance-Outline" />
                                Attendance
                            </div>
                            <div className="sp-list-view flexc selected">
                                <i className="icon-Food" />
                                Food
                            </div>
                            <div className="sp-list-view flexc" onClick={() => { navigate('/sleep'); }}>
                                <i className="icon-Sleep" />
                                Sleep
                            </div>
                            <div className="sp-list-view flexc">
                                <i className="icon-Calendar-Outline" />
                                Timetable
                            </div>
                        </nav>
                        <div className="sp-menu-bar flexc justifySp">
                            <div className="sp-menugroup">
                                <div className="flexc">
                                    <div className="btn-search-bar flexc">
                                        <i className="icon-Search" />
                                        <input
                                            type="text"
                                            placeholder="Search Students"
                                            value={filterValue}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="week-detail flexc">
                                        <button className="set-bord left-arr">
                                            <span className="arrow" />
                                        </button>
                                        <button className="sel-days set-bord">
                                            Week
                                            <span className="arrow up-arr" />
                                        </button>
                                        <button className="set-bord right-arr">
                                            <span className="arrow" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="flexc sp-menugroup">
                                <div className="sp-menubar-clslist flexc">
                                    <span className="ft-wt500 ft-14">Class Teachers</span>
                                    <ul className="flexc">
                                        <li>
                                            <img src={avatar1} />
                                        </li>
                                        <li>
                                            <img src={avatar1} />
                                        </li>
                                        <li>
                                            <img src={avatar1} />
                                        </li>
                                    </ul>
                                    <span className="sp-plus">+</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {foodLoading && foodData ?
                        <div className="std-attence">
                            <div className="attence-top overxy-auto">
                                <div className="students-add-list">
                                    <ul className="sp-head sp_sticky">
                                        <li>{foodData.student}</li>
                                        {dataHeader && dataHeader.length > 0 ?
                                            (dataHeader).map((data, index) => {
                                                return (
                                                    <li>{data.date_data}</li>
                                                );
                                            }) : <div></div>}
                                    </ul>
                                    {foodHeaderdata.map((student, studentIndex) => (
                                        <React.Fragment key={studentIndex}>
                                            <ul>
                                                <li>
                                                    <span className="ft-500 flexc">
                                                        <img src={student.profile_image || avatar1} width={40} alt="Avatar" />
                                                        <span className="mr-lf10 ft-14 truncate">{student.name}</span>
                                                    </span>
                                                </li>
                                                {student.food.map((foodData, foodIndex) => (
                                                    <React.Fragment key={foodIndex}>
                                                        {foodData.food_value.length > 0 ? (
                                                            <li>
                                                                <div className="" >
                                                                    {foodData.food_value.map((food, timeIndex) => (
                                                                        <div className="sp-tag-selected food_date mr-lf10 " key={timeIndex} style={{ cursor: "pointer" }} onClick={() => {
                                                                            openUpdateFoodPopup(
                                                                                {
                                                                                    id: food.food_data.id,
                                                                                    student_id: student.id,
                                                                                    formattedDate: foodData.formattedDate,
                                                                                    food_time_id: food.food_data.food_time_id,
                                                                                    quantity_id: food.food_data.quantity_id,
                                                                                    type_id: food.food_data.type_id,
                                                                                    category_id: food.food_data.category_id,
                                                                                    message: food.food_data.message
                                                                                }
                                                                                //    student,foodData.formattedDate
                                                                            );
                                                                        }}>
                                                                            {food.food_value}
                                                                        </div>
                                                                    ))}

                                                                </div>
                                                                {foodData.attendance_status === 1 &&
                                                                    <div className="sp-add-food cp" onClick={() => {
                                                                        openAddFoodPopup(
                                                                            {
                                                                                student_id: student.id,
                                                                                formattedDate: foodData.formattedDate
                                                                            }
                                                                        );
                                                                    }}>
                                                                        <i className="icon-plus-w" />
                                                                    </div>
                                                                }
                                                            </li>
                                                        ) : (
                                                            <li>
                                                                {foodData.attendance_status === 1 ? (
                                                                    <div className="sp-add-food cp" onClick={() => {
                                                                        //openAddFoodPopup(student,foodData.formattedDate);
                                                                        openAddFoodPopup(
                                                                            {
                                                                                student_id: student.id,
                                                                                formattedDate: foodData.formattedDate
                                                                            }
                                                                        );
                                                                    }}>
                                                                        <i className="icon-plus-w" />
                                                                    </div>
                                                                ) : foodData.attendance_status === 2 ? (
                                                                    <span className="notapp-ele" />
                                                                ) : foodData.attendance_status > 2 ? (
                                                                    <span className="cross-ele" />
                                                                ) : null}
                                                            </li>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </ul>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        </div> : foodHeaderdata.length === 0 ? <div className="sp-title flex-3">Data Not Found</div> : <Loader type="spinner-cub" bgColor="#4D6EE3" color="#4D6EE3" title="Loading..." size={50} />}
                </div>
                {/* my code */}
                <div className="dialog-model absolute">
                    <div className="sp-tt ft-14 flexc ft-wt600">Add Food</div>
                    <div className="form-section-default flexc justifySp fwrap">
                        <div className="field-label half-field">
                            <label>Food Time</label>
                            <div className="field-value-holder margin_top10p dropbutton" style={{ width: '100%' }}>
                                <Dropdown
                                    label={<span style={{ color: 'black' }}>{selectedFoodTime ? selectedFoodTime.time_name : "Select Time"}</span>}
                                    style={{ width: '100%' }}
                                >
                                    {foodTimeData.map(item => (
                                        <Dropdown.Item
                                            className={selectedFoodTime && selectedFoodTime.id === item.id ? "dd_select" : null}
                                            icon={selectedFoodTime && selectedFoodTime.id === item.id ? HiCheck : null}
                                            onClick={() => setFoodDetails({ ...foodDetails, foodTime: item.id })}
                                            key={item.id}
                                        >
                                            {item.time_name}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown>
                            </div>
                            {foodTimeError && <div className="error">{foodTimeError}</div>}
                        </div>
                        <div className="field-label half-field dropbutton">
                            <label>Quantity</label>
                            <div className="field-value-holder margin_top10p" style={{ width: '100%' }}>
                                <Dropdown
                                    label={<span style={{ color: 'black' }}>{selectedQuantity ? selectedQuantity.quantity_name : "Select Quantity"}</span>}
                                    style={{ width: '100%' }}
                                >
                                    {quantityData.map(item => (
                                        <Dropdown.Item
                                            className={selectedQuantity && selectedQuantity.id === item.id ? "dd_select" : null}
                                            icon={selectedQuantity && selectedQuantity.id === item.id ? HiCheck : null}
                                            onClick={() => setFoodDetails({ ...foodDetails, quantity: item.id })}
                                            key={item.id}
                                        >
                                            {item.quantity_name}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown>
                            </div>
                            {quantityError && <div className="error">{quantityError}</div>}
                        </div>
                        <div className="field-label half-field dropbutton">
                            <label>Type</label>
                            <div className="field-value-holder margin_top10p" style={{ width: '100%' }}>
                                <Dropdown
                                    label={<span style={{ color: 'black' }}>{selectedFoodType ? selectedFoodType.type_name : "Select Type"}</span>}
                                    style={{ width: '100%' }}
                                >
                                    {foodTypeData.map(item => (
                                        <Dropdown.Item
                                            className={selectedFoodType && selectedFoodType.id === item.id ? "dd_select" : null}
                                            icon={selectedFoodType && selectedFoodType.id === item.id ? HiCheck : null}
                                            onClick={() => setFoodDetails({ ...foodDetails, foodType: item.id })}
                                            key={item.id}
                                        >
                                            {item.type_name}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown>
                            </div>
                            {foodTypeError && <div className="error">{foodTypeError}</div>}
                        </div>
                        <div className="field-label half-field dropbutton">
                            <label>Category</label>
                            <div className="field-value-holder margin_top10p" style={{ width: '100%' }}>
                                <Dropdown
                                    label={<span style={{ color: 'black' }}>{selectedCategory ? selectedCategory.category_name : "Select Category"}</span>}
                                    style={{ width: '100%' }}
                                >
                                    {foodCategoryData.map(item => (
                                        <Dropdown.Item
                                            className={selectedCategory && selectedCategory.id === item.id ? "dd_select" : null}
                                            icon={selectedCategory && selectedCategory.id === item.id ? HiCheck : null}
                                            onClick={() => setFoodDetails({ ...foodDetails, category: item.id })}
                                            key={item.id}
                                        >
                                            {item.category_name}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown>
                            </div>
                            {categoryError && <div className="error">{categoryError}</div>}
                        </div>
                        <div className="field-label full-field dropbutton">
                            <label>Description</label>
                            <div className="field-value-holder margin_top10p">
                                <textarea
                                    value={foodDetails.description}
                                    onChange={ev => setFoodDetails({ ...foodDetails, description: ev.target.value })}
                                    className="sp-field-input"
                                    placeholder="Description"
                                />
                            </div>
                            {descriptionError && <div className="error">{descriptionError}</div>}
                        </div>
                        <div className="flexc mt-4">
                            <div className="flexc">
                                <button className="createN ft-wt500 mr-2" onClick={() => { foodId === null ? addFood() : updateFood() }}>{foodId === null ? "Save" : "Update"}</button>
                                <button className="sp-view-btn ft-wt500 flexc cancel-btn">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="dialog-model absolute">
                    <div className="sp-tt ft-14 flexc ft-wt600">Add Food</div>
                    <div className="form-section-default flexc justifySp fwrap">
                    <div className="field-label half-field">
                        <label>Food Time</label>
                        <div className="field-value-holder  margin_top10p dropbutton" style={{ width: '100%' }}>
                                        <Dropdown
                                            label={<span style={{ color: 'black' }}>{selectedFoodTime ? selectedFoodTime.time_name : "Select Time"}</span>}
                                            style={{ width: '100%' }}
                                        >
                                            {foodTimeData.map(item => (
                                                <Dropdown.Item
                                                className={selectedFoodTime&&selectedFoodTime.id===item.id?"dd_select":null}
                                                    icon={selectedFoodTime&&selectedFoodTime.id===item.id?HiCheck:null}
                                                    onClick={() => setFoodDetails({ ...foodDetails, foodTime: item.id })}
                                                    key={item.id}
                                                >
                                                    {item.time_name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown>
                        </div>
                    </div>
                    <div className="field-label half-field dropbutton">
                        <label>Quantity</label>
                        <div className="field-value-holder  margin_top10p" style={{ width: '100%' }}>
                                        <Dropdown
                                            label={<span style={{ color: 'black' }}>{selectedQuantity ? selectedQuantity.quantity_name : "Select Quantity"}</span>}
                                            style={{ width: '100%' }}
                                        >
                                            {quantityData.map(item => (
                                                <Dropdown.Item
                                                className={selectedQuantity&&selectedQuantity.id===item.id?"dd_select":null}
                                                    icon={selectedQuantity&&selectedQuantity.id===item.id?HiCheck:null}
                                                    onClick={() => setFoodDetails({ ...foodDetails, quantity: item.id })}
                                                    key={item.id}
                                                >
                                                    {item.quantity_name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown>
                        </div>
                    </div>
                    <div className="field-label half-field dropbutton">
                        <label>Type</label>
                        <div className="field-value-holder  margin_top10p" style={{ width: '100%' }}>
                            <Dropdown
                                label={<span style={{ color: 'black' }}>{selectedFoodType ? selectedFoodType.type_name : "Select Time"}</span>}
                                style={{ width: '100%' }}
                            >
                                {foodTypeData.map(item => (
                                    <Dropdown.Item
                                    className={selectedQuantity&&selectedQuantity.id===item.id?"dd_select":null}
                                        icon={selectedFoodType&&selectedFoodType.id===item.id?HiCheck:null}
                                        onClick={() => setFoodDetails({ ...foodDetails, foodType: item.id })}
                                        key={item.id}
                                    >
                                        {item.type_name}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown>
                        </div>
                    </div>
                    <div className="field-label half-field dropbutton">
                        <label>Category</label>
                        <div className="field-value-holder  margin_top10p" style={{ width: '100%' }}>
                                        <Dropdown
                                            label={<span style={{ color: 'black' }}>{selectedCategory ? selectedCategory.category_name : "Select Time"}</span>}
                                            style={{ width: '100%' }}
                                        >
                                            {foodCategoryData.map(item => (
                                                <Dropdown.Item
                                                className={selectedCategory&&selectedCategory.id===item.id?"dd_select":null}
                                                    icon={selectedCategory&&selectedCategory.id===item.id?HiCheck:null}
                                                    onClick={() => setFoodDetails({ ...foodDetails,category: item.id })}
                                                    key={item.id}
                                                >
                                                    {item.category_name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown>
                        </div>
                    </div>
                    <div className="field-label full-field dropbutton">
                        <label>Description</label>
                        <div className="field-value-holder  margin_top10p">
                        <textarea
                            value={foodDetails.description}
                            onChange={ev => setFoodDetails({ ...foodDetails, description: ev.target.value })}
                            className="sp-field-input"
                            placeholder="Description"
                        />
                        </div>
                    </div>
                    <div className="flexc mt-4">
                        <div className="flexc">
                        <button className="createN ft-wt500 mr-2" onClick={() => {foodId===null?addFood():updateFood()}}>{foodId===null?"Save":"Update"}</button>
                        <button className="sp-view-btn ft-wt500 flexc cancel-btn">
                            Cancel
                        </button>
                        </div>
                    </div>
                    </div>
                </div>                 */}
            </div>
        </section>
    );

}
