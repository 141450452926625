import $ from "jquery";

$(document).ready(function() {
    // $(document).on('click', '#sp-verfybtn', function() {
    //     $('.login-otp-inr').fadeIn();
    //     $(this).parent().parent().css('display', 'none');
    // });
    // $(document).on('click', '#sp-otp-btn', function() {
    //     //window.open('school-info.html');
    // });


    $(document).on('click', '.sp-btn-cancel', function() {
        $('.overlay').removeClass('visible');
        $('.popup-modal-container').removeClass('visible')
    });
    // $(document).on('click', '.sp-btn-create', function() {
    //     $('.overlay').removeClass('visible');
    //     $('.popup-modal-container').removeClass('visible')
    //     setTimeout(function() {
    //         $('.popup-student-details').addClass('visible');
    //     }, 500);
    // });
    $(document).on('click', '.popup-student-details .close', function() {
        $('.popup-student-details').removeClass('visible');
    });

    $(document).on('click', '.add-programs', function() {
        $('.overlay').removeClass('visible');
        // $('.popup-modal-program').removeClass('visible')
        setTimeout(function() {
            $('.popup-modal-program').addClass('visible');
        }, 500);
    });
    $(document).on('click', '.sp-btn-cancel', function() {
        $('.overlay').removeClass('visible');
        $('.popup-modal-program').removeClass('visible')
    });
    $(document).on('click', '.sp-list-view', function(index) {

        if ($(this).index() === 0) {
            //window.open('/index.html');
        } else if ($(this).index() === 1) {
            // window.open('/staffattendance.html');
        }
    });

    // $('.act-announce-inp input').on('click', function() {
    //     $('.announce-post-inr').slideDown();
    // });



});