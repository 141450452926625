import React, { useState, useMemo, useEffect } from "react";
import {useNavigate } from "react-router-dom";
import dashboardavator1 from "./images/dashboard-avator1.png";
import dashboardavator2 from "./images/dashboard-avator2.png";
import dashboardavator3 from "./images/dashboard-avator3.png";
import dashboardavator4 from "./images/dashboard-avator4.png";
import avatar3 from "./images/avatar3.png";
import avatar2 from "./images/avatar2.png";
import "../../common/components/js/common.js";
// import '../../common/components/js/jquery.min.js';
import { baseUrl } from "../../common/common";
import axios from "axios";
import { formatDistanceToNow } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import Loader from "react-js-loader";

export default function Dashboard() {
  const [schoolDashboard, setSchoolDashboard] = useState([]);
  const [leaveApplied, setLeaveApplied] = useState([]);

  useEffect(() => {
    fetchProgramsData();
    getSchooldDashboard();
    getDashboardLeaveApplied();
  }, []);

  const getSchooldDashboard = () => {
    axios({
      method: "post",
      url: baseUrl + "/api/school_dashboard",
      data: {
        school_id: localStorage.getItem("schoolId"),
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((response) => {
      if (response.data.status === true) {
        setSchoolDashboard(response.data);
      }
    });
  };

  useEffect(() => { }, [schoolDashboard]);

  const getDashboardLeaveApplied = () => {
    axios({
      method: "post",
      url: baseUrl + "/api/dashboard_leave_applied",
      data: {
        school_id: localStorage.getItem("schoolId"),
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((response) => {
      if (response.data.status === true) {
        setLeaveApplied(response.data.data);
      }
    });
  };

  const leaveApprove = async (item) => {
    const isComfirm = window.confirm(
      "Are you sure you want to approve this leave?"
    );
    if (isComfirm) {
      console.log("leaveApprove_________", item);
      axios({
        method: "post",
        url: baseUrl + "/api/staff_leave_request_actions",
        data: {
          id: item.id,
          updated_by: localStorage.getItem("schoolId"),
          leave_status: 3,
          status_updated_by: localStorage.getItem("schoolId"),
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        if (response.data.status === true) {
          getSchooldDashboard();
          getDashboardLeaveApplied();
        }
      });
    }
  };

  const getFormatedDate = (date, type) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const dateObj = new Date(date);
    if (isNaN(dateObj.getTime())) {
      return "N/A";
    }

    const month = monthNames[dateObj.getMonth()];
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const isToday = dateObj.toDateString() === today.toDateString();
    const isTomorrow = dateObj.toDateString() === tomorrow.toDateString();
    const isYesterday = dateObj.toDateString() === yesterday.toDateString();

    if (type == "program") {
      return `${month} ${day}, ${year}`;
    } else if (type == "holiday") {
      return `${day} ${month} ${year}`;
    } else if (type == "current") {
      return `${month} ${day}, ${year}`;
    } else if (type == "leave") {
      return `${month} ${day} ${year}`;
    } else if (type == "birthday") {
      if (isToday) {
        return "Today";
      } else if (isTomorrow) {
        return "Tomorrow";
      } else if (isYesterday) {
        return "Yesterday";
      } else {
        const month = monthNames[dateObj.getMonth()];
        const day = dateObj.getDate();
        const year = dateObj.getFullYear();
        return `${day} ${month} ${year}`;
      }
    }
  };

  const studentActivityTimeformat = (start, end) => {
    if (!start && !end) {
      return "N/A";
    }
    const options = { hour: "numeric", minute: "numeric" };
    const startDate = new Date(start);
    const endDate = new Date(end);
    const formattedStartTime = startDate.toLocaleTimeString([], options);
    const formattedEndTime = endDate.toLocaleTimeString([], options);
    return `${formattedStartTime} – ${formattedEndTime}`;
  };

  const getTimeDifference = (apiTime) => {
    const utcDate = new Date(apiTime);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localDate = toZonedTime(utcDate, timeZone);
    let timeDifference = formatDistanceToNow(localDate, { addSuffix: true });
    timeDifference = timeDifference.replace(/^about /, "");
    return timeDifference;
  };

  const backgroundClasses = [
    "sp-row3-bt1",
    "sp-row3-bt2",
    "sp-row3-bt3",
    "sp-row3-bt4",
  ];

  // --------------------------------------

  const navigate = useNavigate();
  const [programsData, setProgramsData] = useState({});
  const [programsLoading, setProgramsLoading] = useState(false);

  const fetchProgramsData = async () => {
      setProgramsLoading(false);
      try {
          const postData = {
              school_id: localStorage.getItem('schoolId')
          }
          const { data } = await axios.post(baseUrl + "/api/get_programs", postData);
          setProgramsData(data.data);
          setProgramsLoading(true);
      } catch (error) {
          console.log(error)
      }
  }

  return (
    <section>
      <div className="outer-container dash_page">
        <div id="left-panel" className="sp-left-panel">
          <div className="sp-top-band">
            <a className="sp-logo">
              Scool Panda<sup>Beta</sup>
            </a>
            <div className="sp-search-top flexc">
              <i className="icon-Search" />
              <input
                id="search"
                className="sp-search-btn"
                placeholder="Quick search..."
              />
            </div>
          </div>
          <div className="sp-middle-band">
            <div className="sp-nav y-auto">
              <div className="">
                <h4 className="sp-title">Quick Actions</h4>
                <ul>
                  <li className="selected">
                    <span>
                      <i className="icon-Dashboard-Outline" />
                      Dashboard
                    </span>
                  </li>
                  <li onClick={() => { navigate('/activity'); }}>
                    <span>
                      <i className="icon-Activity-Outline" />
                      Activity
                    </span>
                  </li>
                  <li onClick={() => { navigate('/calendar'); }}>
                    <span>
                      <i className="icon-Timetable-Outline" />
                      Calendar
                    </span>
                  </li>
                  <li onClick={() => { navigate('/staff_list'); }}>
                    <span>
                      <i className="icon-Staff-Outline" />
                      Staff
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Message-Outline" />
                      Messages
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Fees-Outline" />
                      Fees Management
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Enquiries-Outline" />
                      Enquiries
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Invites-Outline" />
                      Invites
                    </span>
                  </li>
                </ul>
                <h4 className="sp-title flexc justifySp">
                  Programs{" "}
                  <span id="add-programs" className="sp-plus">
                    +
                  </span>
                </h4>
                <ul>
                  {programsLoading && programsData && programsData.length > 0 ?
                    programsData.map((data) => {
                      return (
                        <li onClick={() => { navigate('/student_list'); }}>
                          <span>
                            <i className="icon-Program-Outline-1" />
                            {data.program_name}
                          </span>
                        </li>
                      );
                    }) :
                    <Loader type="spinner-cub" bgColor="#4D6EE3" size={30} />
                  }
                </ul>
              </div>
            </div>
            <div className="sp-bottom sp-nav">
              <ul>
                <li>
                  <span>
                    <i className="icon-Import-Outline" />
                    Notifications
                  </span>
                </li>
                <li>
                  <span>
                    <i className="icon-Invites-Outline" />
                    Upgrade Pro
                  </span>
                </li>
                <li onClick={() => navigate("/signout")}>
                  <span>
                    <i className="icon-Invites-Outline" />
                    Log Out
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* my code */}
        <div className="sp-right-panel">
          <div className="sp-dashboard-container">
            <div className="dashboard-inr">
              <div className="dashboard-top flexc justifySp">
                <p className="ft-wt700">Dashboard</p>
                <button className="ft-wt500 customize_but">Customise</button>
              </div>
              <div
                className="dashboard-cont flexc justifySp"
                style={{ alignItems: "flex-start" }}
              >
                <div className="dashboard-cont-left">
                  <div className="dash-row1 dash-row flexc justifySp ">
                    <div className="dash-row1-box dash-row1-box1">
                      <span className="ft-wt600 ft-13">Student Attendance</span>
                      <div className="dash-box1-btm flexc justifySp">
                        <span className="ft-20 ft-wt700">
                          {schoolDashboard.student_attendance}/
                          {schoolDashboard.student_count}
                        </span>
                        {/* <span className="dash-row1-range" /> */}
                        <img
                          src={dashboardavator1}
                          width={25}
                          height={25}
                          alt="Student Attendance"
                        />
                      </div>
                    </div>
                    <div className="dash-row1-box dash-row1-box2">
                      <span className="ft-wt600 ft-13">Teacher Attendance</span>
                      <div className="dash-box1-btm flexc justifySp">
                        <span className="ft-20 ft-wt700">
                          {schoolDashboard.staff_attendance}/
                          {schoolDashboard.staff_count}
                        </span>
                        <img
                          src={dashboardavator2}
                          width={25}
                          height={25}
                          alt="Teacher Attendance"
                        />
                      </div>
                    </div>
                    <div className="dash-row1-box dash-row1-box3">
                      <span className="ft-wt600 ft-13">Leave Applied</span>
                      <div className="dash-box1-btm flexc justifySp">
                        <span className="ft-20 ft-wt700">
                          {schoolDashboard.leave_applied}
                        </span>
                        <img
                          src={dashboardavator3}
                          width={25}
                          height={25}
                          alt="Leave Applied"
                        />
                      </div>
                    </div>
                    <div className="dash-row1-box dash-row1-box4">
                      <span className="ft-wt600 ft-13">Absents</span>
                      <div className="dash-box1-btm flexc justifySp">
                        <span className="ft-20 ft-wt700">
                          {schoolDashboard.absents}
                        </span>
                        <img
                          src={dashboardavator4}
                          width={25}
                          height={25}
                          alt="Leave Applied"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="dash-row2 dash-row flexc justifySp">
                    <div className="dash-bar-chart1 dash-bar-chart sp-dashboard">
                      <p className="ft-wt600 ft-13">
                        Student Attendance by Class
                      </p>
                      <div className="bar-chart-cont flexc ft-13">
                        <ul>
                          <li>50</li>
                          <li>30</li>
                          <li>20</li>
                          <li>10</li>
                        </ul>
                        <ul className="chart-colum-cont flexc">
                          <li>
                            <span className="chart-colm1" /> DC
                          </li>
                          <li>
                            <span className="chart-colm2" /> Nursury
                          </li>
                          <li>
                            <span className="chart-colm3" /> JKG
                          </li>
                          <li>
                            <span className="chart-colm4" /> SKG
                          </li>
                          <li>
                            <span className="chart-colm5" /> K1
                          </li>
                          <li>
                            <span className="chart-colm6" /> SKG2
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="dash-bar-chart2 dash-bar-chart sp-dashboard">
                      <p className="ft-wt600 ft-13">New Enquiries</p>
                      <div className="bar-chart-cont flexc ft-13">
                        <ul>
                          <li>50</li>
                          <li>30</li>
                          <li>20</li>
                          <li>10</li>
                        </ul>
                        <ul className="chart-colum-cont flexc">
                          <li>
                            <span className="chart-colm1" /> DC
                          </li>
                          <li>
                            <span className="chart-colm2" /> Nursury
                          </li>
                          <li>
                            <span className="chart-colm3" /> JKG
                          </li>
                          <li>
                            <span className="chart-colm4" /> SKG
                          </li>
                          <li>
                            <span className="chart-colm5" /> K1
                          </li>
                          <li>
                            <span className="chart-colm6" /> SKG2
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    className="dash-row3 dash-row flexc justifySp"
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    {schoolDashboard.program?.length > 0 ? (
                      schoolDashboard.program.map((item, index) => {
                        const backgroundClass =
                          backgroundClasses[index % backgroundClasses.length];
                        return (
                          <>
                            <div
                              key={index}
                              className="dash-row3-box sp-dashboard"
                            >
                              <span className={`ft-wt600 ${backgroundClass}`}>
                                {item.program_name}
                              </span>
                              <p className="ft-wt600 ft-13">{item.title}</p>
                              <span className="flexc sp-row3-dt  ft-13">
                                <i className="icon-Timetable-Outline" />
                                {getFormatedDate(item.start_date, "program")}
                              </span>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="dash-row4 dash-row flexc justifySp">
                    <div className="dash-row4-activity sp-dashboard">
                      <div className="activity-row1 flexc justifySp">
                        <p className="ft-wt600 ft-13">Recent Activity</p>
                        {/* <button className="ft-wt600 font_cus view_cus">View all</button> */}
                      </div>
                      <span className="activity-dt">
                        {getFormatedDate(new Date(), "current")}
                      </span>
                      <div className="activity-row2">
                        {schoolDashboard.activity?.length > 0 ? (
                          schoolDashboard.activity
                            .slice(0, 3)
                            .map((item, index) => {
                              const maxTitleCharacters = 50;
                              const maxContentCharacters = 100;
                              const title =
                                item.post_title.length > maxTitleCharacters
                                  ? item.post_title.substring(
                                    0,
                                    maxTitleCharacters
                                  ) + "..."
                                  : item.post_title;
                              const content =
                                item.post_content.length > maxContentCharacters
                                  ? item.post_content.substring(
                                    0,
                                    maxContentCharacters
                                  ) + "..."
                                  : item.post_content;
                              return (
                                <div
                                  key={index}
                                  className="sp-activities flexc"
                                >
                                  <span className="sp-activ-bt sp-activ-bt1 flexc">
                                    <span className="sp-lpdr icon-sp-present" />
                                  </span>
                                  <div className="sp-activ-cont ft-13">
                                    <p>
                                      <span className="ft-wt600">{title}</span>{" "}
                                      {content}
                                    </p>
                                    <span className="sp-activ-hr">
                                      {getTimeDifference(item.created_on)}
                                    </span>
                                  </div>
                                </div>
                              );
                            })
                        ) : (
                          <div
                            className="ft-13 grid place-items-center"
                            style={{ paddingTop: "50px" }}
                          >
                            <p>No data found</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="dash-row4-activity sp-dashboard">
                      <div className="activity-row1 flexc justifySp">
                        <p className="ft-wt600 ft-13">Students Activity time</p>
                        <button className="ft-wt600 font_cus view_cus">
                          View all
                        </button>
                      </div>
                      <div className="sp-activity-row2 flexc justifySp">
                        <div className="activity-colum flexc justifySp">
                          <span className="sp-activity-img" />
                          <div className="activity-colum-cont">
                            <span>
                              {studentActivityTimeformat(
                                schoolDashboard?.checkinfirst,
                                schoolDashboard?.checkinlast
                              )}
                            </span>
                            <p className="ft-wt600 ft-13">Students Check in</p>
                          </div>
                        </div>
                        <div className="activity-colum flexc justifySp">
                          <span className="sp-activity-img" />
                          <div className="activity-colum-cont">
                            <span>
                              {studentActivityTimeformat(
                                schoolDashboard?.foodfirsttime,
                                schoolDashboard?.foodlasttime
                              )}
                            </span>
                            <p className="ft-wt600 ft-13">Lunch Time</p>
                          </div>
                        </div>
                        <div className="activity-colum flexc justifySp">
                          <span className="sp-activity-img" />
                          <div className="activity-colum-cont">
                            <span>
                              {studentActivityTimeformat(
                                schoolDashboard?.checkoutfirst,
                                schoolDashboard?.checkoutlast
                              )}
                            </span>
                            <p className="ft-wt600 ft-13">Students Check Out</p>
                          </div>
                        </div>
                        <div className="activity-colum flexc justifySp">
                          <span className="sp-activity-img" />
                          <div className="activity-colum-cont">
                            <span>
                              {studentActivityTimeformat(
                                schoolDashboard?.sleepfirsttime,
                                schoolDashboard?.sleeplasttime
                              )}
                            </span>
                            <p className="ft-wt600 ft-13">Sleep time</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashboard-cont-right">
                  <div className="dash-right-row1 sp-dashboard">
                    <div className="activity-row1 flexc justifySp">
                      <p className="ft-wt600 ft-14">
                        Staff's Upcoming Birthday
                      </p>
                      {/* <button className="ft-wt600 font_cus view_cus">View all</button> */}
                    </div>
                    <div className="dash-right-activ2 flexc justifySp">
                      <p className="ft-wt600 ft-13">Staff Name</p>
                      <p className="ft-wt600 ft-13">Date</p>
                    </div>
                    <div className="dash-right-activ3">
                      {schoolDashboard.staff_dob?.length > 0 ? (
                        schoolDashboard.staff_dob
                          .slice(0, 3)
                          .map((item, index) => {
                            return (
                              <>
                                <div
                                  key={index}
                                  className="dash-activ3-row flexc justifySp"
                                >
                                  <div className="activ3-row1-left flexc">
                                    {item.gender == "Female" ? (
                                      <>
                                        <img
                                          src={avatar3}
                                          width={29}
                                          height={29}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <img
                                          src={avatar2}
                                          width={29}
                                          height={29}
                                        />
                                      </>
                                    )}
                                    <p className="ft-wt600 ft-13">
                                      {item.first_name} {item.last_name}
                                      {/* <span className="ft-wt400 ft-12">{item.job_title}</span> */}
                                    </p>
                                  </div>
                                  <span className="ft-wt400 ft-13">
                                    {getFormatedDate(item.dob, "birthday")}
                                  </span>
                                </div>
                              </>
                            );
                          })
                      ) : (
                        <div
                          className="ft-13 grid place-items-center"
                          style={{ padding: "5%" }}
                        >
                          <p>No data found</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="dash-right-row2 sp-dashboard">
                    <div className="activity-row1 flexc justifySp">
                      <p className="ft-wt600 ft-14">Upcoming Holidays</p>
                      {/* <button className="ft-wt600 font_cus view_cus">View all</button> */}
                    </div>
                    <div className="dash-right-activ2 flexc justifySp">
                      <p className="ft-wt600 ft-13">Holiday Name</p>
                      <p className="ft-wt600 ft-13">Date</p>
                    </div>
                    <div className="dash-right-activ3">
                      {schoolDashboard.upcoming_holiday?.length > 0 ? (
                        schoolDashboard.upcoming_holiday
                          .slice(0, 3)
                          .map((item, index) => {
                            return (
                              <>
                                <div
                                  key={index}
                                  className="dash-activ3-row flexc justifySp"
                                >
                                  <div className="activ3-row1-left flexc">
                                    <span className="holi-dt-ic flexc">
                                      <i className="icon-Timetable-Outline" />
                                    </span>
                                    <p className="ft-wt600 ft-13">
                                      {item.title}
                                      <span className="ft-wt400 ft-12">
                                        {item.description}
                                      </span>
                                    </p>
                                  </div>
                                  <span className="ft-wt400 ft-12">
                                    {getFormatedDate(item.start_date)}
                                  </span>
                                </div>
                              </>
                            );
                          })
                      ) : (
                        <div
                          className="ft-13 grid place-items-center"
                          style={{ padding: "5%" }}
                        >
                          <p>No data found</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="dash-right-row3 sp-dashboard">
                    <div className="activity-row1 flexc justifySp">
                      <p className="ft-wt600 ft-14">Leave Applied</p>
                      {/* <button className="ft-wt600 font_cus view_cus">View all</button> */}
                    </div>
                    <div className="dash-right-activ2 flexc justifySp">
                      <p className="ft-wt600 ft-13">Name</p>
                      {/* <p className="ft-wt600 ft-13">Date</p> */}
                    </div>
                    <div className="dash-right-activ3">
                      {leaveApplied?.length > 0 ? (
                        leaveApplied.slice(0, 3).map((item, index) => {
                          return (
                            <>
                              <div
                                key={index}
                                className="dash-activ3-row flexc justifySp"
                              >
                                <div className="activ3-row1-left flexc">
                                  {item.gender == "Female" ? (
                                    <>
                                      <img
                                        src={avatar3}
                                        width={29}
                                        height={29}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        src={avatar2}
                                        width={29}
                                        height={29}
                                      />
                                    </>
                                  )}
                                  <p className="ft-wt600 ft-13">
                                    {item.first_name} {item.last_name}
                                    <span className="ft-wt400 ft-12">
                                      {getFormatedDate(
                                        item.start_date,
                                        "leave"
                                      )}{" "}
                                      -{" "}
                                      {getFormatedDate(item.end_date, "leave")}
                                    </span>
                                  </p>
                                </div>
                                <button
                                  class="ft-12"
                                  onClick={() => leaveApprove(item)}
                                >
                                  Approve
                                </button>
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <div
                          className="ft-13 grid place-items-center"
                          style={{ padding: "5%" }}
                        >
                          <p>No data found</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
