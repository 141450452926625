
// import React, { useState } from "react";
// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
// // import firebaseConfig from "./scool-panda-serviceAccountKey.json"

// let firebaseConfig={
//   apiKey: "AIzaSyDxStEcHP8L-wZMdcEwnklVSl4lHovSXuw",
//   authDomain: "scool-panda.firebaseapp.com",
//   projectId: "scool-panda",
//   storageBucket: "scool-panda.appspot.com",
//   messagingSenderId: "144297568045",
//   appId: "1:144297568045:web:3e141a30b477e8409bd5a4",
//   measurementId: "G-EDN8HRGH52"
// };
// console.log(firebaseConfig)

// firebase.initializeApp(firebaseConfig);


// function App() {
//     const [phoneNumber, setPhoneNumber] = useState("");
//     const [verificationId, setVerificationId] = useState("");
//     const [otp, setOTP] = useState("");
//     const [error, setError] = useState(null);
  
//     const sendOTP = () => {
//       const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
//         "recaptcha-container"
//       );
//      console.log(recaptchaVerifier)
//       firebase
//         .auth()
//         .signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
//         .then((confirmationResult) => {
//           setVerificationId(confirmationResult.verificationId);
//           setError(null);
//         })
//         .catch((error) => {
//           setError(error.message);
//         });
//     };
  
//     const verifyOTP = () => {
//       const credential = firebase.auth.PhoneAuthProvider.credential(
//         verificationId,
//         otp
//       );
  
//       firebase
//         .auth()
//         .signInWithCredential(credential)
//         .then((result) => {
//           console.log("OTP verified", result);
//         })
//         .catch((error) => {
//           setError(error.message);
//         });
//     };
  
//     return (
//       <div>
//         <div id="recaptcha-container"></div>
//         <input
//           type="text"
//           placeholder="Enter Phone Number"
//           value={phoneNumber}
//           onChange={(e) => setPhoneNumber(e.target.value)}
//         />
//         <button onClick={sendOTP}>Send OTP</button>
//         <input
//           type="text"
//           placeholder="Enter OTP"
//           value={otp}
//           onChange={(e) => setOTP(e.target.value)}
//         />
//         <button onClick={verifyOTP}>Verify OTP</button>
//         {error && <p>{error}</p>}
//       </div>
//     );
  
// }

// export default App;


import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment-timezone';
import 'react-big-calendar/lib/css/react-big-calendar.css';

// Configure moment.js to use local time
const localizer = momentLocalizer(moment);

// Function to convert UTC to IST
const convertToIST = (dateString) => {
  const istDate = moment.utc(dateString).tz('Asia/Kolkata');
  console.log(`Original UTC date: ${dateString}, Converted IST date: ${istDate.format()}`);
  return istDate.toDate();
};

// Example events with UTC times
const rawEvents = [
  {
    start: '2024-05-30T18:30:00.000Z',
    end: '2024-05-30T19:30:00.000Z',
    title: 'My Event'
  }
];

function App() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const convertedEvents = rawEvents.map(event => ({
      ...event,
      start: convertToIST(event.start),
      end: convertToIST(event.end)
    }));
    setEvents(convertedEvents);
  }, []);

  return (
    <div style={{ height: '500pt' }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
      />
    </div>
  );
}

export default App;

