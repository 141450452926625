import React, { useState } from "react";
import { Link, Navigate, Outlet } from "react-router-dom";
import { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import CommonSidebar from "./CommonSidebar";
import { Grid } from "@mui/material";

export default function DefaultLayout() {
  return (
    <>
      <Header />
      <main>
      {/* <Grid container>
        <Grid item>
          <CommonSidebar />
        </Grid>
        <Grid item xs={12}>
          <Outlet />
        </Grid>
      </Grid> */}
        <Outlet />
      </main>
      <Footer />
    </>
  );
}
