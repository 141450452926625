import React, { useState, useMemo, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import panda from "./images/panda.png";
import avatar1 from "./images/avatar1.png";
import $ from "jquery";
import { baseUrl } from "../../common/common";
import Loader from "react-js-loader";
import '../../common/components/css/style.css';
import axios from 'axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Dropdown } from 'flowbite-react';
import "../../index.css";
import { HiCheck } from "react-icons/hi";

export default function StudentCalendar() {

    const navigate = useNavigate();

    const [programsData, setProgramsData] = useState([]);
    const [programsLoading, setProgramsLoading] = useState(false);

    const fetchProgramsData = async () => {
        setProgramsLoading(false);
        try {
            const postData = {
                school_id: localStorage.getItem('schoolId')
                // school_id:24
            }
            const { data } = await axios.post(baseUrl + "/api/get_programs", postData);
            setProgramsData(data.data);
            setProgramsLoading(true);
        } catch (error) {
            console.log(error)
        }
    }

    const localizer = momentLocalizer(moment);

    const [program, setProgram] = useState("");

    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");

    const [category, setCategory] = useState("");
    const [categoryData, setCategoryData] = useState([]);
    const selectedCategory = categoryData.find(data => data.id === category);
    const fetchCategoryData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/get_event_category",
            headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setCategoryData(response.data.data);
                console.log(response);
            }
        });
    }

    const [foodTimeData, setFoodTimeData] = useState([]);
    const selectedStartTime = foodTimeData.find(data => data.id === startTime);
    const selectedEndTime = foodTimeData.find(data => data.id === endTime);
    const fetchFoodTimeData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/get_time",
            headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setFoodTimeData(response.data.data);
                console.log(response);
            }
        });
    }

    const selectedProgram = programsData.find(data => data.id === program);
    // const fetchSleepTimeData = async () => {
    //     setSleepTimeLoading(false);
    //     axios({
    //         method: "post",
    //         url: baseUrl + "/api/get_sleep_time",
    //         // data:{},
    //         headers: { "Content-Type": "multipart/form-data" },
    //     }).then((response) => {
    //         console.log(response);
    //         if (response.data.status === true) {
    //             setSleepTimeData(response.data.data);
    //             console.log(response);
    //             setSleepTimeLoading(true);
    //         }
    //     });
    // }

    // Define your events
    const event = [
        // {
        //     title: 'My Event',
        //     start: new Date('2024-05-30T18:30:00.000Z'),
        //     end: new Date('2024-05-30T18:30:00.000Z')
        // },
        // {
        //     title: 'Children Day',
        //     start: new Date(2024, 3, 1, 14, 0), // Apr 1, 2024 2:00 PM
        //     end: new Date(2024, 3, 1, 16, 0),   // Apr 1, 2024 4:00 PM
        // },
        // {
        //     title: 'Fancy Dress Competition For Kids',
        //     start: new Date(2024, 3, 7, 10, 0), // Apr 7, 2024 10:00 AM
        //     end: new Date(2024, 3, 7, 12, 0),   // Apr 7, 2024 12:00 PM
        // },
        // {
        //     title: 'World Sleep Day - Overnight Camping',
        //     start: new Date(2024, 3, 18, 0, 0),  // Apr 18, 2024 12:00 AM
        //     end: new Date(2024, 3, 18, 17, 0),   // Apr 18, 2024 5:00 PM
        // },
        // {
        //     title: 'Annual Day',
        //     start: new Date(2024, 3, 22, 0, 0),  // Apr 22, 2024 12:00 AM
        //     end: new Date(2024, 3, 22, 17, 0),   // Apr 22, 2024 5:00 PM
        // },
        // {
        //     title: 'Art Attack',
        //     start: new Date(2024, 4, 1, 14, 0),  // May 1, 2024 2:00 PM
        //     end: new Date(2024, 4, 1, 16, 0),    // May 1, 2024 4:00 PM
        // }
    ];

    const [events, setEvents] = useState([]);
    const [showAddEventPopup, setShowAddEventPopup] = useState(false);
    const [newEvent, setNewEvent] = useState({
        title: '',
        start: '',
        end: '',
        startTime: '',
        endTime: '',
        program: 'All Programs',
        notify: true,
        description: ''
    });

    const handleSelectSlot = ({ start, end }) => {
        // const popup = $(".dialog-model");
        // const clickedButton = $(this);
        // clickedButton.addClass('selected');    
        // clickedButton.append(popup);
        // popup.css({
        //     display: "block"
        // });  
        setNewEvent({
            title: '',
            start,
            end,
            startTime: '',
            endTime: '',
            program: 'All Programs',
            notify: true,
            description: ''
        });
        setShowAddEventPopup(true);
    };

    const [titleError, setTitleError] = useState(null);
    const handleAddEvent = () => {
        if (newEvent.title === "") {
            setTitleError("Please Enter Event Name");
        } else {
            setTitleError(null)
            setEvents([...events, newEvent]);
            console.log(newEvent)
            setShowAddEventPopup(false);
            const postData = {
                school_id: localStorage.getItem('schoolId'),
                event_type: category,
                programs_id: program,
                start_date: newEvent.start,
                end_date: newEvent.end,
                description: newEvent.description,
                title: newEvent.title,
                from_time_id: 1,
                to_time_id: 1,
                calendar_status: 1,
                status: 1,
                created_by: localStorage.getItem('schoolId')
            }
            console.log(postData)
            axios({
                method: "post",
                url: baseUrl + "/api/timetable_add_edit",
                data: postData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    fetchCalendarData();
                }
            });
        }

    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        setNewEvent(prevEvent => ({
            ...prevEvent,
            [name]: newValue
        }));
    };

    const handleSelectEvent = (event) => {
        const { start, end } = event;
        console.log('Start Time:', start);
        console.log('End Time:', end);
    };

    const [calendarData, setCalendarData] = useState([]);
    const [calendarLoading, setCalendarLoading] = useState(false);

    const fetchCalendarData = async () => {
        setCalendarLoading(false);
        try {
            const postData = {
                school_id: localStorage.getItem('schoolId'),
                programs_id: localStorage.getItem('programId')
            };
            const { data } = await axios.post(baseUrl + "/api/get_timetable", postData);
            setCalendarData(data.data)
            const fetchedEvents = data.data.map(event => ({
                title: event.title,
                start: new Date(event.start_date),
                end: new Date(event.end_date)
            }));
            setEvents(fetchedEvents);
        } catch (error) {
            console.log(error);
        } finally {
            setCalendarLoading(true);
        }
    };

    // const addUpdateFood = () => {
    //     const postData={
    //         id:foodId,
    //         school_id:localStorage.getItem('schoolId'),
    //         staff_id:5,
    //         food_date:foodDate,
    //         programs_id:localStorage.getItem('programId'),
    //         student_id:studentId,          
    //         food_time_id:foodDetails.foodTime,
    //         type_id:foodDetails.foodType,
    //         quantity_id:foodDetails.quantity,
    //         category_id:foodDetails.category,
    //         message:foodDetails.description,
    //         updated_by:localStorage.getItem('schoolId')
    //     } 
    //     console.log(postData)
    //     axios({
    //         method: "post",
    //         url: baseUrl+"/api/food_edit",
    //         data:postData,
    //         headers: {"Content-Type": "multipart/form-data"},
    //     }).then((response) => {
    //         console.log(response);
    //         if (response.data.status === true) {
    //             // fetchFoodData();
    //             window.location.reload(); 
    //         }
    //     });
    // }


    useEffect(() => {
        fetchCategoryData();
        fetchFoodTimeData();
        fetchProgramsData();
        fetchCalendarData();
        $(document).ready(function () {
            // Dropdown toggle using event delegation
            $(document).on('click', '[data-dropdown-toggle]', function () {
                var targetDropdown = $(this).data('dropdown-toggle');
                $('#' + targetDropdown).toggleClass('hidden');
            });
        });
        // $(document).ready(function() {
        //     const popup = $(".dialog-model");
        //     $(".sp-time-event li .week-day-box").click(function(event) {
        //         event.stopPropagation(); // Correction made here
        //         $('.week-day-box').not('.new-event').removeClass('selected');
        //         const clickedButton = $(this);
        //         clickedButton.addClass('selected'); // Correction made here
        //         const buttonOffset = clickedButton.offset();

        //         const popupTop = buttonOffset.top + clickedButton.outerHeight() + 10;
        //         const popupLeft = buttonOffset.left + (clickedButton.outerWidth() / 2) - (popup.outerWidth() / 2) + 5;

        //         clickedButton.append(popup);
        //         popup.css({
        //             display: "block"
        //         });
        //     });
        //     $('.dialog-model').click(function(event) {
        //         event.stopPropagation();
        //     });

        //     $(".dialog-model .cancel-btn").click(function(event) {
        //         event.stopPropagation();
        //         $(this).closest('.week-day-box').removeClass('selected'); // Correction made here
        //         $('.outer-container').append(popup);
        //         $(".dialog-model").css('display', 'none');
        //         $(".sp-add-food").parent().removeClass('selected');
        //     });
        //     $(".dialog-model .createN").click(function(event) {
        //         event.stopPropagation();
        //         $(this).closest('.week-day-box').addClass('new-event'); // Correction made here
        //         $('.outer-container').append(popup);
        //         $(".dialog-model").css('display', 'none');
        //         $(".week-day-box.selected").html('<span class="ft-700 ft-15 setgap"><span class="sp-tag-selected ft-wt700">Fancy Dress Competition for Kids</span><span class="sp-tag-selected">10:00 AM to 12:00 PM</span></span>')
        //         $(".sp-add-food").parent().removeClass('selected');
        //     });

        //     $(document).click(function(event) {
        //         const clickedElement = $(event.target);
        //         if (!clickedElement.hasClass("dialog-model") && $(".dialog-model").css("display") === "block") {
        //             $(".dialog-model").css('display', 'none');
        //             $('.outer-container').append(popup);
        //             $(".sp-add-food").parent().removeClass('selected');
        //         }
        //     });

        // });

        // $(".btn-dropdown-fieldval li").click(function(event) {
        //     const clickedOption = $(this);
        //     clickedOption.siblings().removeClass("selected");

        //     clickedOption.addClass("selected");

        //     const selectedValue = clickedOption.text().trim();
        //     clickedOption.parent().parent().parent().find(".btn-dropdown span em").text(`${selectedValue}`);
        //     console.log("Selected value:", selectedValue);
        // });

        // $(document).ready(function() {
        //     $(".sp-menu-togl span").click(function() {
        //         $(".sp-menu-togl span").removeClass("active");
        //         $(this).addClass("active");

        //         var tab = $(this).data("attr");
        //         $(".sp-timetab-sec").removeClass("active");
        //         $("." + tab).addClass("active");
        //     });
        // });

    }, [])

    return (
        <section>
            <div className="outer-container">
                <div id="left-panel" className="sp-left-panel">
                    <div className="sp-top-band">
                        <a className="sp-logo">
                            Scool Panda<sup>Beta</sup>
                        </a>
                        <div className="sp-search-top flexc">
                            <i className="icon-Search" />
                            <input
                                id="search"
                                className="sp-search-btn"
                                placeholder="Quick search..."
                            />
                        </div>
                    </div>
                    <div className="sp-middle-band">
                        <div className="sp-nav y-auto">
                            <div className="">
                                <h4 className="sp-title">Quick Actions</h4>
                                <ul>
                                    <li onClick={() => { navigate('/dashboard'); }}>
                                        <span>
                                            <i className="icon-Dashboard-Outline" />
                                            Dashboard
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/activity'); }}>
                                        <span>
                                            <i className="icon-Activity-Outline" />
                                            Activity
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/calendar'); }}>
                                        <span>
                                            <i className="icon-Timetable-Outline" />
                                            Calendar
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/staff_list'); }}>
                                        <span>
                                            <i className="icon-Staff-Outline" />
                                            Staff
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i className="icon-Message-Outline" />
                                            Messages
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i className="icon-Fees-Outline" />
                                            Fees Management
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i className="icon-Enquiries-Outline" />
                                            Enquiries
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i className="icon-Invites-Outline" />
                                            Invites
                                        </span>
                                    </li>
                                </ul>
                                <h4 className="sp-title flexc justifySp">
                                    Programs{" "}
                                    <span id="add-programs" className="sp-plus">
                                        +
                                    </span>
                                </h4>
                                <ul>
                                    {programsLoading && programsData && programsData.length > 0 ?
                                        programsData.map((data, index) => {
                                            return (
                                                <li className={data.id === parseInt(localStorage.getItem('programId')) ? "selected" : ""} onClick={() => {
                                                    localStorage.setItem('programId', data.id);
                                                    localStorage.setItem('programName', data.program_name);
                                                    fetchCalendarData();
                                                    navigate('/student_timetable');
                                                }}>
                                                    <span>
                                                        <i className="icon-Program-Outline-1" />
                                                        {data.program_name}
                                                    </span>
                                                </li>
                                            );
                                        }) :
                                        <Loader type="spinner-cub" bgColor="#4D6EE3" size={30} />
                                    }

                                </ul>
                            </div>
                        </div>
                        <div className="sp-bottom sp-nav">
                            <ul>
                                <li>
                                    <span>
                                        <i className="icon-Import-Outline" />
                                        Notifications
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <i className="icon-Invites-Outline" />
                                        Upgrade Pro
                                    </span>
                                </li>
                                <li onClick={() => navigate("/signout")}>
                                    <span>
                                        <i className="icon-Invites-Outline" />
                                        Log Out
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* my code */}
                <div className="sp-right-panel">
                    <div className="sp-top-bar flexc justifySp">
                        <div className="ft-wt600 flexc">
                            <span className="selected-icon">
                                <i className="icon-Program-Outline-1" />
                            </span>
                            {localStorage.getItem('programName')}
                            <i className="icon-More-Icon" />
                        </div>
                        <div className="flexc">
                            <button className="sp-view-btn ft-wt500 flexc">
                                <i className="icon-Import-Outline" />
                                Import
                            </button>
                            <button className="createN ft-wt500 mr-lf10">+ New Student</button>
                        </div>
                    </div>
                    <div className="sp-header borderbt">
                        <nav className="flexc">
                            <div className="sp-list-view flexc" onClick={() => { navigate('/student_list'); }}>
                                <i className="icon-Students-Outline" />
                                Student List
                            </div>
                            <div className="sp-list-view flexc" onClick={() => { navigate('/student_attendance'); }}>
                                <i className="icon-Attendance-Outline" />
                                Attendance
                            </div>
                            <div className="sp-list-view flexc" onClick={() => { navigate('/food'); }}>
                                <i className="icon-Food" />
                                Food
                            </div>
                            <div className="sp-list-view flexc" onClick={() => { navigate('/sleep'); }}>
                                <i className="icon-Sleep" />
                                Sleep
                            </div>
                            <div className="sp-list-view flexc selected">
                                <i className="icon-Calendar-Outline" />
                                Timetable
                            </div>
                        </nav>
                        {/* <div className="sp-menu-bar flexc justifySp">
                            <div className="sp-menugroup">
                                <div className="flexc">
                                    <div className="btn-search-bar flexc">
                                        <i className="icon-Search" />
                                        <input type="search" placeholder="Search Students" />
                                    </div>
                                    <div className="week-detail flexc">
                                        <button className="set-bord left-arr">
                                            <span className="arrow" />
                                        </button>
                                        <button className="sel-days set-bord">
                                            Week
                                            <span className="arrow up-arr" />
                                        </button>
                                        <button className="set-bord right-arr">
                                            <span className="arrow" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="flexc sp-menugroup">
                                <div className="sp-menubar-clslist flexc">
                                    <span className="ft-wt500 ft-14">Class Teachers</span>
                                    <ul className="flexc">
                                        <li>
                                            <img src={avatar1} />
                                        </li>
                                        <li>
                                            <img src={avatar1} />
                                        </li>
                                        <li>
                                            <img src={avatar1} />
                                        </li>
                                    </ul>
                                    <span className="sp-plus">+</span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    {calendarLoading ? (
                        <div className="sp-timetab-inr">
                            <Calendar
                                localizer={localizer}
                                events={events}
                                startAccessor="start"
                                endAccessor="end"
                                selectable
                                views={['month', 'week']}
                                defaultView='month'
                                onSelectSlot={handleSelectSlot}
                                onSelectEvent={handleSelectEvent}
                            />
                            {/* <Calendar
                                    localizer={localizer}
                                    events={events}
                                    startAccessor="start"
                                    endAccessor="end"
                                    // style={{ height: 500 }}
                                    views={['month', 'week']}
                                    defaultView='month'
                                    /> */}
                        </div>
                    ) : <Loader type="spinner-cub" bgColor="#4D6EE3" color="#4D6EE3" title="Loading..." size={50} />}

                </div>
                {showAddEventPopup && (
                    <div className="dialog-model absolute" style={newEvent.start && { display: "block" }}>
                        <div className="sp-tt ft-14 flexc ft-wt600">Create New Event</div>
                        <div className="form-section-default flexc justifySp fwrap">
                            <div className="field-label fullsz-field">
                                <label>Name</label>
                                <div className="field-value-holder margin_top10p">
                                    <input
                                        className="sp-field-input"
                                        placeholder="Type name.."
                                        name="title"
                                        value={newEvent.title}
                                        onChange={handleChange}
                                    />
                                </div>
                                {titleError && <div className="error">{titleError}</div>}
                            </div>
                            {/* <div className="field-label fullsz-field">
                                <label>Category</label>
                                <div className="field-value-holder  margin_top10p dropbutton" style={{ width: '100%' }}>
                                    <Dropdown
                                        label={<span style={{ color: 'black', whiteSpace: 'nowrap' }}>{selectedCategory ? selectedCategory.event_category_name : "Select Category"}</span>}
                                        style={{ width: '100%' }}
                                    >
                                        {categoryData.map(item => (
                                            <Dropdown.Item
                                                className={selectedCategory && selectedCategory.id === item.id ? "dd_select" : null}
                                                icon={selectedCategory && selectedCategory.id === item.id ? HiCheck : null}
                                                onClick={() => setCategory(item.id)}
                                                key={item.id}
                                            >
                                                {item.event_category_name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown>

                                </div>
                            </div> */}
                            <div className="field-label half-field">
                                <label>Start Date</label>
                                <div className="field-value-holder margin_top10p flexc">
                                    <input
                                        type="date"
                                        className="sp-field-input"
                                        placeholder="Start Date"
                                        name="start"
                                        value={moment(newEvent.start).format('YYYY-MM-DD')}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="field-label half-field">
                                <label>Start Time</label>
                                <div className="field-value-holder margin_top10p flexc">
                                    <Dropdown
                                        label={<span style={{ color: 'black' }}>{selectedStartTime ? selectedStartTime.time_name : "Select Time"}</span>}
                                        style={{ width: '100%' }}
                                    >
                                        {foodTimeData.map(item => (
                                            <Dropdown.Item
                                                className={selectedStartTime && selectedStartTime.id === item.id ? "dd_select" : null}
                                                icon={selectedStartTime && selectedStartTime.id === item.id ? HiCheck : null}
                                                onClick={() => setStartTime(item.id)}
                                                key={item.id}
                                            >
                                                {item.time_name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="field-label half-field">
                                <label>End Date</label>
                                <div className="field-value-holder margin_top10p flexc">
                                    <input
                                        type="date"
                                        className="sp-field-input"
                                        placeholder="End Date"
                                        name="end"
                                        value={moment(newEvent.end).format('YYYY-MM-DD')}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="field-label half-field">
                                <label>End Time</label>
                                <div className="field-value-holder margin_top10p flexc">
                                    <Dropdown
                                        label={<span style={{ color: 'black' }}>{selectedEndTime ? selectedEndTime.time_name : "Select Time"}</span>}
                                        style={{ width: '100%' }}
                                    >
                                        {foodTimeData.map(item => (
                                            <Dropdown.Item
                                                className={selectedEndTime && selectedEndTime.id === item.id ? "dd_select" : null}
                                                icon={selectedEndTime && selectedEndTime.id === item.id ? HiCheck : null}
                                                onClick={() => setEndTime(item.id)}
                                                key={item.id}
                                            >
                                                {item.time_name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="field-label fullsz-field">
                                <label>Programs</label>
                                <div className="field-value-holder  margin_top10p dropbutton" style={{ width: '100%' }}>
                                    <Dropdown
                                        label={<span style={{ color: 'black', whiteSpace: 'nowrap' }}>{selectedProgram ? selectedProgram.program_name : program === 0 ? "All Programs" : "Select Program"}</span>}
                                        style={{ width: '100%' }}
                                    >
                                        <Dropdown.Item
                                            className={program === 0 ? "dd_select" : null}
                                            icon={program === 0 ? HiCheck : null}
                                            onClick={() => setProgram(0)}
                                            key={0}
                                        >
                                            All Programs
                                        </Dropdown.Item>
                                        {programsData.map(item => (
                                            <Dropdown.Item
                                                className={selectedProgram && selectedProgram.id === item.id ? "dd_select" : null}
                                                icon={selectedProgram && selectedProgram.id === item.id ? HiCheck : null}
                                                onClick={() => setProgram(item.id)}
                                                key={item.id}
                                            >
                                                {item.program_name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="notify-cont flexc">
                                <label className="label-container">
                                    <input
                                        type="checkbox"
                                        defaultChecked={newEvent.notify}
                                        name="notify"
                                        onChange={handleChange}
                                    />
                                    <span className="tick-ele" />
                                </label>
                                <span className="ft-13">
                                    Notify Staff and Parents in selected programs
                                </span>
                            </div>
                            <div className="field-label full-field">
                                <label>Description</label>
                                <div className="field-value-holder margin_top10p">
                                    <textarea
                                        className="sp-field-input"
                                        placeholder="description"
                                        name="description"
                                        value={newEvent.description}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="flexc mt-4">
                                <div className="flexc">
                                    <button className="createN ft-wt500 mr-2" onClick={handleAddEvent}>
                                        Create
                                    </button>
                                    <button
                                        className="sp-view-btn ft-wt500 flexc cancel-btn"
                                        onClick={() => { setTitleError(null); setShowAddEventPopup(false); }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
}
