import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import file from "./images/fileupload.svg";
import axios from "axios";
import { baseUrl } from "../../common/common";
import filec from "./images/fileupload-c.svg";

export default function Import({ importStudent, handleImportStatus }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [fileMessage, setFileMessage] = useState('');
  const [messageType, setMessageType] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  useEffect(() => {
    console.log("messageType____", messageType, fileMessage)
  }, [fileMessage, messageType]);

  const importStudents = async (event) => {
    const files = event.target.files;
    const importFile = event.target.files[0];
    console.log("q--------", files);
    
    const formData = new FormData();
    formData.append("file", importFile);

    try {
      let response;
      if (importStudent === "Student") {
        formData.append("programs_id", localStorage.getItem("programId"));
        formData.append("school_id", localStorage.getItem("schoolId"));

        response = await axios.post(baseUrl + "/api/student_import", formData, {
          headers: { "Content-Type": "multipart/form-data" }
        });
      } else if (importStudent === "staff") {
        formData.append("school_id", localStorage.getItem("schoolId"));

        response = await axios.post(baseUrl + "/api/staff_import", formData, {
          headers: { "Content-Type": "multipart/form-data" }
        });
      }

      if (response.data.status == true) {
        handleImportStatus(true);
        setMessageType(true);
        setFileMessage("Import Successfully");
        setOpen(true);
        console.log("messageType", messageType, fileMessage);
        setTimeout(() => {
          importStudent === "Student"?navigate('/student_list'):navigate('/staff_list');
        }, 2500);
      } else {
        handleImportStatus(true);
        setMessageType(false);
        setFileMessage("Import Failed");
        setOpen(true);
      }
    } catch (error) {
      console.error("Error importing:", error);
      handleImportStatus(true);
      setMessageType(false);
      setFileMessage("Import Failed");
      setOpen(true);
    }
  };

  return (
    <section>
      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={3000}
        message={"test"}
        ContentProps={{
          sx: {
            border: "1px solid",
            opacity: "1",
            background: messageType === true ? "#40BE61" : "#FD4954",
            borderColor: messageType === true ? "#40BE61" : "#FD4954"
          }
        }}
      />
      <div className="outer-container">
        <div className="overlay" />
        <div className="load-container">
          <div className="sp-table-import y-auto flexc justify-center">
            <div className="sp-import-container">
              <section className="sp-top-box" id="upload-dropbox-zone">
                <img src={file} width={58} height={60} className="file-img" />
                <div className="ft-13 clr-grey line-h-4 mt-4">
                  Drag and drop file here or
                  <span className="relative clr-theme1 ft-wt500">
                    <input
                      type="file"
                      title=""
                      multiple=""
                      accept=".jpg, .jpeg, .png, .webp, .dmg"
                      aria-labelledby="upload-dropbox-zone"
                      onChange={importStudents}
                    />
                    Choose file
                  </span>
                  <span className="ft-12 block">support CSV files only</span>
                </div>
                <div className="flexc justify-center mt-6">
                  <div className="file-input-container">
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      accept=".xlsx"
                      onChange={importStudents}
                    />
                    <label
                      className="createN ft-wt500 flexc"
                      htmlFor="fileInput"
                    >
                      <i className="icon-Import-Outline" />
                      Import
                    </label>
                  </div>
                  <button
                    className="sp-view-btn ft-wt500 mr-lf10"
                    id="newStudentCreate"
                  >
                    Add Manually
                  </button>
                </div>
              </section>
              <section className="sp-bt-box mt-5">
                <div className="flexc justifySp">
                  <div className="">
                    <h4 className="ft-13 ft-wt600 flexc">
                      <img
                        src={filec}
                        width={28}
                        height={60}
                        className="file-col mr-2"
                      />
                      Table Sample
                    </h4>
                    <p className="ft-13 mt-2 line-h-4">
                      You can download the attached example and use them as a
                      starting point of your own file.
                    </p>
                  </div>
                  {importStudent === "Student" ? (
                    <button
                      className="sp-view-btn ft-wt500 mr-lf10"
                      onClick={() =>
                        (window.location.href = "/sample_file.xlsx")
                      }
                    >
                      Download
                    </button>
                  ) : (
                    <button
                      className="sp-view-btn ft-wt500 mr-lf10"
                      onClick={() => (window.location.href = "/Staff.xlsx")}
                    >
                      Download
                    </button>
                  )}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}





// import React, { useState, useMemo, useEffect } from "react";
// import {useNavigate } from "react-router-dom";
// import file from "./images/fileupload.svg";
// import axios from "axios";
// import { baseUrl } from "../../common/common";
// import filec from "./images/fileupload-c.svg";
// export default function Import({ importStudent, handleImportStatus }) {

//   const navigate = useNavigate();
//   const importStudents = (event) => {
//     const files = event.target.files;
//     //setUser({ ...user, profile_image: files[0] });
//     const importFile = event.target.files[0];
//     console.log("q--------", files);
//     if (importStudent === "Student") {
//       axios({
//         method: "post",
//         url: baseUrl + "/api/student_import",
//         data: {
//           programs_id: localStorage.getItem("programId"),
//           school_id: localStorage.getItem("schoolId"),
//           file: importFile,
//         },
//         headers: { "Content-Type": "multipart/form-data" },
//       }).then((response) => {
//         console.log(response);
//         if (response["data"]["status"] === true) {
//           console.log(response);
//           handleImportStatus(true);
//           navigate('/student_list');
//         }
//       });
//     }
//     if (importStudent === "staff") {
//       axios({
//         method: "post",
//         url: baseUrl + "/api/staff_import",
//         data: {
//           school_id: localStorage.getItem("schoolId"),
//           file: importFile,
//         },
//         headers: { "Content-Type": "multipart/form-data" },
//       }).then((response) => {
//         console.log(response);
//         if (response["data"]["status"] === true) {
//           console.log(response);
//           handleImportStatus(true);
//           navigate('/staff_list');
//         }
//       });
//     }
//     //setSelectedImport(file);
//   };

//   return (
//     <section>
//       <div className="outer-container">
//         <div className="overlay" />

//         <div className="load-container">
//           <div className="sp-table-import y-auto flexc justify-center">
//             <div className="sp-import-container">
//               <section className="sp-top-box" id="upload-dropbox-zone">
//                 <img src={file} width={58} height={60} className="file-img" />
//                 <div className="ft-13 clr-grey line-h-4 mt-4">
//                   Drag and drop file here or
//                   <span className="relative clr-theme1 ft-wt500">
//                     <input
//                       type="file"
//                       title=""
//                       multiple=""
//                       accept=".jpg, .jpeg, .png, .webp, .dmg"
//                       aria-labelledby="upload-dropbox-zone"
//                     />
//                     Choose file
//                   </span>
//                   <span className="ft-12 block">support CSV files only</span>
//                 </div>
//                 <div className="flexc justify-center mt-6">
//                   <div className="file-input-container">
//                     <input
//                       type="file"
//                       id="fileInput"
//                       style={{ display: "none" }}
//                       accept=".xlsx"
//                       onChange={importStudents}
//                     />
//                     <label
//                       className="createN ft-wt500 flexc"
//                       htmlFor="fileInput"
//                     >
//                       <i className="icon-Import-Outline" />
//                       Import
//                     </label>
//                   </div>
//                   <button
//                     className="sp-view-btn ft-wt500 mr-lf10"
//                     id="newStudentCreate"
//                   >
//                     Add Manually
//                   </button>
//                 </div>
//               </section>
//               <section className="sp-bt-box mt-5">
//                 <div className="flexc justifySp">
//                   <div className="">
//                     <h4 className="ft-13 ft-wt600 flexc">
//                       <img
//                         src={filec}
//                         width={28}
//                         height={60}
//                         className="file-col mr-2"
//                       />
//                       Table Sample
//                     </h4>
//                     <p className="ft-13 mt-2 line-h-4">
//                       You can download the attached example and use them as a
//                       starting point of your own file.
//                     </p>
//                   </div>
//                   {importStudent === "Student" ? (
//                     <button
//                       className="sp-view-btn ft-wt500 mr-lf10"
//                       onClick={() =>
//                         (window.location.href = "/sample_file.xlsx")
//                       }
//                     >
//                       Download
//                     </button>
//                   ) : (
//                     <button
//                       className="sp-view-btn ft-wt500 mr-lf10"
//                       onClick={() => (window.location.href = "/Staff.xlsx")}
//                     >
//                       Download
//                     </button>
//                   )}
//                 </div>
//               </section>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }
