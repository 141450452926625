import React, { useState, useMemo, useEffect } from "react";
import {Link, Navigate, Outlet } from "react-router-dom";
import "../signin/css/style.css";
import panda from "./images/panda.png";
export default function Recovery() {
    return (
        <section>
            <div className="whole-container sign_page">
                {/* Login Welcome back */}
                <div className="flex-prop form-wrap">
                    <div className="form-container">
                        <div className="mrg-cen text-align-c box main_panda">
                            <img src={panda} style={{ width: "80px" }} width={80} height={100} alt="Scool Panda" />
                            <h1>Recovery link sent!</h1>
                            <p className="txt-normal mt-10">Now, check your email.</p>
                           <span> <Link to="/signin" className="ms-clr cp mt-20 dis-block ft-600">or sign in</Link></span>  
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
