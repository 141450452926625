import React, { useState, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "../signin/css/style.css";
import panda from "./images/panda.png";
import Snackbar from '@mui/material/Snackbar';
import { baseUrl } from "../../common/common";

export default function NewPassword() {
  
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const confirmPasswordRef = useRef(null);
  const submitButtonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [fileError, setFileError] = useState('');

  const { encrypt_data } = useParams();
  const navigate = useNavigate();

  const validatePassword = async (password) => {
    if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters long.");
      return false;
    } else {
      setPasswordError("");
      return true;
    }
  };

  const validateConfirmPassword = async (password, confirmPassword) => {
    if (confirmPassword !== password) {
      setConfirmPasswordError("Passwords do not match.");
      return false;
    } else {
      setConfirmPasswordError("");
      return true;
    }
  };

  const handlePasswordChange = async (e) => {
    const newPassword = e.target.value;
    await setPassword(newPassword);
    await validatePassword(newPassword);
    await validateConfirmPassword(newPassword, confirmPassword);
  };

  const handleConfirmPasswordChange = async (e) => {
    const newConfirmPassword = e.target.value;
    await setConfirmPassword(newConfirmPassword);
    await validateConfirmPassword(password, newConfirmPassword);
  };

  const handleKeyPress = (e, nextField) => {
    if (e.key === "Enter") {
      e.preventDefault();
      nextField.current.focus();
    }
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isPasswordValid = await validatePassword(password);
    const isConfirmPasswordValid = await validateConfirmPassword(password, confirmPassword);

    if (isPasswordValid && isConfirmPasswordValid) {
      try {
        const postData = {
          encrypt_data: encrypt_data,
          password: password,
        };
        const { data } = await axios.post(baseUrl + "/api/change_password", postData);
        console.log(data, "d");
        if (data.status == true) {
          navigate('/signout');
        } else {
          setFileError(data.message);
          setOpen(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setFileError("password mismatch");
      setOpen(true);
    }
  };

  return (
    <section>
      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={3000}
        message={fileError}
        ContentProps={{
          sx: {
            border: "1px 0px 0px 0px",
            opacity: "0px",
            background: "#FD4954",
            border: "1px solid #FD4954"
          }
        }}
      />
      <div className="whole-container sign_page">
        <div className="flex-prop form-wrap">
          <div className="form-container">
            <div className="mrg-cen text-align-c main_panda">
              <img src={panda} style={{ width: "80px" }} width={80} height={100} alt="School Panda" />
              <h1>Create a new password!</h1>
            </div>
            <div className="sign-form mt-40">
              <form onSubmit={handleSubmit}>
                <div className="forms-fields-text">
                  <label htmlFor="password">Choose new password</label>
                  <div className="icon_input">
                    <input
                      type="password"
                      placeholder="8+ Character required"
                      value={password}
                      onChange={handlePasswordChange}
                      onKeyPress={(e) => handleKeyPress(e, confirmPasswordRef)}
                      style={{ border: passwordError ? "1px solid red" : "" }}
                    />
                    <span className="sp-icon-pw" />
                  </div>
                  {passwordError && <span className="error">{passwordError}</span>}
                </div>
                <div className="forms-fields-text">
                  <label htmlFor="confirmPassword">Confirm</label>
                  <div className="icon_input">
                    <input
                      type="password"
                      placeholder="8+ Character required"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      ref={confirmPasswordRef}
                      onKeyPress={(e) => handleKeyPress(e, submitButtonRef)}
                      style={{ border: confirmPasswordError ? "1px solid red" : "" }}
                    />
                    <span className="sp-icon-pw" />
                  </div>
                  {confirmPasswordError && <span className="error">{confirmPasswordError}</span>}
                </div>

                <button className="verify-btn" ref={submitButtonRef}>
                  Create Password
                </button>
                <Link to="/signin" className="ms-clr cp">
                  Back to Sign In
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
