import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import panda from "./images/panda.png";
import $ from "jquery";
import { baseUrl } from "../../common/common";
import Loader from "react-js-loader";
import '../../common/components/css/style.css';
import axios from 'axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment-timezone';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Dropdown } from 'flowbite-react';
import "../../index.css";
import { HiCheck } from "react-icons/hi";
// import momentTime from 'moment-timezone';

export default function EventCalendar() {

    const navigate = useNavigate();

    const [programsData, setProgramsData] = useState([]);
    const [programsLoading, setProgramsLoading] = useState(false);

    const fetchProgramsData = async () => {
        setProgramsLoading(false);
        try {
            const postData = {
                school_id: localStorage.getItem('schoolId')
                // school_id:24
            }
            const { data } = await axios.post(baseUrl + "/api/get_programs", postData);
            setProgramsData(data.data);
            setProgramsLoading(true);
        } catch (error) {
            console.log(error)
        }
    }

    const localizer = momentLocalizer(moment);

    const [program, setProgram] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [category, setCategory] = useState("");
    const [categoryData, setCategoryData] = useState([]);
    const selectedCategory = categoryData.find(data => data.id === category);
    const fetchCategoryData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/get_event_category",
            headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setCategoryData(response.data.data);
                console.log(response);
            }
        });
    }

    const [foodTimeData, setFoodTimeData] = useState([]);
    const selectedStartTime = foodTimeData.find(data => data.id === startTime);
    const selectedEndTime = foodTimeData.find(data => data.id === endTime);
    const fetchFoodTimeData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/get_time",
            headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setFoodTimeData(response.data.data);
                console.log(response);
            }
        });
    }

    const selectedProgram = programsData.find(data => data.id === program);

    const events1 = [
        {
            title: 'Children Day',
            start: new Date(2024, 5, 1, 14, 30), // May 1, 2024 2:30 PM (local time)
            end: new Date(2024, 5, 1, 16, 0),   // May 1, 2024 4:00 PM (local time)
        },
    ];

    const event1 = [
        // {
        //     title: 'My Event',
        //     start: new Date('2024-05-30T18:30:00.000Z'),
        //     end: new Date('2024-05-30T19:30:00.000Z')
        // },
        {
            title: 'Children Day',
            start: new Date(2024, 5, 1, 14, 30), // Apr 1, 2024 2:00 PM
            end: new Date(2024, 5, 1, 16, 0),   // Apr 1, 2024 4:00 PM
        },
        // {
        //     title: 'Fancy Dress Competition For Kids',
        //     start: new Date(2024, 3, 7, 10, 0), // Apr 7, 2024 10:00 AM
        //     end: new Date(2024, 3, 7, 12, 0),   // Apr 7, 2024 12:00 PM
        // },
        // {
        //     title: 'World Sleep Day - Overnight Camping',
        //     start: new Date(2024, 3, 18, 0, 0),  // Apr 18, 2024 12:00 AM
        //     end: new Date(2024, 3, 18, 17, 0),   // Apr 18, 2024 5:00 PM
        // },
        // {
        //     title: 'Annual Day',
        //     start: new Date(2024, 3, 22, 0, 0),  // Apr 22, 2024 12:00 AM
        //     end: new Date(2024, 3, 22, 17, 0),   // Apr 22, 2024 5:00 PM
        // },
        // {
        //     title: 'Art Attack',
        //     start: new Date(2024, 4, 1, 14, 0),  // May 1, 2024 2:00 PM
        //     end: new Date(2024, 4, 1, 16, 0),    // May 1, 2024 4:00 PM
        // }
    ];

    const [events, setEvents] = useState([]);
    const [showEventPopup, setShowEventPopup] = useState(false);
    const [showAddEventPopup, setShowAddEventPopup] = useState(false);
    const [newEvent, setNewEvent] = useState({
        id: '',
        title: '',
        start: '',
        end: '',
        startTime: '',
        endTime: '',
        program: '',
        category: '',
        notify: true,
        description: ''
    });

    const [currentView, setCurrentView] = useState('month');
    const [addUpdateStatus, setAddUpdateStatus] = useState('');

    const handleViewChange = (view) => {
        setCurrentView(view);
    };

    const handleSelectSlot = ({ start, end }) => {
        setAddUpdateStatus("add")
        setCategory("")
        setEndTime("")
        setStartTime("")
        setProgram("")
        const formatDate = (date) => {
            return date.toLocaleDateString('en-GB', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            }).split('/').reverse().join('-');
        };
        const adjustedEnd = new Date(end);
        adjustedEnd.setDate(adjustedEnd.getDate() - 1);
        const newEvent = {
            id: '',
            title: '',
            start: formatDate(start),
            end: currentView === 'month' ? formatDate(adjustedEnd) : formatDate(end),
            startTime: '',
            endTime: '',
            program: '',
            category: '',
            notify: true,
            description: ''
        };
        setNewEvent(newEvent);
        setShowAddEventPopup(true);
    };

    // const handleSelectSlot = ({ start, end }) => {
    //     // const popup = $(".dialog-model");
    //     // const clickedButton = $(this);
    //     // clickedButton.addClass('selected');    
    //     // clickedButton.append(popup);
    //     // popup.css({
    //     //     display: "block"
    //     // });
    //     alert(currentView)
    //     if(currentView=='month'){
    //         setNewEvent({
    //             title: '',
    //             start:start.toLocaleDateString('en-GB', {
    //                 year: 'numeric',
    //                 month: '2-digit',
    //                 day: '2-digit'
    //             }).split('/').reverse().join('-'),
    //             end:setDate(end.getDate() - 1).toLocaleDateString('en-GB', {
    //                 year: 'numeric',
    //                 month: '2-digit',
    //                 day: '2-digit'
    //             }).split('/').reverse().join('-'),
    //             startTime: '',
    //             endTime: '',
    //             program: 'All Programs',
    //             notify: true,
    //             description: ''
    //         });
    //     }else{
    //         setNewEvent({
    //             title: '',
    //             start:start.toLocaleDateString('en-GB', {
    //                 year: 'numeric',
    //                 month: '2-digit',
    //                 day: '2-digit'
    //             }).split('/').reverse().join('-'),
    //             end:end.toLocaleDateString('en-GB', {
    //                 year: 'numeric',
    //                 month: '2-digit',
    //                 day: '2-digit'
    //             }).split('/').reverse().join('-'),
    //             startTime: '',
    //             endTime: '',
    //             program: 'All Programs',
    //             notify: true,
    //             description: ''
    //         });  
    //     }  

    //     setShowAddEventPopup(true);
    // };

    const [titleError, setTitleError] = useState(null);
    const [categoryError, setCategoryError] = useState(null);
    const [startTimeError, setStartTimeError] = useState(null);
    const [endTimeError, setEndTimeError] = useState(null);
    const [programError, setProgramError] = useState(null);

    const handleAddEvent = () => {
        let hasError = false;

        if (newEvent.title === "") {
            setTitleError("Please Enter Event Name");
            hasError = true;
        } else {
            setTitleError(null);
        }
        // if (!category) {
        //     setCategoryError("Please Select a Category");
        //     hasError = true;
        // } else {
        //     setCategoryError(null);
        // }
        if (startTime === "") {
            setStartTimeError("Please Select Start Time");
            hasError = true;
        } else {
            setStartTimeError(null);
        }
        if (endTime === "") {
            setEndTimeError("Please Select End Time");
            hasError = true;
        } else {
            setEndTimeError(null);
        }

        if (hasError) return;

        const formattedStartDate = `${newEvent.start} ${selectedStartTime ? selectedStartTime.time_name : '00:00'}`;
        const formattedEndDate = `${newEvent.end} ${selectedEndTime ? selectedEndTime.time_name : '00:00'}`;

        const postData = {
            school_id: localStorage.getItem('schoolId'),
            event_type: category,
            programs_id: program,
            start_date: formattedStartDate,
            end_date: formattedEndDate,
            description: newEvent.description,
            title: newEvent.title,
            from_time_id: startTime,
            to_time_id: endTime,
            calendar_status: 1,
            status: 1
        };

        if (newEvent.id && newEvent.id !== "") {
            postData.id = newEvent.id;
            postData.updated_by = localStorage.getItem('schoolId');
        } else {
            postData.created_by = localStorage.getItem('schoolId');
        }
        setTitleError(null);
        setStartTimeError(null);
        setEndTimeError(null);
        console.log(postData);
        axios({
            method: "post",
            url: `${baseUrl}/api/timetable_add_edit`,
            data: postData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setShowAddEventPopup(false);
                fetchCalendarData();
            }
        }).catch((error) => {
            console.error("There was an error adding/updating the event!", error);
        });
    };


    // const handleAddEvent = () => {

    //     if (newEvent.title === "") {
    //         setTitleError("Please Enter Event Name");
    //     } else {
    //         const formattedStartDate = `${newEvent.start} ${selectedStartTime ? selectedStartTime.time_name : '00:00'}`;
    //         const formattedEndDate = `${newEvent.end} ${selectedEndTime ? selectedEndTime.time_name : '00:00'}`;

    //         const postData = {
    //             school_id: localStorage.getItem('schoolId'),
    //             event_type: category,
    //             programs_id: program,
    //             start_date: formattedStartDate,
    //             end_date: formattedEndDate,
    //             description: newEvent.description,
    //             title: newEvent.title,
    //             from_time_id: startTime,
    //             to_time_id: endTime,
    //             calendar_status: 1,
    //             status: 1
    //         };

    //         if (newEvent.id && newEvent.id !== "") {
    //             postData.id = newEvent.id;
    //             postData.updated_by = localStorage.getItem('schoolId');
    //         } else {
    //             postData.created_by = localStorage.getItem('schoolId');
    //         }

    //         setTitleError(null);
    //         console.log(postData);

    //         // axios({
    //         //     method: "post",
    //         //     url: `${baseUrl}/api/timetable_add_edit`,
    //         //     data: postData,
    //         //     headers: { "Content-Type": "multipart/form-data" },
    //         // }).then((response) => {
    //         //     console.log(response);
    //         //     if (response.data.status === true) {
    //         //         setShowAddEventPopup(false);
    //         //         fetchCalendarData();
    //         //         // if (newEvent.id && newEvent.id !== "") {
    //         //         //     setEvents(events.map(event => event.id === newEvent.id ? newEvent : event));
    //         //         // } else {
    //         //         //     setEvents([...events, { ...newEvent, id: response.data.id }]);
    //         //         // }
    //         //         // console.log(newEvent);
    //         //         // setShowAddEventPopup(false);
    //         //     }
    //         // }).catch((error) => {
    //         //     console.error("There was an error adding/updating the event!", error);
    //         // });
    //     }
    // };




    // const handleAddEvent = () => {
    //     if (newEvent.title === "") {
    //         setTitleError("Please Enter Event Name");
    //     } else {
    //         const postData = {
    //             school_id: localStorage.getItem('schoolId'),
    //             event_type: category,
    //             programs_id: program,
    //             start_date: `${newEvent.start} ${selectedStartTime.time_name}`,
    //             end_date: `${newEvent.end} ${selectedEndTime.time_name}`,
    //             description: newEvent.description,
    //             title: newEvent.title,
    //             from_time_id: startTime,
    //             to_time_id: endTime,
    //             calendar_status: 1,
    //             status: 1                
    //         };    
    //         if (newEvent.id && newEvent.id !== "") {
    //             postData.id = newEvent.id;
    //             postData.updated_by= localStorage.getItem('schoolId')
    //         }else{
    //             postData.created_by= localStorage.getItem('schoolId')
    //         }    
    //         setTitleError(null);
    //         console.log(postData)
    //         axios({
    //             method: "post",
    //             url: `${baseUrl}/api/timetable_add_edit`,
    //             data: postData,
    //             headers: { "Content-Type": "multipart/form-data" },
    //         }).then((response) => {
    //             console.log(response);
    //             if (response.data.status === true) {
    //                 setEvents([...events, newEvent]);
    //                 console.log(newEvent);
    //                 setShowAddEventPopup(false);
    //                 // fetchCalendarData();
    //             }
    //         });
    //     }
    // };


    // const handleAddEvent = () => {
    //     if (newEvent.title === "") {
    //         setTitleError("Please Enter Event Name");
    //     }else{
    //         const postData={}
    //         if(newEvent.id===""){
    //             postData={
    //                 school_id:localStorage.getItem('schoolId'),
    //                 event_type:category,
    //                 programs_id:program,
    //                 start_date:(newEvent.start+" "+selectedStartTime.time_name),
    //                 end_date:(newEvent.end+" "+selectedEndTime.time_name),
    //                 description:newEvent.description,
    //                 title:newEvent.title,
    //                 from_time_id:startTime,
    //                 to_time_id:endTime,
    //                 calendar_status:1,
    //                 status:1,
    //                 created_by:localStorage.getItem('schoolId')
    //             } 
    //         }else{
    //             postData={
    //                 id:newEvent.id,
    //                 school_id:localStorage.getItem('schoolId'),
    //                 event_type:category,
    //                 programs_id:program,
    //                 start_date:(newEvent.start+" "+selectedStartTime.time_name),
    //                 end_date:(newEvent.end+" "+selectedEndTime.time_name),
    //                 description:newEvent.description,
    //                 title:newEvent.title,
    //                 from_time_id:startTime,
    //                 to_time_id:endTime,
    //                 calendar_status:1,
    //                 status:1,
    //                 created_by:localStorage.getItem('schoolId')
    //             }    
    //         }
    //         setTitleError(null)
    //         console.log(postData)
    //         // axios({
    //         //     method: "post",
    //         //     url: baseUrl+"/api/timetable_add_edit",
    //         //     data:postData,
    //         //     headers: {"Content-Type": "multipart/form-data"},
    //         // }).then((response) => {
    //         //     console.log(response);
    //         //     if (response.data.status === true) {
    //         //         setEvents([...events, newEvent]);
    //         //         console.log(newEvent)                    
    //         //         setShowAddEventPopup(false);
    //         //         // fetchCalendarData();
    //         //     }
    //         // });
    //     }

    // };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        setNewEvent(prevEvent => ({
            ...prevEvent,
            [name]: newValue
        }));
    };

    const handleSelectEvent = (event) => {

        setAddUpdateStatus("update")
        setNewEvent({});
        console.log(event)
        // const {start, end } = event;
        setCategory(event.event_type)
        setEndTime(event.to_time_id)
        setStartTime(event.from_time_id)
        setProgram(event.program_id)
        const formatDate = (date) => {
            return date.toLocaleDateString('en-GB', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            }).split('/').reverse().join('-');
        };
        const newEvent = {
            id: event.id,
            title: event.title,
            start: formatDate(event.start),
            end: formatDate(event.end),
            startTime: event.from_time_id,
            endTime: event.to_time_id,
            program: event.program_id,
            category: event.event_type,
            notify: true,
            description: event.description,
            title: event.title,
        };
        setNewEvent(newEvent);
        setShowEventPopup(true);
        // setShowAddEventPopup(true);
    };

    const [calendarData, setCalendarData] = useState([]);
    const [calendarLoading, setCalendarLoading] = useState(false);

    const fetchCalendarData = async () => {
        setCalendarLoading(false);
        try {
            const postData = {
                school_id: localStorage.getItem('schoolId')
            };
            const { data } = await axios.post(baseUrl + "/api/get_timetable", postData);
            setCalendarData(data.data)

            const fetchedEvents = data.data.map(event =>
            ({
                id: event.id,
                title: event.title,
                event_type: event.event_type,
                program_id: event.programs_id,
                from_time_id: event.from_time_id,
                to_time_id: event.to_time_id,
                description: event.description,
                title: event.title,
                start: new Date(...event.start_date),
                end: new Date(...event.end_date)
            })
            );
            setEvents(fetchedEvents);
        } catch (error) {
            console.log(error);
        } finally {
            setCalendarLoading(true);
        }
    };

    // const addUpdateFood = () => {
    //     const postData={
    //         id:foodId,
    //         school_id:localStorage.getItem('schoolId'),
    //         staff_id:5,
    //         food_date:foodDate,
    //         programs_id:localStorage.getItem('programId'),
    //         student_id:studentId,          
    //         food_time_id:foodDetails.foodTime,
    //         type_id:foodDetails.foodType,
    //         quantity_id:foodDetails.quantity,
    //         category_id:foodDetails.category,
    //         message:foodDetails.description,
    //         updated_by:localStorage.getItem('schoolId')
    //     } 
    //     console.log(postData)
    //     axios({
    //         method: "post",
    //         url: baseUrl+"/api/food_edit",
    //         data:postData,
    //         headers: {"Content-Type": "multipart/form-data"},
    //     }).then((response) => {
    //         console.log(response);
    //         if (response.data.status === true) {
    //             // fetchFoodData();
    //             window.location.reload(); 
    //         }
    //     });
    // }

    useEffect(() => {
        fetchCategoryData();
        fetchFoodTimeData();
        fetchProgramsData();
        fetchCalendarData();
    }, [])

    return (
        <section>
            <div className="outer-container">
                <div id="left-panel" className="sp-left-panel">
                    <div className="sp-top-band">
                        <a className="sp-logo">
                            Scool Panda<sup>Beta</sup>
                        </a>
                        <div className="sp-search-top flexc">
                            <i className="icon-Search" />
                            <input
                                id="search"
                                className="sp-search-btn"
                                placeholder="Quick search..."
                            />
                        </div>
                    </div>
                    <div className="sp-middle-band">
                        <div className="sp-nav y-auto">
                            <div className="">
                                <h4 className="sp-title">Quick Actions</h4>
                                <ul>
                                    <li onClick={() => { navigate('/dashboard'); }}>
                                        <span>
                                            <i className="icon-Dashboard-Outline" />
                                            Dashboard
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/activity'); }}>
                                        <span>
                                            <i className="icon-Activity-Outline" />
                                            Activity
                                        </span>
                                    </li>
                                    <li className="selected">
                                        <span>
                                            <i className="icon-Timetable-Outline" />
                                            Calendar
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/staff_list'); }}>
                                        <span>
                                            <i className="icon-Staff-Outline" />
                                            Staff
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i className="icon-Message-Outline" />
                                            Messages
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i className="icon-Fees-Outline" />
                                            Fees Management
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i className="icon-Enquiries-Outline" />
                                            Enquiries
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <i className="icon-Invites-Outline" />
                                            Invites
                                        </span>
                                    </li>
                                </ul>
                                <h4 className="sp-title flexc justifySp">
                                    Programs{" "}
                                    <span id="add-programs" className="sp-plus">
                                        +
                                    </span>
                                </h4>
                                <ul>
                                    {programsLoading && programsData && programsData.length > 0 ?
                                        programsData.map((data, index) => {
                                            return (
                                                <li onClick={() => {
                                                    localStorage.setItem('programId', data.id);
                                                    localStorage.setItem('programName', data.program_name);
                                                    navigate('/student_list');
                                                }}>
                                                    <span>
                                                        <i className="icon-Program-Outline-1" />
                                                        {data.program_name}
                                                    </span>
                                                </li>
                                            );
                                        }) :
                                        <Loader type="spinner-cub" bgColor="#4D6EE3" size={30} />
                                    }

                                </ul>
                            </div>
                        </div>
                        <div className="sp-bottom sp-nav">
                            <ul>
                                <li>
                                    <span>
                                        <i className="icon-Import-Outline" />
                                        Notifications
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <i className="icon-Invites-Outline" />
                                        Upgrade Pro
                                    </span>
                                </li>
                                <li onClick={() => navigate("/signout")}>
                                    <span>
                                        <i className="icon-Invites-Outline" />
                                        Log Out
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* my code */}
                <div className="sp-right-panel">
                    <div className="sp-top-bar flexc justifySp borderbt">
                        <div className="ft-wt600 flexc">
                            <span className="selected-icon">
                                <i className="icon-Timetable-Outline" />
                            </span>
                            Calendar
                            <i className="icon-More-Icon" />
                        </div>
                        <div className="flexc">
                            <button className="sp-view-btn ft-wt500 flexc">
                                <i className="icon-Import-Outline" />
                                Import
                            </button>
                        </div>
                    </div>
                    {calendarLoading ? (
                        <div className="sp-timetab-inr">
                            <Calendar
                                localizer={localizer}
                                events={events}
                                onView={handleViewChange}
                                startAccessor="start"
                                endAccessor="end"
                                selectable
                                views={['month', 'week']}
                                defaultView='month'
                                onSelectSlot={handleSelectSlot}
                                onSelectEvent={handleSelectEvent}
                            />
                        </div>
                    ) : <Loader type="spinner-cub" bgColor="#4D6EE3" color="#4D6EE3" title="Loading..." size={50} />}
                </div>
                {showAddEventPopup && (
                    <div className="dialog-model absolute" style={newEvent.start && { display: "block" }}>
                        <div className="sp-tt ft-14 flexc ft-wt600">{addUpdateStatus === 'add' ? "Create New" : "Update"} Event</div>
                        <div className="form-section-default flexc justifySp fwrap">
                            <div className="field-label fullsz-field">
                                <label>Name</label>
                                <div className="field-value-holder margin_top10p">
                                    <input
                                        className="sp-field-input"
                                        placeholder="Type name.."
                                        name="title"
                                        value={newEvent.title}
                                        onChange={handleChange}
                                    />
                                </div>
                                {titleError && <div className="error">{titleError}</div>}
                            </div>
                            <div className="field-label fullsz-field">
                                <label>Category</label>
                                <div className="field-value-holder margin_top10p dropbutton" style={{ width: '100%' }}>
                                    <Dropdown
                                        label={<span style={{ color: 'black', whiteSpace: 'nowrap' }}>{selectedCategory ? selectedCategory.event_category_name : "Select Category"}</span>}
                                        style={{ width: '100%' }}
                                    >
                                        {categoryData.map(item => (
                                            <Dropdown.Item
                                                className={selectedCategory && selectedCategory.id === item.id ? "dd_select" : null}
                                                icon={selectedCategory && selectedCategory.id === item.id ? HiCheck : null}
                                                onClick={() => setCategory(item.id)}
                                                key={item.id}
                                            >
                                                {item.event_category_name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="field-label half-field">
                                <label>Start Date</label>
                                <div className="field-value-holder margin_top10p flexc">
                                    <input
                                        type="date"
                                        className="sp-field-input"
                                        placeholder="Start Date"
                                        name="start"
                                        value={moment(newEvent.start).format('YYYY-MM-DD')}
                                        onChange={handleChange}
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className="field-label half-field">
                                <label>Start Time</label>
                                <div className="field-value-holder margin_top10p flexc">
                                    <Dropdown
                                        label={<span style={{ color: 'black' }}>{selectedStartTime ? selectedStartTime.time_name : "Select Time"}</span>}
                                        style={{ width: '100%' }}
                                    >
                                        {foodTimeData.map(item => (
                                            <Dropdown.Item
                                                className={selectedStartTime && selectedStartTime.id === item.id ? "dd_select" : null}
                                                icon={selectedStartTime && selectedStartTime.id === item.id ? HiCheck : null}
                                                onClick={() => setStartTime(item.id)}
                                                key={item.id}
                                            >
                                                {item.time_name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown>
                                </div>
                                {startTimeError && <div className="error">{startTimeError}</div>}
                            </div>
                            <div className="field-label half-field">
                                <label>End Date</label>
                                <div className="field-value-holder margin_top10p flexc">
                                    <input
                                        type="date"
                                        className="sp-field-input"
                                        placeholder="End Date"
                                        name="end"
                                        value={moment(newEvent.end).format('YYYY-MM-DD')}
                                        onChange={handleChange}
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className="field-label half-field">
                                <label>End Time</label>
                                <div className="field-value-holder margin_top10p flexc">
                                    <Dropdown
                                        label={<span style={{ color: 'black' }}>{selectedEndTime ? selectedEndTime.time_name : "Select Time"}</span>}
                                        style={{ width: '100%' }}
                                    >
                                        {foodTimeData.map(item => (
                                            <Dropdown.Item
                                                className={selectedEndTime && selectedEndTime.id === item.id ? "dd_select" : null}
                                                icon={selectedEndTime && selectedEndTime.id === item.id ? HiCheck : null}
                                                onClick={() => setEndTime(item.id)}
                                                key={item.id}
                                            >
                                                {item.time_name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown>
                                </div>
                                {endTimeError && <div className="error">{endTimeError}</div>}
                            </div>

                            <div className="field-label fullsz-field">
                                <label>Programs</label>
                                <div className="field-value-holder margin_top10p dropbutton" style={{ width: '100%' }}>
                                    <Dropdown
                                        label={<span style={{ color: 'black', whiteSpace: 'nowrap' }}>{selectedProgram ? selectedProgram.program_name : program === 0 ? "All Programs" : "Select Program"}</span>}
                                        style={{ width: '100%' }}
                                    >
                                        <Dropdown.Item
                                            className={program === 0 ? "dd_select" : null}
                                            icon={program === 0 ? HiCheck : null}
                                            onClick={() => setProgram(0)}
                                            key={0}
                                        >
                                            All Programs
                                        </Dropdown.Item>
                                        {programsData.map(item => (
                                            <Dropdown.Item
                                                className={selectedProgram && selectedProgram.id === item.id ? "dd_select" : null}
                                                icon={selectedProgram && selectedProgram.id === item.id ? HiCheck : null}
                                                onClick={() => setProgram(item.id)}
                                                key={item.id}
                                            >
                                                {item.program_name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="notify-cont flexc">
                                <label className="label-container">
                                    <input
                                        type="checkbox"
                                        defaultChecked={newEvent.notify}
                                        name="notify"
                                        onChange={handleChange}
                                    />
                                    <span className="tick-ele" />
                                </label>
                                <span className="ft-13">
                                    Notify Staff and Parents in selected programs
                                </span>
                            </div>
                            <div className="field-label full-field">
                                <label>Description</label>
                                <div className="field-value-holder margin_top10p">
                                    <textarea
                                        className="sp-field-input"
                                        placeholder="description"
                                        name="description"
                                        value={newEvent.description}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="flexc mt-4">
                                <div className="flexc">
                                    <button className="createN ft-wt500 mr-2" onClick={handleAddEvent}>
                                        {addUpdateStatus === 'add' ? "Create" : "Update"}
                                    </button>
                                    <button
                                        className="sp-view-btn ft-wt500 flexc cancel-btn"
                                        onClick={() => { setTitleError(null); setShowAddEventPopup(false); }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* <div className="form-section-default flexc justifySp fwrap">
                            <div className="field-label fullsz-field">
                                <label>Name</label>
                                <div className="field-value-holder margin_top10p">
                                    <input
                                        className="sp-field-input"
                                        placeholder="Type name.."
                                        name="title"
                                        value={newEvent.title}
                                        onChange={handleChange}
                                    />
                                </div>
                                {titleError && <div className="error">{titleError}</div>}
                            </div>
                            <div className="field-label fullsz-field">
                                <label>Category</label>
                                <div className="field-value-holder  margin_top10p dropbutton" style={{ width: '100%' }}>
                                    <Dropdown
                                        label={<span style={{ color: 'black', whiteSpace: 'nowrap' }}>{selectedCategory ? selectedCategory.event_category_name : "Select Category"}</span>}
                                        style={{ width: '100%' }}
                                    >
                                        {categoryData.map(item => (
                                            <Dropdown.Item
                                                className={selectedCategory && selectedCategory.id === item.id ? "dd_select" : null}
                                                icon={selectedCategory && selectedCategory.id === item.id ? HiCheck : null}
                                                onClick={() => setCategory(item.id)}
                                                key={item.id}
                                            >
                                                {item.event_category_name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown>

                                </div>
                            </div>
                            <div className="field-label half-field">
                                <label>Start Date</label>
                                <div className="field-value-holder margin_top10p flexc">
                                    <input
                                        type="date"
                                        className="sp-field-input"
                                        placeholder="Start Date"
                                        name="start"
                                        value={moment(newEvent.start).format('YYYY-MM-DD')}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="field-label half-field">
                                <label>Start Time</label>
                                <div className="field-value-holder margin_top10p flexc">
                                    <Dropdown
                                        label={<span style={{ color: 'black' }}>{selectedStartTime ? selectedStartTime.time_name : "Select Time"}</span>}
                                        style={{ width: '100%' }}
                                    >
                                        {foodTimeData.map(item => (
                                            <Dropdown.Item
                                                className={selectedStartTime && selectedStartTime.id === item.id ? "dd_select" : null}
                                                icon={selectedStartTime && selectedStartTime.id === item.id ? HiCheck : null}
                                                onClick={() => setStartTime(item.id)}
                                                key={item.id}
                                            >
                                                {item.time_name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="field-label half-field">
                                <label>End Date</label>
                                <div className="field-value-holder margin_top10p flexc">
                                    <input
                                        type="date"
                                        className="sp-field-input"
                                        placeholder="End Date"
                                        name="end"
                                        value={moment(newEvent.end).format('YYYY-MM-DD')}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="field-label half-field">
                                <label>End Time</label>
                                <div className="field-value-holder margin_top10p flexc">
                                    <Dropdown
                                        label={<span style={{ color: 'black' }}>{selectedEndTime ? selectedEndTime.time_name : "Select Time"}</span>}
                                        style={{ width: '100%' }}
                                    >
                                        {foodTimeData.map(item => (
                                            <Dropdown.Item
                                                className={selectedEndTime && selectedEndTime.id === item.id ? "dd_select" : null}
                                                icon={selectedEndTime && selectedEndTime.id === item.id ? HiCheck : null}
                                                onClick={() => setEndTime(item.id)}
                                                key={item.id}
                                            >
                                                {item.time_name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="field-label fullsz-field">
                                <label>Programs</label>
                                <div className="field-value-holder  margin_top10p dropbutton" style={{ width: '100%' }}>
                                    <Dropdown
                                        label={<span style={{ color: 'black', whiteSpace: 'nowrap' }}>{selectedProgram ? selectedProgram.program_name :program === 0 ?"All Programs":"Select Program"}</span>}
                                        style={{ width: '100%' }}
                                    >
                                        <Dropdown.Item
                                                className={program === 0 ? "dd_select" : null}
                                                icon={program === 0 ? HiCheck : null}
                                                onClick={() => setProgram(0)}
                                                key={0}
                                            >
                                            All Programs
                                        </Dropdown.Item>
                                        {programsData.map(item => (
                                            <Dropdown.Item
                                                className={selectedProgram && selectedProgram.id === item.id ? "dd_select" : null}
                                                icon={selectedProgram && selectedProgram.id === item.id ? HiCheck : null}
                                                onClick={() => setProgram(item.id)}
                                                key={item.id}
                                            >
                                                {item.program_name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="notify-cont flexc">
                                <label className="label-container">
                                    <input
                                        type="checkbox"
                                        defaultChecked={newEvent.notify}
                                        name="notify"
                                        onChange={handleChange}
                                    />
                                    <span className="tick-ele" />
                                </label>
                                <span className="ft-13">
                                    Notify Staff and Parents in selected programs
                                </span>
                            </div>
                            <div className="field-label full-field">
                                <label>Description</label>
                                <div className="field-value-holder margin_top10p">
                                    <textarea
                                        className="sp-field-input"
                                        placeholder="description"
                                        name="description"
                                        value={newEvent.description}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="flexc mt-4">
                                <div className="flexc">
                                    <button className="createN ft-wt500 mr-2" onClick={handleAddEvent}>
                                      {addUpdateStatus==='add'?"Create":"Update"}
                                    </button>
                                    <button
                                        className="sp-view-btn ft-wt500 flexc cancel-btn"
                                        onClick={()=>{setTitleError(null);setShowAddEventPopup(false);}}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                )}
                {showEventPopup && (
                    <div className="dialog-model absolute" style={{ display: "block" }}>
                        <div className="flexc justifySp">
                            <div className="sp-tt ft-14 ft-wt600">Event Information</div>
                            <div>
                                <span className="selected-icon" onClick={() =>{setShowEventPopup(false); setShowAddEventPopup(true)}}>
                                    <i className="icon-Timetable-Outline" />
                                </span>
                                <span className="selected-icon">
                                    <i className="icon-Timetable-Outline" />
                                </span>
                            </div>
                        </div>
                        <div className="form-section-default flexc justifySp fwrap">
                            <div className="field-label full-field">
                                <label>Name</label>
                                <div className="sp-tt ft-14 flexc ft-wt600 mt-4">{newEvent.title}</div>
                            </div>
                            <div className="field-label full-field">
                                <label>Category</label>
                                <div className="sp-tt ft-14 flexc ft-wt600 mt-4">{newEvent.category}</div>
                            </div>
                            <div className="field-label half-field">
                                <label>Start Date</label>
                                <div className="sp-tt ft-14 flexc ft-wt600 mt-4">{newEvent.start}</div>
                            </div>
                            <div className="field-label half-field">
                                <label>Start Time</label>
                                <div className="sp-tt ft-14 flexc ft-wt600 mt-4">{newEvent.startTime}</div>
                            </div>
                            <div className="field-label half-field">
                                <label>End Date</label>
                                <div className="sp-tt ft-14 flexc ft-wt600 mt-4">{newEvent.end}</div>
                            </div>
                            <div className="field-label half-field">
                                <label>End Time</label>
                                <div className="sp-tt ft-14 flexc ft-wt600 mt-4">{newEvent.endTime}</div>
                            </div>
                            <div className="field-label half-field">
                                <label>Programs</label>
                                <div className="sp-tt ft-14 flexc ft-wt600 mt-4">{newEvent.program}</div>
                            </div>
                            <div className="field-label full-field">
                                <label>Description</label>
                                <div className="sp-tt ft-14 flexc ft-wt600 mt-4">{newEvent.description}</div>
                            </div>
                            <div className="flexc mt-4">
                                <button
                                    className="sp-view-btn ft-wt500 flexc cancel-btn"
                                    onClick={() => { setTitleError(null); setShowEventPopup(false); }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </section>
    );
}
